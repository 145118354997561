import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import MintChest from "./MintChest/MintChest";
import PlaynityTheGenesisNft from "./PlaynityTheGenesisNft/PlaynityTheGenesisNft";
import RoboheroAvatar from "./RoboheroAvatar/RoboheroAvatar";
import SpaceCartelsAmulet from "./SpaceCartelsAmulet/SpaceCartelsAmulet";
import ThorstarterShield from "./ThorstarterShield/ThorstarterShield";

const NftPage = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();

  const collectionIds = [
    "space-cartels-amulets",
    "thorstarter-shields",
    "robohero-avatars",
    "mint-chests",
    "playnity-the-genesis",
  ];

  useEffect(() => {
    if (!collectionIds.includes(collectionId)) {
      navigate("/");
    }
  });

  return (
    <>
      {collectionId === "mint-chests" && <MintChest />}
      {collectionId === "robohero-avatars" && <RoboheroAvatar />}
      {collectionId === "space-cartels-amulets" && <SpaceCartelsAmulet />}
      {collectionId === "thorstarter-shields" && <ThorstarterShield />}
      {collectionId === "playnity-the-genesis" && <PlaynityTheGenesisNft />}
    </>
  );
};

export default NftPage;
