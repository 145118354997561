import React, { useState, useEffect } from "react";
import Title from "../../components/Title/Title";
import Text from "../../components/Text/Text";
import { ReactComponent as MintLogo } from "../../assets/images/mint-logo.svg";
import Button from "../../components/Button/Button";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import useTerra from "../../hooks/useTerra";
import useAirdropApi from "../../hooks/useAirdropApi";
import utils from "../../utils/utils";
import { tokensPrecision } from "../../config";
import { useSnackbar } from "notistack";
import SmallTitle from "../../components/SmallTitle/SmallTitle";
import Modal from "../../components/Modal/Modal";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import { Link } from "react-router-dom";
import TxHash from "../../components/TxHash/TxHash";
import Timer from "../../components/Timer/Timer";

const EXPIRY_AIRDROP_DATE = new Date("2022-06-13T12:00:00.000Z");

// FYI: styles from ClaimMint.scss :)

const GenesisAirdrop = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [airdropInfo, setAirdropInfo] = useState(null);
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { claimAirdrop, getAirdropUserInfo } = useAirdropApi();

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [claimingAvailable, setClaimingAvailable] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      const airdropInfo = async () => {
        const airdropInfoResponse = await getAirdropUserInfo(
          connectedWallet.walletAddress
        );
        if (airdropInfoResponse) {
          console.log(airdropInfoResponse);
          setAirdropInfo(airdropInfoResponse);
          if (airdropInfoResponse?.total_amount === "0") {
            clearInterval(intervalId);
          }
        } else {
          enqueueSnackbar({
            text: "There was a problem while fetching airdrop info!",
            variant: "error",
          });
          await utils.delay(30000);
        }
      };
      if (connectedWallet) {
        await airdropInfo();
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [network.name, connectedWallet, getAirdropUserInfo, enqueueSnackbar]);

  const beforeTransaction = () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
  };
  const claim = () => {
    beforeTransaction();
    claimAirdrop(setLoading, setTxInfo, setTxError);
  };
  const claimableAmount =
    parseInt(airdropInfo?.claimable_amount) / tokensPrecision || 0;
  const claimedAmount =
    parseInt(airdropInfo?.claimed_amount) / tokensPrecision || 0;
  const totalAmount =
    parseInt(airdropInfo?.total_amount) / tokensPrecision || 0;
  return (
    <AppBgLayout>
      {isModalOpen && (
        <Modal
          id="modal"
          type={loading ? "warning" : txError ? "error" : "success"}
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <LoadingPopup
            status={loading ? "loading" : txError ? "error" : "success"}
            loadingComponent={
              <>
                {!txInfo ? (
                  <Text
                    className="loading-popup__copy"
                    text="Approve transaction"
                  />
                ) : (
                  <>
                    <TxHash
                      txhash={txInfo?.result.txhash}
                      network={connectedWallet?.network.name}
                    />
                  </>
                )}
              </>
            }
            successComponent={
              <TxHash
                txhash={txInfo?.result.txhash}
                network={connectedWallet?.network.name}
              />
            }
            errorMsg={txError ? txError.toString() : null}
          />
        </Modal>
      )}
      <div className="claim-mint__title-container">
        <Title
          text="genesis"
          suffix="airdrop"
          inverted
          className="claim-mint__title"
        />
        <SmallTitle text="Airdrop Rules" />
        <div className="claim-mint__rules">
          <Text text="Claim <span>20%</span> now" />
          <Text text="Claim <span>80%</span> after staking MINT tokens in Single Asset staking or LP tokens in Liquidity Pool staking" />
        </div>
        <Text
          className="claim-mint__text-line"
          text="Everyone who purchase at least one NFT on Luart secondary marketplace and still hold it in wallet."
        />
        <Text
          className="claim-mint__text-line"
          text="Snapshot date: 08/03 at 9:00 UTC."
        />
        <Text
          className="claim-mint__text-line"
          text="The size of the airdrop depends of the numbers of NFTs purchased."
        />
        <Text className="claim-mint__text-line" text="Claiming fee 1 UST" />
        <div className="claim-mint__timer">
          <SmallTitle text={`Claiming available for:`} />
          <Timer
            expiryTimestamp={EXPIRY_AIRDROP_DATE}
            onExpire={() => setClaimingAvailable(false)}
            showDays={true}
          ></Timer>
        </div>
      </div>

      <div className="claim-mint__inputs">
        <div>
          <label className="claim-label" htmlFor="from">
            <div>
              <input
                type="text"
                className="claim-label__input"
                value={claimableAmount.toFixed(2)}
                readOnly
                id="from"
              />
              <p className="claim-label__title">
                Available <span>| MINT</span>
              </p>
            </div>
            <p className="claim-label__currency">
              MINT <MintLogo />
            </p>
            {claimingAvailable && (
              <Button
                disabled={claimableAmount === 0}
                onClick={claim}
                variant="contained"
                className="claim-label__button"
              >
                Claim
              </Button>
            )}
          </label>
        </div>

        <div>
          <label className="claim-label" htmlFor="to">
            <div>
              <input
                type="text"
                className="claim-label__input"
                value={`${claimedAmount.toFixed(2)} / ${totalAmount.toFixed(
                  2
                )}`}
                readOnly
                id="to"
              />
              <p className="claim-label__title">
                Claimed <span>| MINT</span>
              </p>
            </div>
            <p className="claim-label__currency">
              MINT <MintLogo />
            </p>
          </label>
        </div>
      </div>

      <div className="claim-mint__footer">
        {claimingAvailable && (
          <Button
            disabled={claimableAmount === 0}
            onClick={claim}
            variant="contained"
            className="claim-label__button claim-label__button--mobile"
          >
            Claim
          </Button>
        )}
        {totalAmount === 0 && (
          <Text text="Unfortunately your address is not eligible to claim airdrop." />
        )}{" "}
        {claimedAmount > 0 && claimedAmount < totalAmount && (
          <Link to="/stake">
            <Button variant="contained">
              Stake Now to unlock your airdrop
            </Button>
          </Link>
        )}
      </div>
    </AppBgLayout>
  );
};

export default GenesisAirdrop;
