import { contractAddresses } from "../../../config";
import useTerra from "../../../hooks/useTerra";
import CollectionSale from "../../../containers/CollectionSale/CollectionSale";

import NFTImages from "../../../assets/images/ThorstarterShields";

import Text from "../../../components/Text/Text";
import useThorstarterShieldsApi from "../../../hooks/useThorstarterShieldsApi";

const ThorstarterShieldsCollection = () => {
  const { network } = useTerra();
  const { getWhitelistedAddresses, getNftInfo, getConfig } = useThorstarterShieldsApi();

  const description = (
    <>
      <Text
        text={`Our factory is compact and prepared to start the minting process.
              Shields have the following utilities:`}
      />

      <ul className="collection-sale__description-benefits">
        <li>
          <Text text="Basic points to Allocation Multiplier on Thorstarter IDO's"></Text>
        </li>
        <li>
          <Text text="Temporary extra points* to Allocation Multiplier on Thorstarter IDO's dependent on burned XRUNE (Initially <span>1 burned</span> XRUNE = <span>25 points</span> for one week)"></Text>
        </li>
        <li>
          <Text text="$MINT tokens yield farming"></Text>
        </li>
        <li>
          <Text text="<span>Bonus</span> Each shield will automatically receive 1000-2000 pre-burned XRUNE after minting"></Text>
        </li>
      </ul>
      <Text
        className="collection-sale__disclaimer"
        text="*Points works also on other chains (ETH, Terra, Fantom, Polygon)"
      ></Text>
    </>
  );

  const chances = {
    chances: [
      { name: "from", chance: 1000 },
      { name: "to", chance: 2000 },
    ],
    whitelistChances: [
      { name: "from", chance: 1500 },
      { name: "to", chance: 2000 },
    ],
  };

  return (
    <CollectionSale
      // contract interactions
      contractAddress={contractAddresses[network.name].thorstarterShields}
      getContractConfig={getConfig}
      tokensAmountQuery={{ num_tokens: {} }}
      tokensAmountResultPropertyName="count"
      getWhitelistedAddresses={getWhitelistedAddresses}
      getNftInfo={getNftInfo}
      whitelistedAddressesResultPropertyName="whitelisted"
      // UI
      NFTImages={NFTImages}
      title="Thorstarter"
      titleSuffix="Shields Collection"
      description={description}
      chances={chances}
      chancesText="Amount of XRUNE pre-burned on minted NFT"
      chancesType="range"
      denom="uluna"
      congratulationsPage="/collections/thorstarter-shields/nft"
      // Drawing attributes
      returnedDrawedAttrName="Total XRUNE Burned"
      attrValueThatHasToChange="0"
    ></CollectionSale>
  );
};

export default ThorstarterShieldsCollection;
