import "./UnstakeConfirmationPopup.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";

const UnstakeConfirmationPopup = ({
  onConfirm,
  onConfirmDisabled = false,
  onCancel,
  children,
}) => {
  return (
    <div className="unstake-confirmation-popup">
      <div className="unstake-confirmation-popup__title-container">
        <Text
          className="unstake-confirmation-popup__title"
          text="Submit to unstake"
        />
      </div>

      <Text
        className="unstake-confirmation-popup__copy"
        text="Choose the amount of mint tokens that you want to unstake. Wait till unbonding period ends to get all of your tokens without burning!"
      />

      {children}

      <footer className="unstake-confirmation-popup__footer">
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>

        <Button
          disabled={onConfirmDisabled}
          onClick={onConfirm}
          variant="contained"
        >
          Submit to Unstake
        </Button>
      </footer>
    </div>
  );
};

export default UnstakeConfirmationPopup;
