import React, { useEffect, useState } from "react";
import useLeaderboardHooks from "../hooks/LeaderboardHooks";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../../hooks/useTerra";
import utils from "../../../utils/utils";
import leaderboardUtils from "../utils/LeaderboardUtils";
import LeaderboardContainer from "../../../containers/LeaderboardContainer/LeaderboardContainer";
import usePlaynityTheGenesisApi from "../../../hooks/usePlaynityTheGenesisApi";
import PlaynityTheGenesisUtils from "../../../utils/PlaynityTheGenesisUtils";
import { useNavigate } from "react-router-dom";

const PlaynityTheGenesisNftLeaderboard = () => {
  const navigate = useNavigate();
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getPlaynityTheGenesisInfoAll, getPlaynityTheGenesisTokens } =
    usePlaynityTheGenesisApi();
  const { fetchCollectionData } = useLeaderboardHooks();
  const [tokenCollection, setTokenCollection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ownedTokensIds, setOwnedTokensIds] = useState([]);
  const [error, setError] = useState(null);
  const [sortMethod, setSortMethod] = useState("rarityScore");
  const [sortDir, setSortDir] = useState("desc");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      await fetchCollectionData(
        getPlaynityTheGenesisInfoAll,
        getPlaynityTheGenesisTokens,
        parseAndSetTokenCollection,
        setOwnedTokensIds,
        setError,
        1800
      );
    }, 10000);

    setSortMethod("rarityScore");

    return () => clearInterval(intervalId);
  }, [
    connectedWallet,
    network.name,
    getPlaynityTheGenesisInfoAll,
    getPlaynityTheGenesisTokens,
    fetchCollectionData,
  ]);

  const parseAndSetTokenCollection = (collection) => {
    collection = collection?.map((item) => ({
      ...item,
      rarity: item.drawed_nft_info ? item.drawed_nft_info?.rarity : "-",
      rarityScore: item.drawed_nft_info
        ? PlaynityTheGenesisUtils.getRarityScoreFromDna(
            item.drawed_nft_info?.dna
          )
        : "0",
    }));
    setTokenCollection(collection);
  };

  const onHeaderClick = (columnName) => {
    if (sortMethod === columnName) {
      if (sortDir === "asc") {
        setSortDir("desc");
      } else {
        setSortDir("asc");
      }
    }

    setSortMethod(columnName);
  };

  const headersArr = [
    { key: "token_id", title: "Token ID" },
    { key: "rarity", title: "Rarity" },
    { key: "rarityScore", title: "Rarity Score" },
    { key: "pending_reward", title: "tokens inside" },
  ];

  const rowData = (props, index, tokenData) => {
    const tokenReward = tokenData.pending_reward / 1_000_000;
    const tokenId = tokenData.token_id;
    const rarity = tokenData?.rarity;
    const rarityScore = tokenData?.rarityScore;

    return (
      <tr
        {...props}
        onClick={() => {
          navigate(`/collections/playnity-the-genesis/nft/${tokenId}`);
        }}
        className="leaderboard__table-row-link"
      >
        <td style={{ "--title": "'No.'" }}>{index + 1}</td>
        <td style={{ "--title": "'Token ID'" }}>{`#${tokenId}`}</td>
        <td style={{ "--title": "'Rarity'" }}>{`${rarity}`}</td>
        <td style={{ "--title": "'Rarity Score'" }}>
          {`${utils.addSeparatorsToNumber(
            Number.parseFloat(rarityScore).toFixed(2)
          )}`}
        </td>
        <td style={{ "--title": "'Tokens inside'" }}>
          {`${utils.addSeparatorsToNumber(
            Number.parseFloat(tokenReward).toFixed(2)
          )}`}
        </td>
      </tr>
    );
  };

  return (
    <LeaderboardContainer
      collectionName="Playnity The Genesis"
      renderRow={leaderboardUtils.renderRow(
        tokenCollection,
        filter,
        rowData,
        sortMethod,
        sortDir
      )}
      renderListHeaders={leaderboardUtils.renderListHeaders(
        headersArr,
        sortMethod,
        sortDir,
        onHeaderClick
      )}
      tokenCollection={tokenCollection}
      error={error}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default PlaynityTheGenesisNftLeaderboard;
