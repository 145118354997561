import React, { useState } from "react";
import Modal from "../Modal/Modal";
import HappyHourContent from "../HappyHourContent/HappyHourContent";
import useHappyHourEventState from '../../hooks/useHappyHourEventState'

const HappyHourPopup = () => {
  const [isModalVisible, setModalVisible] = useState(true);
  const { eventState, updateEventState } = useHappyHourEventState()

  return (
    <>
      {isModalVisible && eventState !=='after' && (
        <Modal onCloseClick={() => setModalVisible(false)}>
          <HappyHourContent onTimerEnd={updateEventState} setModalVisible={setModalVisible}/>
        </Modal>
      )}
    </>
  );
};

export default HappyHourPopup;
