import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useMintTokenApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  return {
    getMintTokenBalance: useCallback(
      async (connectedWalletAddress) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].mintToken,
            { balance: { address: connectedWalletAddress } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getLpTokenBalance: useCallback(
      async (connectedWalletAddress) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].lpToken,
            { balance: { address: connectedWalletAddress } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
  };
};

export default useMintTokenApi;
