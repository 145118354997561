import "./Treasury.scss";
import Title from "../../components/Title/Title";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import { ReactComponent as DesktopBg } from "./bg-desktop.svg";
import { ReactComponent as MobileBg } from "./bg-mobile.svg";
import { ReactComponent as Luna } from "../../components/Price/luna.svg";
import { ReactComponent as Ust } from "../../components/Price/ust.svg";
import { ReactComponent as Mint } from "../../assets/images/mint-logo.svg";
import SmallTitle from "../../components/SmallTitle/SmallTitle";
import Button from "../../components/Button/Button";
import React, { useCallback, useEffect, useState } from "react";
import { tokensPrecision, circulatingSupplyURL } from "../../config";
import utils from "../../utils/utils";
import useUSTRewardsApi from "../../hooks/useUSTRewardsApi";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import { MsgExecuteContract } from "@terra-money/terra.js";
import useTerra from "../../hooks/useTerra";
import { contractAddresses } from "../../config";
import useContractApi from "../../hooks/useContractApi";
import Modal from "../../components/Modal/Modal";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import Tooltip from "../../components/Tooltip/Tooltip";
import Text from "../../components/Text/Text";
import useTokenPrice from "../../hooks/useTokenPrice";
import TxHash from "../../components/TxHash/TxHash";
import useDAOTreasuryGatewayApi from "../../hooks/useDAOTreasuryGatewayApi";
import { ReactComponent as HelpIcon } from "./help-icon.svg";

const Treasury = ({ displayAsComponent = true, className }) => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getVestingAccount, getState } = useUSTRewardsApi();
  const { getTreasuryValue } = useDAOTreasuryGatewayApi();
  const { executeContractAndWaitForResult } = useContractApi();
  const { getPrice } = useTokenPrice();

  const [tokenPrice, setTokenPrice] = useState(0);
  const [circulatingSupply, setCirculatingSupply] = useState(0);
  const [totalDistributedReward, setTotalDistributedReward] = useState(0);
  const [totalTreasuryValue, setTotalTreasuryValue] = useState(null);
  const [rewardsInfo, setRewardsInfo] = useState(null);
  const [treasuryDetails, setTreasuryDetails] = useState({});

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateState = useCallback(() => {
    (async () => {
      let res;
      try {
        res = await fetch(circulatingSupplyURL);
        const circulatingSupply = (await res.text()) * tokensPrecision;
        setCirculatingSupply(circulatingSupply);
      } catch (error) {
        console.error(error);
      }

      setTokenPrice(await getPrice());
      res = await getState();
      const treasuryValue = await getTreasuryValue();
      const totalValue =
        parseInt(treasuryValue?.total) +
        parseInt(treasuryValue?.ust_transferred_for_rewards) -
        parseInt(res?.total_distributed_reward);

      setTreasuryDetails({
        anchorProtocol: utils.printAmount(
          parseInt(treasuryValue?.anchor_value)
        ),
        ustInTreasuryLP: utils.printAmount(
          parseInt(treasuryValue?.liquidity_value)
        ),
        ust: utils.printAmount(parseInt(treasuryValue?.ust_value)),
        ustInRewardsContract: utils.printAmount(
          parseInt(treasuryValue?.ust_transferred_for_rewards) -
            parseInt(res?.total_distributed_reward)
        ),
      });

      setTotalDistributedReward(res?.total_distributed_reward);
      setTotalTreasuryValue(totalValue);

      if (!connectedWallet) {
        return;
      }

      res = await getVestingAccount(connectedWallet?.walletAddress);
      setRewardsInfo(res);
    })();
  }, [
    connectedWallet,
    getVestingAccount,
    getState,
    getPrice,
    getTreasuryValue,
  ]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(updateState, 5000);
    return () => clearInterval(intervalId);
  }, [updateState]);

  const claim = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);

    let msgs = [];

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].ustRewards,
        { claim_ust: {} },
        {}
      )
    );
    setLoading(true);
    const txResult = await executeContractAndWaitForResult(
      msgs,
      setTxInfo,
      setTxError
    );
    setLoading(false);

    console.log(txResult);

    updateState();
  };

  return (
    <div className="treasury">
      <AppBgLayout
        displayBackgroundLogo={displayAsComponent}
        className={className}
      >
        {isModalOpen && (
          <Modal
            id="modal"
            type={loading ? "warning" : txError ? "error" : "success"}
            onCloseClick={() => {
              setModalOpen(false);
            }}
            showCloseButton
          >
            <LoadingPopup
              status={loading ? "loading" : txError ? "error" : "success"}
              loadingComponent={
                <>
                  {!txInfo ? (
                    <Text
                      className="loading-popup__copy"
                      text="Approve transaction"
                    />
                  ) : (
                    <>
                      <TxHash
                        txhash={txInfo?.result.txhash}
                        network={connectedWallet?.network.name}
                      ></TxHash>
                    </>
                  )}
                </>
              }
              successComponent={
                <TxHash
                  txhash={txInfo?.result.txhash}
                  network={connectedWallet?.network.name}
                ></TxHash>
              }
              errorMsg={txError ? txError.toString() : null}
            />
          </Modal>
        )}

        <Title className="treasury__title" text="MintDAO" suffix="Treasury" />

        <div className="treasury__bg">
          <MobileBg className="treasury__mobile" />
          <DesktopBg className="treasury__desktop" />

          <Luna className="treasury__luna" />
          <Ust className="treasury__ust" />
          <Mint className="treasury__mint" />

          <div className="treasury__info1">
            <SmallTitle
              text={`${tokenPrice ? tokenPrice.toFixed(3) : "..."} UST`}
              className="treasury__amount"
            />
            <SmallTitle text="mint price" className="treasury__desc" />
          </div>

          <div className="treasury__info2">
            <SmallTitle
              text={
                totalTreasuryValue
                  ? `${utils.printAmount(totalTreasuryValue)} UST`
                  : "... UST"
              }
              className="treasury__amount"
            />
            <div className="treasury__reserve_box">
              <SmallTitle text="Treasury reserve" className="treasury__desc" />
              <Tooltip
                text={`UST: ${treasuryDetails.ust || 0} UST
                Locked in Anchor: ${treasuryDetails.anchorProtocol || 0} UST
                UST part of LP tokens: ${
                  treasuryDetails.ustInTreasuryLP || 0
                } UST
                UST in distribution contract: ${
                  treasuryDetails.ustInRewardsContract || 0
                } UST`}
              >
                <HelpIcon className="treasury__help_icon" />
              </Tooltip>
            </div>
          </div>

          <div className="treasury__info3">
            <SmallTitle
              text={utils.printAmount(circulatingSupply)}
              className="treasury__amount"
            />
            <SmallTitle text="mint circ. supply" className="treasury__desc" />
          </div>
        </div>

        <Text text="Dual Staking rewards" className="treasury__dual-rewards" />
        <div className="treasury__inputs">
          <div>
            <label className="claim-label" htmlFor="from">
              <div>
                <input
                  type="text"
                  className="claim-label__input"
                  value={`${utils.printAmount(totalDistributedReward)}`}
                  readOnly
                  id="from"
                />
                <p className="claim-label__title">
                  Total Paid Out To Stakers <span>| UST</span>
                </p>
              </div>

              <p className="claim-label__currency">
                UST <Ust />
              </p>
            </label>
          </div>

          <div>
            <label className="claim-label" htmlFor="to">
              <div className="treasury__claim-split-box">
                <div>
                  <input
                    type="text"
                    className="claim-label__input"
                    value={utils.printAmount(rewardsInfo?.pending_reward)}
                    readOnly
                    id="to"
                  />
                  <p className="claim-label__title">To Claim</p>
                </div>

                <div>
                  <input
                    type="text"
                    className="claim-label__input"
                    value={utils.printAmount(rewardsInfo?.total_claimed_reward)}
                    readOnly
                    id="to"
                  />
                  <p className="claim-label__title">Total Claimed</p>
                </div>
              </div>

              <p className="claim-label__currency">
                UST <Ust />
              </p>
              <Button
                onClick={claim}
                variant="contained"
                className="claim-label__button"
              >
                Claim
              </Button>
            </label>
          </div>

          <Button
            onClick={claim}
            variant="contained"
            fullWidth
            className="treasury__button"
          >
            Claim Now
          </Button>
        </div>
      </AppBgLayout>
    </div>
  );
};

export default Treasury;
