import "./Chance.scss";
import SmallTitle from "../SmallTitle/SmallTitle";

const Chance = ({
  className,
  whitelisted,
  chances,
  chancesText,
  chancesType, // chances or range or distribution
}) => {
  const chancesComponent = (
    whitelisted ? chances.whitelistChances : chances.chances
  )?.map((item) => (
    <li key={item.name} className="chance__item">
      <div className="chance__percent">{`${item.chance * 100}%`}</div>
      <SmallTitle className="chance__title" text={item.name} />
    </li>
  ));

  const rangeChancesComponent = (
    whitelisted ? chances.whitelistChances : chances.chances
  )?.map((item) => (
    <li key={item.name} className="chance__item">
      <SmallTitle className="chance__title" text={item.name} />
      <div className="chance__percent">{`${item.chance}`}</div>
    </li>
  ));

  const distributionChancesComponent = (
    whitelisted ? chances.whitelistChances : chances.chances
  )?.map((item) => (
    <li key={item.name} className="chance__item">
      <SmallTitle className="chance__title" text={item.name} />
      <div className="chance__percent">{`${item.chance}`}</div>
    </li>
  ));

  return (
    <>
      <SmallTitle text={chancesText} />
      {chancesType === "chances" && (
        <ul className={`chance ${className}`}>{chancesComponent}</ul>
      )}
      {chancesType === "range" && (
        <ul className={`chance ${className}`}>{rangeChancesComponent}</ul>
      )}
      {chancesType === "distribution" && (
        <ul className={`chance ${className}`}>
          {distributionChancesComponent}
        </ul>
      )}
    </>
  );
};

export default Chance;
