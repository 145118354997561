import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import { useCallback } from "react";

export const useLeaderboardHooks = () => {
  const connectedWallet = useConnectedWallet();
  const fetchCollectionData = useCallback(
    async (
      getTokensInfo,
      getOwnedTokens,
      setTokens,
      setOwnedTokens,
      setError,
      limit = 1000
    ) => {
      const errorCheck = (arg) =>
        arg === null
          ? setError("There was a problem while fetching the NFT collection.")
          : setError(null);

      const tokens = await getTokensInfo({
        token_id: [],
        limit,
      });
      errorCheck(tokens);
      setTokens(tokens);

      if (connectedWallet?.terraAddress) {
        const token_ids = await getOwnedTokens({
          owner: connectedWallet?.terraAddress,
          limit,
        });
        if (token_ids && token_ids.length > 0) {
          setOwnedTokens(token_ids);
        }
      } else {
        setOwnedTokens([]);
      }
    },
    [connectedWallet?.terraAddress]
  );

  return { fetchCollectionData };
};

export default useLeaderboardHooks;
