import legendary from "../assets/images/chests/legendary.jpg";
import rare from "../assets/images/chests/rare.jpg";
import uncommon from "../assets/images/chests/uncommon.jpg";
import minting from "../assets/images/chests/minting.jpg";
import legendaryWide from "../assets/images/chests/wide/legendary.jpg";
import rareWide from "../assets/images/chests/wide/rare.jpg";
import uncommonWide from "../assets/images/chests/wide/uncommon.jpg";
import mintingWide from "../assets/images/chests/wide/minting.jpg";

const mintChestUtils = {
  getChestByPower: (power) => {
    if (power >= 1901 && power <= 2000) {
      return {
        image: legendary,
        wideImage: legendaryWide,
        type: "legendary",
        name: "Legendary Chest",
        mintDescription:
          "You just minted the legendary chest which is created from the hearts of our little robots. Be careful because this is the most powerful chest in the entire collection. With the addition of this unique bare metal, each chest will generate MINT tokens as fast as possible!",
      };
    }
    if (power >= 1601 && power <= 1900) {
      return {
        image: rare,
        wideImage: rareWide,
        type: "rare",
        name: "Rare Chest",
        mintDescription:
          "You just minted the rare chest which is made by our oldest and wisest robot! Only he knows how to create this chest. Your chest is made of sapphire blue metal and will generate you great figures of MINT tokens!",
      };
    }
    if (power >= 1001 && power <= 1600) {
      return {
        image: uncommon,
        wideImage: uncommonWide,
        type: "uncommon",
        name: "Uncommon Chest",
        mintDescription:
          "You just minted an uncommon chest, which is produced with the most accessible metals on our planet. Hence this chest can be done without extra costs of interplanetary transporting. The green color connects with the energy of our planet and produces MINT tokens",
      };
    }
    return {
      image: minting,
      wideImage: mintingWide,
      type: "minting",
      name: "Minting...",
      mintDescription: "",
    };
  },
};
export default mintChestUtils;
