import "./Price.scss";
import { ReactComponent as Luna } from "./luna.svg";
import { ReactComponent as Ust } from "./ust.svg";

const Price = ({ price, denom }) => (
  <div className="price">
    {price}
    <span className="price__logo">
      {denom === "uluna" && (
        <>
          $luna <Luna />
        </>
      )}
      {denom === "uusd" && (
        <>
          $ust <Ust />
        </>
      )}
    </span>
  </div>
);

export default Price;
