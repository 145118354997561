import { useEffect, useState } from "react";
import {happyHour} from "../config"
const useHappyHourEventState = () => {
  const [eventState, setEventState] = useState()
  const eventStartTime = (new Date(happyHour.startDate)).getTime()
  const eventEndTime = (new Date(happyHour.startDate)).getTime()+1000*60*60
  const getEventState = () => {
    const now = Date.now()
    if(now < eventStartTime) {
      return "before"
    }
    if(now >= eventStartTime && now <= eventEndTime) {
      return "live"
    }
    if(now > eventEndTime) {
      return "after"
    }
  }

  const updateEventState = () => {
    const currentEventState = getEventState()
    console.log('currentEventState',currentEventState)
    setEventState(currentEventState)
  }

  useEffect(()=>{
    const currentEventState = getEventState()
    setEventState(currentEventState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return {
    eventState,
    updateEventState
  }
}
export default useHappyHourEventState