import "./Counter.scss";
import Title from "../Title/Title";
import { ReactComponent as Indicator } from "./indicator.svg";
import { useEffect, useState } from "react";

let firstPaint = true;

const Counter = ({ left, all, className }) => {
  const [animationState, setAnimationState] = useState("idle");
  const [showFakeNumber, setShowFakeNumber] = useState(false);
  useEffect(() => {
    if (firstPaint) {
      firstPaint = false;
      return;
    }
    setAnimationState("active");
    setShowFakeNumber(true);
    setTimeout(() => {
      setAnimationState("idle");
      setShowFakeNumber(false);
    }, 500);
  }, [left]);

  return (
    <div className={`counter ${className}`}>
      <ul
        className={`counter__items ${
          animationState === "active" ? "counter__items--animate" : ""
        }`}
      >
        {/* FAKE */}
        {showFakeNumber && (
          <li className="counter__item">
            <div className="counter__indicator" />
            <Title
              className="counter__number"
              text={left + 2 <= all ? left + 2 : " "}
            />
          </li>
        )}

        {/* LEFT */}
        <li className="counter__item">
          <div className="counter__indicator" />
          <Title
            className="counter__number"
            text={left + 1 <= all ? left + 1 : " "}
          />
        </li>

        {/* MIDDLE */}
        <li
          className={`counter__item ${
            animationState === "idle" ? "counter__item--active" : ""
          }`}
        >
          <div className="counter__indicator">
            <Indicator className="counter__leaf" />
          </div>
          <Title className="counter__number" text={isNaN(left) || left < 0 ? "-" : left} />
          <Title className="counter__title" text="tokens left" />
        </li>

        {/* RIGHT */}
        <li className="counter__item">
          <div className="counter__indicator" />
          <Title
            className="counter__number"
            text={left - 1 >= 0 ? left - 1 : " "}
          />
        </li>

        {/* HIDDEN */}
        <li className="counter__item">
          <div className="counter__indicator" />
          <Title
            className="counter__number"
            text={left - 2 >= 0 ? left - 2 : " "}
          />
        </li>
      </ul>
    </div>
  );
};

export default Counter;
