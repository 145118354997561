import "./MintChest.scss";
import Text from "../../../components/Text/Text";
import Title from "../../../components/Title/Title";
import SmallTitle from "../../../components/SmallTitle/SmallTitle";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NftContainer from "../../../containers/NftContainer/NftContainer";
import useMintChestsApi from "../../../hooks/useMintChestsApi";
import mintChestUtils from "../../../utils/mintChestsUtils";

const MintChest = () => {
  const { tokenId } = useParams();
  const { state } = useLocation();
  const { getNftInfo } = useMintChestsApi();
  const [nftInfo, setNftInfo] = useState(
    mintChestUtils.getChestByPower(state?.chestPower)
  );

  useEffect(() => {
    const init = async () => {
      const contractQuery = await getNftInfo(tokenId);
      const attr = contractQuery?.extension?.attributes;
      setNftInfo({
        chestPower: attr[0].value,
        mintReward: attr[1].value,
        ...mintChestUtils.getChestByPower(attr[0].value),
      });
    };

    init();
  }, [tokenId, getNftInfo]);

  const image = <img src={nftInfo?.image} alt={nftInfo?.chestPower} />;

  const children = (
    <>
      <Title
        className="mint-chest__name"
        text={`${nftInfo?.type} Mint Chest`}
      />

      <SmallTitle text="description" />
      <div className="collection__description">
        <Text text={`This chest has the following utilities:`} />

        <ul className="mint-chest__description-benefits">
          <li>
            <Text text="MintDAO Yield Farming before TGE"></Text>
          </li>
          <li>
            <Text text="Access to the MintDAO WL competitions"></Text>
          </li>
          <li>
            <Text text="Additional benefits in the future from the MintDAO project"></Text>
          </li>
          <li>
            <Text text="41 69 72 64 72 6F 70 20 4D 69 6E 74 44 41 4F 20 41 76 61 74 61 72"></Text>
          </li>
        </ul>
      </div>

      <p className="mint-chest__info">
        ID: <span>#{tokenId}</span>
      </p>
      <p className="mint-chest__info">
        Power: <span>{nftInfo?.chestPower}</span>
      </p>
      <p className="mint-chest__info">
        Rarity: <span>{nftInfo?.type}</span>
      </p>
    </>
  );

  return (
    <NftContainer
      tokenId={tokenId}
      image={image}
      imagePath={nftInfo?.image}
      children={children}
      title="Mint Chest"
      harvestFee={state?.harvestFee}
      contractAddress={state?.contractAddress}
      harvestReward={nftInfo?.mintReward}
    />
  );
};

export default MintChest;
