import { contractAddresses, harvestFees } from "../../../config";
import Text from "../../../components/Text/Text";
import roboheroAvatarsUtils from "../../../utils/roboheroAvatarsUtils";
import { useNavigate } from "react-router";

export const useRoboheroAvatarsPresenter = () => {
  const navigate = useNavigate();

  return {
    parseTokens: (tokens, network) => {
      const items = tokens?.map((robohero) => {
        const roboheroId = robohero.token_id;
        const roboheroFaction = robohero.faction;
        const roboheroRarity = robohero.rarity;
        const roboheroAdditionalInfo =
          roboheroAvatarsUtils.getRoboheroByTokenInfo(
            roboheroFaction,
            roboheroRarity
          );
        const roboheroReward = robohero.pending_reward / 1_000_000;
        const roboheroAttributeSection = (
          <>
            <Text
              text={`<span>Rarity:</span> ${roboheroRarity}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>Faction:</span> ${roboheroFaction}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>tokens inside:</span>`}
              className="dashboard__item-attribute"
            />
          </>
        );

        const contractAddress = contractAddresses[network.name].roboheroAvatars;
        const harvestFee = harvestFees.roboheroAvatars;
        return {
          tokenImage: roboheroAdditionalInfo.image,
          tokenId: roboheroId,
          tokenName: `Robohero Avatar`,
          itemAttributesSection: roboheroAttributeSection,
          itemReward: roboheroReward,
          contractAddress,
          harvestFee,
          onClick: () => {
            navigate(`/collections/robohero-avatars/nft/${roboheroId}`, {
              state: {
                tokenId: roboheroId,
                contractAddress,
                harvestFee,
                faction: roboheroFaction,
                rarity: roboheroRarity,
              },
            });
          },
        };
      });
      return items;
    },
  };
};

export default useRoboheroAvatarsPresenter;
