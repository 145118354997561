import "./Tabs.scss";

const Tabs = ({ items, primary, onClick, className }) => {
  return (
    <ul className={`tabs ${primary ? "tabs--primary" : ""} ${className}`}>
      {items.map((item) => (
        <li
          onClick={() => onClick(item)}
          key={`tab__${item.name}`}
          className={`tabs__item ${item.active ? "tabs__item--active" : ""}`}
        >
          {item.name}
        </li>
      ))}
    </ul>
  );
};

export default Tabs;
