import "./NftContainer.scss";
import Box from "../../components/Box/Box";
import Container from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Title from "../../components/Title/Title";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useHarvest } from "../../hooks/useHarvest";
import utils from "../../utils/utils";
import Text from "../../components/Text/Text";
import { ReactComponent as MintToken } from "../../pages/Dashboard/mintToken.svg";

/**
 * @param {JSX.Element} image
 * @param {string} imagePath
 * @param {JSX.Element} children
 * @param {JSX.Element} beforeTitle
 * @param {string} title
 * @param {boolean} congratulations
 * @param {string} collectionSalePage
 * @param {string} tokenId
 * @param {number} harvestFee
 * @param {string} contractAddress
 * @param {number} harvestReward
 * @returns {JSX.Element}
 */
const NftContainer = ({
  tokenId,
  image,
  imagePath,
  children,
  beforeTitle,
  title,
  congratulations,
  collectionSalePage,
  harvestFee,
  contractAddress,
  harvestReward,
}) => {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { harvest, HarvestModal } = useHarvest();

  return (
    <div className="nft-container">
      {isOpen && (
        <Lightbox mainSrc={imagePath} onCloseRequest={() => setIsOpen(false)} />
      )}
      <Container className="nft-container__container" narrow>
        <div className="nft-container__photos">
          <Box className="nft-container__box">
            <div onClick={() => setIsOpen(true)}>{image}</div>
          </Box>
        </div>
        <div>
          {beforeTitle}
          {congratulations ? (
            <Title
              className="nft-container__title"
              text={`Congratulations on`}
              suffix={`NFT #${tokenId}`}
              inverted
            ></Title>
          ) : (
            <Title
              className="nft-container__title"
              text={title}
              suffix={`#${tokenId}`}
              inverted
            ></Title>
          )}

          {children}

          {!congratulations && (
            <footer className="nft-container__footer">
              <div className="nft-container__harvest-container">
                <div className="nft-container__harvest-info-section">
                  <Title
                    className="nft-container__harvest-title"
                    text={`${utils.printAmount(harvestReward)}`}
                    inverted
                  />
                  <div className="nft-container__harvest-mint">
                    <Text
                      text={`<span>MINT</span>`}
                      className="nft-container__harvest-attribute"
                    />
                    <MintToken className="nft-container__mint-token-icon" />
                  </div>
                </div>

                <div className="nft-container__harvest-button">
                  <Button
                    variant="contained"
                    onClick={() =>
                      harvest(contractAddress, harvestFee, tokenId)
                    }
                    fullWidth
                  >
                    Harvest MINT
                  </Button>
                </div>
              </div>
              <div className="nft-container__harvest-button--mobile">
                <Button
                  variant="contained"
                  onClick={() => harvest(contractAddress, harvestFee, tokenId)}
                  fullWidth
                >
                  Harvest MINT
                </Button>
              </div>

              <div className="nft-container__go-back-button">
                <Grid spacing={2} container>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="contained"
                      onClick={() => navigate(-1)}
                      fullWidth
                    >
                      Go Back
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </footer>
          )}

          {congratulations && (
            <footer className="nft-container__footer">
              <Grid spacing={2} container>
                <Grid item xs={12} sm={6}>
                  <Link to={collectionSalePage}>
                    <Button variant="contained" fullWidth>
                      Buy More
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link to="/">
                    <Button variant="contained" fullWidth>
                      Back to Homepage
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </footer>
          )}
        </div>
      </Container>
      <HarvestModal />
    </div>
  );
};

export default NftContainer;
