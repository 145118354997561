import React, { useCallback, useState } from "react";
import "./ProvideLP.scss";
import Text from "../../components/Text/Text";
import { ReactComponent as Plus } from "./plus.svg";
import Button from "../../components/Button/Button";
import BalanceInput from "../../components/BalanceInput/BalanceInput";
import useTerra from "../../hooks/useTerra";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useContractApi from "../../hooks/useContractApi";
import useTerraswapApi from "../../hooks/useTerraswapApi";
import { contractAddresses } from "../../config";
import { MsgExecuteContract } from "@terra-money/terra.js";
import debounce from "lodash.debounce";
import utils from "../../utils/utils";
import Modal from "../../components/Modal/Modal";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import useBalances from "../../hooks/useBalances";
import { tokensPrecision } from "../../config";
import TxHash from "../../components/TxHash/TxHash";

const ProvideLP = () => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { executeContractAndWaitForResult } = useContractApi();
  const { getPool } = useTerraswapApi();
  const { balances, updateBalances } = useBalances();

  const [mintTokensSelectedAmount, setMintTokensSelectedAmount] = useState("");
  const [USTSelectedAbount, setUSTSelectedAmount] = useState("");

  const [mintTokensInputError, setMintTokensInputError] = useState(null);
  const [USTInputError, setUSTInputError] = useState(null);

  const [lpFromTransaction, setLpFromTransaction] = useState(0);
  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const onMintValueChangeWrapper = (inputAmount) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    setMintTokensSelectedAmount(amount);
    setMintTokensInputError(
      amount * tokensPrecision > balances?.mint ? "Invalid funds" : null
    );
    setUSTInputError(null);
    onMintValueChange(amount);
  };

  const onUSTValueChangeWrapper = (inputAmount) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    setUSTSelectedAmount(amount);
    setUSTInputError(
      amount * tokensPrecision > balances?.uusd ? "Invalid funds" : null
    );
    setMintTokensInputError(null);
    onUSTValueChange(amount);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMintValueChange = useCallback(
    debounce(async (mintAmount) => {
      const pool = await getPool();
      if (pool) {
        const totalMINT = pool.assets[0].amount;
        const totalUST = pool.assets[1].amount;
        const ustAmount = (mintAmount / totalMINT) * totalUST;
        setUSTSelectedAmount(Number.parseFloat(ustAmount).toFixed(6));
        calculateLPTokens(mintAmount);
      } else {
        setMintTokensSelectedAmount("");
        setUSTSelectedAmount("");
        setMintTokensInputError("Could not estimate");
      }
    }, 300),
    [network.name]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUSTValueChange = useCallback(
    debounce(async (ustAmount) => {
      const pool = await getPool();
      if (pool) {
        const totalMINT = pool.assets[0].amount;
        const totalUST = pool.assets[1].amount;

        const mintAmount = (ustAmount / totalUST) * totalMINT;
        setMintTokensSelectedAmount(Number.parseFloat(mintAmount).toFixed(6));
        calculateLPTokens(mintAmount);
      } else {
        setMintTokensSelectedAmount(0);
        setUSTSelectedAmount(0);
        setMintTokensInputError("Could not estimate");
      }
    }, 300),
    []
  );

  const calculateLPTokens = async (mintAmount) => {
    const pool = await getPool();
    if (pool) {
      const totalLP = pool.total_share;
      const totalMINT = pool.assets[0].amount;
      const rate = totalMINT / totalLP;

      const resultLP = mintAmount / rate;
      setLpFromTransaction(resultLP);
    }
  };

  const addLiquidity = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    const ustAmount = Math.floor(
      USTSelectedAbount * tokensPrecision
    ).toString();
    const mintAmount = Math.floor(
      mintTokensSelectedAmount * tokensPrecision
    ).toString();

    let msgs = [];

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].mintToken,
        {
          increase_allowance: {
            amount: mintAmount,
            spender: contractAddresses[network.name].terraswapPair,
          },
        },
        {}
      )
    );

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].terraswapPair,
        {
          provide_liquidity: {
            assets: [
              {
                info: {
                  token: {
                    contract_addr: contractAddresses[network.name].mintToken,
                  },
                },
                amount: mintAmount,
              },
              {
                info: {
                  native_token: {
                    denom: "uusd",
                  },
                },
                amount: ustAmount,
              },
            ],
            slippage_tolerance: "0.100",
          },
        },
        { uusd: ustAmount }
      )
    );

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].feeCollector,
        {
          receive_funds: {},
        },
        { uusd: tokensPrecision }
      )
    );

    setLoading(true);
    const txResult = await executeContractAndWaitForResult(
      msgs,
      setTxInfo,
      setTxError
    );
    setLoading(false);
    console.log(txResult);

    setMintTokensSelectedAmount(0);
    setUSTSelectedAmount(0);
    setMintTokensInputError(null);
    setUSTInputError(null);
    updateBalances();
  };

  return (
    <div className="provide-lp">
      {isModalOpen && (
        <Modal
          id="modal"
          type={loading ? "warning" : txError ? "error" : "success"}
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <LoadingPopup
            status={loading ? "loading" : txError ? "error" : "success"}
            loadingComponent={
              <>
                {!txInfo ? (
                  <Text
                    className="loading-popup__copy"
                    text="Approve transaction"
                  />
                ) : (
                  <>
                    <TxHash
                      txhash={txInfo?.result.txhash}
                      network={connectedWallet?.network.name}
                    ></TxHash>
                  </>
                )}
              </>
            }
            successComponent={
              <TxHash
                txhash={txInfo?.result.txhash}
                network={connectedWallet?.network.name}
              ></TxHash>
            }
            errorMsg={txError ? txError.toString() : null}
          />
        </Modal>
      )}
      <div className={`provide-lp__inputs`}>
        <BalanceInput
          currency="mint"
          balance={balances?.mint}
          onChange={onMintValueChangeWrapper}
          placeholderValue={0}
          value={mintTokensSelectedAmount}
          error={mintTokensInputError}
        />
        <Plus className="provide-lp__plus" />
        <BalanceInput
          currency="ust"
          balance={balances?.uusd}
          onChange={onUSTValueChangeWrapper}
          placeholderValue={0}
          value={USTSelectedAbount}
          error={USTInputError}
        />
      </div>
      <div className="provide-lp__details">
        <div className="provide-lp__details-col">
          <Text
            className="provide-lp__detail"
            text={`Trading Fee <span>~${utils.printAmount(
              tokensPrecision
            )} UST</span>`}
          />
          <Text
            className="provide-lp__detail"
            text={`LP from Tx <span>~${utils.addSeparatorsToNumber(
              Number.parseFloat(lpFromTransaction).toFixed(2)
            )} LP</span>`}
          />
          <Text
            className="provide-lp__detail"
            text="Est Tx Fee <span>&lt; 2 UST</span>"
          />
        </div>
      </div>
      <Button
        variant="contained"
        fullWidth
        className="provide-lp__button"
        onClick={addLiquidity}
        disabled={
          mintTokensInputError ||
          USTInputError ||
          +mintTokensSelectedAmount === 0 ||
          +USTSelectedAbount === 0
        }
      >
        Add Liquidity
      </Button>
    </div>
  );
};

export default ProvideLP;
