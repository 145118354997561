import { initializeApp } from "firebase/app";

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyCQCsbhhBDYYrvmqtn3Dlp0WKumc_AsF2k",
  authDomain: "mintdao-app.firebaseapp.com",
  databaseURL:
    "https://mintdao-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mintdao-app",
  storageBucket: "mintdao-app.appspot.com",
  messagingSenderId: "375955204530",
  appId: "1:375955204530:web:214e82eb12fd0a8791dcab",
};

// Initialize Firebase
initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
