import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";
import useGenericCollectionHooks from "./GenericHooks/useGenericCollectionHooks";

export const useRoboheroApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();

  const getRoboheroTokens = useCallback(
    async (params) => {
      try {
        const cw721Limit = 30;
        let { owner, startAfter, limit = cw721Limit } = params;
        let response = [];
        let tokensIds = [];
        do {
          const query = {
            owner,
            start_after: startAfter,
            limit,
          };
          if (response.length > 0) {
            query.start_after = response[response.length - 1];
          }
          const result = await queryContract(
            contractAddresses[network.name].roboheroAvatars,
            { tokens: query }
          );
          tokensIds = result.tokens;
          limit -= tokensIds.length;
          response = response.concat(tokensIds);
        } while (limit > 0 && tokensIds.length === cw721Limit);
        if (!response || typeof response !== "object") {
          return null;
        }
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );

  const getRoboheroTokensInfo = useCallback(
    async (params) => {
      try {
        const { tokenIds = [], startAfter, limit } = params;
        const response = await queryContract(
          contractAddresses[network.name].roboheroAvatars,
          {
            get_tokens: {
              token_ids: tokenIds,
              start_after: startAfter,
              limit,
            },
          }
        );

        if (!response || typeof response !== "object") {
          return null;
        }
        return response.tokens.sort(
          (a, b) => parseInt(a.token_id) - parseInt(b.token_id)
        );
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );

  return {
    getWhitelistedAddresses: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].roboheroAvatars,
          { get_whitelisted_addresses: { limit: 1000 } }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getNftInfo: useCallback(
      async (tokenId) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].roboheroAvatars,
            { nft_info: { token_id: tokenId } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getRoboheroTokens,
    getRoboheroTokensInfo,
    ...useGenericCollectionHooks(
      getRoboheroTokens,
      getRoboheroTokensInfo,
      1000,
      10000
    ),
  };
};

export default useRoboheroApi;
