import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useDAOTreasuryGatewayApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  const daoTreasuryGatewayAddress =
    contractAddresses[network.name].daoTreasuryGateway;
  return {
    getTreasuryValue: useCallback(async () => {
      try {
        const response = await queryContract(daoTreasuryGatewayAddress, {
          treasury_value: {},
        });
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, daoTreasuryGatewayAddress]),
  };
};

export default useDAOTreasuryGatewayApi;
