import React from "react";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { WalletProvider } from "@starterra/starterra-tool-dapp";
import {ErrorBoundary} from 'react-error-boundary'

import Gradient from "./components/Gradient/Gradient";
import Navbar from "./containers/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage/HomePage";
import { availableNetworks } from "./config";
import Dashboard from "./pages/Dashboard/Dashboard";
import NftLeaderboard from "./pages/NftLeaderboard/NftLeaderboard";
import { SnackbarProvider } from "notistack";
import SnackMessage from "./components/MessageBar/SnackMessage";
import Collections from "./pages/Collections/Collections";
import BuyMint from "./pages/BuyMint/BuyMint";
import ClaimMint from "./pages/ClaimMint/ClaimMint";
import GenesisAirdrop from "./pages/GenesisAirdrop/GenesisAirdrop";
import Treasury from "./pages/Treasury/Treasury";
import Staking from "./pages/Staking/Staking";
import LegalPopup from "./components/LegalPopup/LegalPopup";
import HappyHourPopup from "./components/HappyHourPopup/HappyHourPopup";
import SpaceCartelsAmuletsCollection from "./pages/SpaceCartelsAmulets/SpaceCartelsAmuletsCollection/SpaceCartelsAmuletsCollection";
import ThorstarterShieldsCollection from "./pages/ThorstarterShields/ThorstarterShieldsCollection/ThorstarterShieldsCollection";
import StakingLeaderboard from "./pages/StakingLeaderboard/StakingLeaderboard";
import NftPage from "./pages/NftPage/NftPage";
import PlaynityTheGenesisCollection from "./pages/PlaynityTheGenesis/PlaynityTheGenesisCollection/PlaynityTheGenesisCollection";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";

const clear301RedirectCache = async () => {
  console.log("clear 301 redirect cache");
  try{
   await fetch('https://app.mintdao.io', {method: 'post'})
  } catch (err) {
    console.error(err);
  }
}

const App = () => {

  useEffect(clear301RedirectCache, []);
  return (
    <SnackbarProvider
      className="notistack"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      maxSnack={3}
      content={(key, message) => <SnackMessage id={key} message={message} />}
    >
      <WalletProvider
        defaultNetwork={availableNetworks[1]}
        walletConnectChainIds={availableNetworks}
      >
        <BrowserRouter>
          <>
            <HappyHourPopup />
            <LegalPopup />
            <Gradient />
            <Navbar />
            <ErrorBoundary FallbackComponent={ErrorFallback}> 
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route
                  path="/collections/space-cartels-amulets"
                  element={<SpaceCartelsAmuletsCollection />}
                />
                <Route
                  path="/collections/:collectionId/leaderboard"
                  element={<NftLeaderboard />}
                />
                <Route
                  path="/collections/thorstarter-shields"
                  element={<ThorstarterShieldsCollection />}
                />
                <Route
                  path="/collections/playnity-the-genesis"
                  element={<PlaynityTheGenesisCollection />}
                />
                <Route
                  path="/collections/:collectionId/nft/:tokenId"
                  element={<NftPage />}
                />
                <Route path="/collections" element={<Collections />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/trade" element={<BuyMint />} />
                <Route path="/vesting" element={<ClaimMint />} />
                <Route path="/airdrop" element={<GenesisAirdrop />} />
                <Route path="/treasury" element={<Treasury />} />
                <Route path="/leaderboard" element={<StakingLeaderboard />} />
                <Route path="/stake" element={<Staking />} />
                <Route path="/stake/:type" element={<Staking />} />
                <Route path="/stake/:type/:action" element={<Staking />} />
                <Route path="/*" element={<Navigate to="/" />} />
              </Routes>
              
            </ErrorBoundary>
            <Footer />
          </>
        </BrowserRouter>
      </WalletProvider>
    </SnackbarProvider>
  );
};

export default App;
