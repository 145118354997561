import React, { useCallback, useState, useEffect } from "react";
import "./BuyMint.scss";
import Title from "../../components/Title/Title";
import Text from "../../components/Text/Text";
import { ReactComponent as Arrows } from "./arrows.svg";
import { ReactComponent as TerraSwapLogo } from "./terraswapLogo.svg";
import Button from "../../components/Button/Button";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import BalanceInput from "../../components/BalanceInput/BalanceInput";
import useTerra from "../../hooks/useTerra";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useContractApi from "../../hooks/useContractApi";
import useTerraswapApi from "../../hooks/useTerraswapApi";
import { contractAddresses } from "../../config";
import { MsgExecuteContract } from "@terra-money/terra.js";
import debounce from "lodash.debounce";
import utils from "../../utils/utils";
import Modal from "../../components/Modal/Modal";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import Tabs from "../../components/Tabs/Tabs";
import useBalances from "../../hooks/useBalances";
import { tokensPrecision } from "../../config";
import TxHash from "../../components/TxHash/TxHash";
import { Link } from "react-router-dom";

const BuyMint = ({ displayAsComponent = true, className }) => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { executeContractAndWaitForResult } = useContractApi();
  const { getSimulation, getReverseSimulation } = useTerraswapApi();

  const { balances, updateBalances } = useBalances();

  const [mintTokensSelectedAmount, setMintTokensSelectedAmount] = useState("");
  const [USTSelectedAbount, setUSTSelectedAmount] = useState("");

  const [mintTokensInputError, setMintTokensInputError] = useState(null);
  const [USTInputError, setUSTInputError] = useState(null);

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isBuySelected, setIsBuySelected] = useState(true);
  const [slippageLimit, setSlippageLimit] = useState(0.03);
  const [swapRate, setSwapRate] = useState(0);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const swapIsBuySelected = () => {
    selectSwapRate();
    setIsBuySelected((prevValue) => !prevValue);
  };

  const selectSwapRate = () => {
    let swapRate = 0;
    if (USTSelectedAbount > 0 && mintTokensSelectedAmount > 0) {
      if (isBuySelected) {
        swapRate = USTSelectedAbount / mintTokensSelectedAmount;
      } else {
        swapRate = mintTokensSelectedAmount / USTSelectedAbount;
      }
    }

    setSwapRate(swapRate * tokensPrecision);
  };

  const onMintValueChangeWrapper = (inputAmount) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    setMintTokensSelectedAmount(amount);
    if (!isBuySelected) {
      setMintTokensInputError(
        amount * tokensPrecision > balances?.mint ? "Invalid funds" : null
      );
    }
    setUSTInputError(null);
    onMintValueChange(amount);
  };

  const onUSTValueChangeWrapper = (inputAmount) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    setUSTSelectedAmount(amount);
    if (isBuySelected) {
      setUSTInputError(
        amount * tokensPrecision > balances?.uusd ? "Invalid funds" : null
      );
    }
    setMintTokensInputError(null);
    onUSTValueChange(amount);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onMintValueChange = useCallback(
    debounce(async (amount) => {
      let simulation = {};

      const msgInfo = {
        token: {
          contract_addr: contractAddresses[network.name].mintToken,
        },
      };

      if (!isBuySelected) {
        // estimate how much you can buy
        simulation = await getSimulation(
          Math.floor(amount * tokensPrecision).toString(),
          msgInfo
        );
        if (simulation) {
          setUSTSelectedAmount(simulation?.return_amount / tokensPrecision);
          setSwapRate(simulation?.return_amount / amount);
        } else {
          setUSTSelectedAmount("");
          setUSTInputError("Could not estimate");
        }
      } else {
        // estimate how many mints you need
        simulation = await getReverseSimulation(
          Math.floor(amount * tokensPrecision).toString(),
          msgInfo
        );

        if (simulation) {
          setUSTSelectedAmount(simulation?.offer_amount / tokensPrecision);
          setSwapRate(simulation?.offer_amount / amount);
        } else {
          setUSTSelectedAmount("");
          setUSTInputError("Could not estimate");
        }
      }
    }, 300),
    [getSimulation, getReverseSimulation, network.name, isBuySelected]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUSTValueChange = useCallback(
    debounce(async (amount) => {
      let simulation = {};
      const msgInfo = {
        native_token: {
          denom: "uusd",
        },
      };

      if (isBuySelected) {
        // estimate how much you can buy
        simulation = await getSimulation(
          Math.floor(amount * tokensPrecision).toString(),
          msgInfo
        );

        if (simulation) {
          setMintTokensSelectedAmount(
            simulation?.return_amount / tokensPrecision
          );
          setSwapRate(simulation?.return_amount / amount);
        }
      } else {
        // estimate how many mints you need
        simulation = await getReverseSimulation(
          Math.floor(amount * tokensPrecision).toString(),
          msgInfo
        );

        if (simulation) {
          setMintTokensSelectedAmount(
            simulation?.offer_amount / tokensPrecision
          );
          setSwapRate(simulation?.offer_amount / amount);
        } else {
          setMintTokensSelectedAmount(0);
          setMintTokensInputError("Could not estimate");
        }
      }
    }, 300),
    [getSimulation, getReverseSimulation, isBuySelected]
  );

  const swap = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    const ustAmount = Math.floor(
      USTSelectedAbount * tokensPrecision
    ).toString();
    const mintAmount = Math.floor(
      mintTokensSelectedAmount * tokensPrecision
    ).toString();

    let msgs = [];
    if (isBuySelected) {
      msgs.push(
        new MsgExecuteContract(
          connectedWallet?.walletAddress,
          contractAddresses[network.name].terraswapPair,
          {
            swap: {
              belief_price: (ustAmount / mintAmount).toString(),
              max_spread: slippageLimit.toString(),
              offer_asset: {
                info: {
                  native_token: {
                    denom: "uusd",
                  },
                },
                amount: ustAmount,
              },
              to: connectedWallet?.terraAddress,
            },
          },
          { uusd: ustAmount }
        )
      );
    } else {
      msgs.push(
        new MsgExecuteContract(
          connectedWallet?.walletAddress,
          contractAddresses[network.name].mintToken,
          {
            send: {
              amount: mintAmount,
              contract: contractAddresses[network.name].terraswapPair,
              msg: Buffer.from(
                JSON.stringify({
                  swap: {
                    belief_price: (mintAmount / ustAmount).toString(),
                    max_spread: slippageLimit.toString(),
                  },
                })
              ).toString("base64"),
            },
          },
          {}
        )
      );
    }

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].feeCollector,
        {
          receive_funds: {},
        },
        { uusd: tokensPrecision }
      )
    );

    setLoading(true);
    const txResult = await executeContractAndWaitForResult(
      msgs,
      setTxInfo,
      setTxError
    );
    setLoading(false);

    setTxInfo(txResult);

    setMintTokensSelectedAmount("");
    setUSTSelectedAmount("");
    setMintTokensInputError(null);
    setUSTInputError(null);
    updateBalances();
  };

  const printSucessfullSwapComponent = () => {
    if (!txInfo?.logs) {
      return;
    }

    let offer_amount = txInfo?.logs[0]?.events?.[3]?.attributes.filter(
      (x) => x.key === "offer_amount"
    )[0].value;
    let return_amount = txInfo?.logs[0]?.events?.[3]?.attributes.filter(
      (x) => x.key === "return_amount"
    )[0].value;

    let offer_asset = isBuySelected ? "UST" : "MINT";
    let ask_asset = isBuySelected ? "MINT" : "UST";

    return (
      <div>
        <Text
          className="loading-popup__copy"
          text={`Successfully swapped ${utils.printAmount(
            offer_amount
          )} of ${offer_asset} to ${utils.printAmount(
            return_amount
          )} of ${ask_asset}.`}
        ></Text>

        <TxHash
          txhash={txInfo?.txhash}
          network={connectedWallet?.network.name}
        ></TxHash>
        {isBuySelected && (
          <div className="buy-mint__stake-mint-button">
            <Link to="/stake">
              <Button variant="contained">Stake your MINT Tokens</Button>
            </Link>
          </div>
        )}
      </div>
    );
  };

  return (
    <AppBgLayout
      displayBackgroundLogo={displayAsComponent}
      className={className}
    >
      {isModalOpen && (
        <Modal
          id="modal"
          type={loading ? "warning" : txError ? "error" : "success"}
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <LoadingPopup
            status={loading ? "loading" : txError ? "error" : "success"}
            loadingComponent={
              <>
                {!txInfo ? (
                  <Text
                    className="loading-popup__copy"
                    text="Approve transaction"
                  />
                ) : (
                  <>
                    <TxHash
                      txhash={txInfo?.result?.txhash}
                      network={connectedWallet?.network.name}
                    ></TxHash>
                  </>
                )}
              </>
            }
            successComponent={printSucessfullSwapComponent()}
            errorMsg={txError ? txError.toString() : null}
          />
        </Modal>
      )}

      <div className="buy-mint__title-container">
        <div>
          <Title
            text={isBuySelected ? "buy your" : "sell your"}
            suffix="mint"
            inverted
            className="buy-mint__title"
          />
          <Text text="Instantly trade MINT and UST." />
        </div>
        <Tabs
          className="buy-mint__tabs"
          items={[
            { name: "Buy MINT", active: isBuySelected },
            { name: "Sell MINT", active: !isBuySelected },
          ]}
          onClick={swapIsBuySelected}
        />
      </div>
      <div
        className={`buy-mint__inputs ${
          isBuySelected ? "buy-mint__inputs--reversed" : ""
        }`}
      >
        <BalanceInput
          currency="mint"
          balance={balances?.mint}
          onChange={onMintValueChangeWrapper}
          placeholderValue={0}
          value={mintTokensSelectedAmount}
          error={mintTokensInputError}
        />
        <Arrows className="buy-mint__arrows" onClick={swapIsBuySelected} />
        <BalanceInput
          currency="ust"
          balance={balances?.uusd}
          onChange={onUSTValueChangeWrapper}
          placeholderValue={0}
          value={USTSelectedAbount}
          error={USTInputError}
        />
      </div>
      <div className="buy-mint__details">
        <div className="buy-mint__details-col">
          <Text
            className="buy-mint__detail"
            text={`Trading Fee <span>~${utils.printAmount(
              0.3 * tokensPrecision
            )} UST</span>`}
          />
          <Text
            className="buy-mint__detail"
            text="Est Tx Fee <span>&lt; 2 UST</span>"
          />
        </div>
        <div className="buy-mint__details-col">
          <Text
            className="buy-mint__detail"
            text={`Rate <span>~${utils.printAmount(swapRate)} ${
              isBuySelected ? "Mint per UST" : "UST per Mint"
            }</span>`}
          />
          <p className="text buy-mint__detail">
            Slippage Limit
            <span>
              <button
                className={`mini-button ${
                  slippageLimit === 0.01 ? "mini-button--active" : ""
                }`}
                onClick={() => setSlippageLimit(0.01)}
              >
                1%
              </button>
              <button
                className={`mini-button ${
                  slippageLimit === 0.03 ? "mini-button--active" : ""
                }`}
                onClick={() => setSlippageLimit(0.03)}
              >
                3%
              </button>
              <button
                className={`mini-button ${
                  slippageLimit === 0.1 ? "mini-button--active" : ""
                }`}
                onClick={() => setSlippageLimit(0.1)}
              >
                10%
              </button>
            </span>
          </p>
        </div>
        <div className="text powered-by">
          Powered by <TerraSwapLogo width="80px" />
        </div>
      </div>
      <Button
        variant="contained"
        fullWidth
        className="buy-mint__button"
        onClick={swap}
        disabled={
          mintTokensInputError ||
          USTInputError ||
          +mintTokensSelectedAmount === 0 ||
          +USTSelectedAbount === 0
        }
      >
        {isBuySelected ? "Buy Now" : "Sell Now"}
      </Button>
    </AppBgLayout>
  );
};

export default BuyMint;
