import "./AppBgLayout.scss";
import React, { useRef } from "react";
import useOnScreen from "../../hooks/useOnScreen";
import { ReactComponent as Logo } from "../../assets/images/logo_shape.svg";
import Container from "../Container/Container";

const AppBgLayout = ({ children, displayBackgroundLogo = true, className }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <div className={`app-bg-layout ${className ? className : ""}`} ref={ref}>
      <Logo
        className={`app-bg-layout__logo ${
          isVisible && displayBackgroundLogo
            ? "app-bg-layout__logo--visible"
            : ""
        }`}
      />

      <Container className="app-bg-layout__container" narrow={true}>
        {children}
      </Container>
    </div>
  );
};

export default AppBgLayout;
