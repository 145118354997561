import minting from "../assets/images/chests/minting.jpg";
import level1 from "../assets/images/ThorstarterShields/Level1.jpg";
import level2 from "../assets/images/ThorstarterShields/Level2.jpg";
import level3 from "../assets/images/ThorstarterShields/Level3.jpg";
import level4 from "../assets/images/ThorstarterShields/Level4.jpg";
import level5 from "../assets/images/ThorstarterShields/Level5.jpg";

const ThorstarterShieldsUtils = {
  getThorstarterShieldsByTokenInfo: (level) => {
    // TODO refactor so level is only matched by trait 
    if(level === "Level1"){
      level = "Basic"
    }
    if(level === "Level2"){
      level = "Metal"
    }
    if(level === "Level3"){
      level = "Magical"
    }
    if(level === "Level4"){
      level = "Silver Skull"
    }
    if(level === "Level5"){
      level = "Golden Dragon"
    }
    const propertyInfoMap = {
      Basic: {
        name: "Basic",
        levelNumber: 1,
        image: level1,
      },
      Metal: {
        name: "Metal",
        levelNumber: 2,
        image: level2,
      },
      Magical: {
        name: "Magical",
        levelNumber: 3,
        image: level3,
      },
      "Silver Skull": {
        name: "Silver Skull",
        levelNumber: 4,
        image: level4,
      },
      "Golden Dragon": {
        name: "Golden Dragon",
        levelNumber: 5,
        image: level5,
      },
    };

    return (
      propertyInfoMap[level] || {
        name: "",
        levelNumber: "",
        image: minting,
      }
    );
  },
};

export default ThorstarterShieldsUtils;
