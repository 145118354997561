import "./BoxImagesCarousel.scss";
import Box from "../Box/Box";
import { useEffect, useState } from "react";

const BoxImagesCarousel = ({ className, NFTImages, interval = 2000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentIndex === NFTImages?.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [NFTImages, currentIndex, interval]);

  const items = NFTImages.map((image, index) => (
    <li
      key={index}
      className={`box-images-carousel__item ${
        currentIndex !== index
          ? "box-images-carousel__item--hidden"
          : "box-images-carousel__item--active"
      }`}
    >
      <Box className="box-images-carousel__box">
        <img src={image} alt={index} />
      </Box>
    </li>
  ));

  return <ul className={`box-images-carousel ${className}`}>{items}</ul>;
};

export default BoxImagesCarousel;
