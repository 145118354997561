import React, { useEffect, useState } from "react";
import "./LegalPopup.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { ReactComponent as Icon } from "./confirmIcon.svg";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { get, getDatabase, ref, set } from "firebase/database";
import Modal from "../Modal/Modal";
import { useWallet } from "@starterra/starterra-tool-dapp";

const LegalPopup = () => {
  const connectedWallet = useConnectedWallet();
  const { disconnect } = useWallet();
  const db = getDatabase();
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const fetchLegalData = async () => {
      if (!connectedWallet) {
        return;
      }

      const termsOfUse = await get(
        ref(db, `users/${connectedWallet.walletAddress}/consents/terms_of_use`)
      );

      const privacyPolicy = await get(
        ref(
          db,
          `users/${connectedWallet.walletAddress}/consents/privacy_policy`
        )
      );

      if (!termsOfUse.exists() || !privacyPolicy.exists()) {
        setModalVisible(true);
      }
    };

    fetchLegalData();
  }, [db, connectedWallet]);

  const confirm = () => {
    Promise.all([
      set(
        ref(db, `users/${connectedWallet.walletAddress}/consents/terms_of_use`),
        true
      ),
      set(
        ref(
          db,
          `users/${connectedWallet.walletAddress}/consents/privacy_policy`
        ),
        true
      ),
    ]).then(() => {
      setModalVisible(false);
    });
  };

  const cancel = () => {
    disconnect();
    setModalVisible(false);
  };

  return (
    <>
      {isModalVisible && (
        <Modal onCloseClick={() => {}}>
          <div className="legal-popup">
            <div className="legal-popup__title-container">
              <Text className="legal-popup__title" text="Confirmation" />
              <Icon className="legal-popup__icon" />
            </div>

            <div className="legal-popup__container-confirmation-item">
              <label
                htmlFor="consent1"
                className="legal-popup__confirmation-text"
              >
                I hereby declare that I have read and understood the{" "}
                <a
                  href="https://mintdao.io/TERMS_OF_USE.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  href="https://mintdao.io/PRIVACY_POLICY.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>{" "}
                applicable on the Platform and I accept to be bound by their
                provisions.
              </label>
            </div>

            <footer className="legal-popup__footer">
              <Button onClick={cancel} variant="outlined">
                Disconnect
              </Button>
              <Button onClick={confirm} variant="contained">
                Confirm
              </Button>
            </footer>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LegalPopup;
