import { LCDClient } from "@terra-money/terra.js";
import { useWallet } from "@starterra/starterra-tool-dapp";
import { useMemo } from "react";

export const useTerra = () => {
  const { network } = useWallet();
  const terra = useMemo(() => {
    return new LCDClient({
      URL: network.lcd,
      chainID: network.chainID,
    });
  }, [network]);
  return { terra, network };
};

export default useTerra;
