import { useCallback } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useStakingApi = (stakingType) => {
  const { network } = useTerra();
  const connectedWallet = useConnectedWallet();
  const { queryContract, executeContractAndWaitForResult } = useContractApi();
  const stakingContractAddress =
    stakingType === "mint"
      ? contractAddresses[network.name].stakingSAS
      : contractAddresses[network.name].stakingLP;
  const tokenContractAddress =
    stakingType === "mint"
      ? contractAddresses[network.name].mintToken
      : contractAddresses[network.name].lpToken;
  const feeCollectorAddress = contractAddresses[network.name].feeCollector;
  return {
    getConfig: useCallback(async () => {
      try {
        const response = await queryContract(stakingContractAddress, {
          config: {},
        });
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, stakingContractAddress]),
    getState: useCallback(async () => {
      try {
        const response = await queryContract(stakingContractAddress, {
          state: {},
        });
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, stakingContractAddress]),
    getStakerInfo: useCallback(
      async (staker) => {
        try {
          const response = await queryContract(stakingContractAddress, {
            staker_info: {
              staker,
            },
          });
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, stakingContractAddress]
    ),
    getStakerUnbondings: useCallback(
      async (params) => {
        try {
          const unbondingsLimit = 1000;
          let { staker, startAfter, limit = unbondingsLimit } = params;
          let response = [];
          let unbondings = [];
          do {
            const query = {
              staker,
              start_after: startAfter,
              limit,
            };
            if (response.length > 0) {
              query.start_after =
                response[response.length - 1].unbonding_key.toString();
            }
            const result = await queryContract(stakingContractAddress, {
              staker_unbondings: query,
            });
            unbondings = result.unbondings;
            response = response.concat(unbondings);
          } while (unbondings.length === unbondingsLimit);

          if (!response || typeof response !== "object") {
            return null;
          }
          return response.sort((a, b) => a.unbonding_key - b.unbonding_key);
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, stakingContractAddress]
    ),
    sendAndBond: useCallback(
      async (
        amount,
        unbondingPeriod,
        setLoading,
        setTxInfo,
        setTxError,
        afterTransaction
      ) => {
        try {
          setLoading(true);
          const bondMsg = {
            bond: {
              unbonding_period: unbondingPeriod,
            },
          };
          const encodedBondMsg = Buffer.from(JSON.stringify(bondMsg)).toString(
            "base64"
          );
          const msg = {
            amount: amount.toString(),
            contract: stakingContractAddress,
            msg: encodedBondMsg,
          };
          console.log(
            "response",
            msg,
            connectedWallet?.walletAddress,
            tokenContractAddress
          );
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              tokenContractAddress,
              {
                send: msg,
              }
            ),
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              feeCollectorAddress,
              {
                receive_funds: {},
              },
              { uusd: 1000000 }
            ),
          ];
          // const response = await executeContract(msgs);
          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          afterTransaction();
          setLoading(false);
          console.log("response", response);
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        stakingContractAddress,
        tokenContractAddress,
        feeCollectorAddress,
        executeContractAndWaitForResult,
      ]
    ),
    claimRewards: useCallback(
      async (setLoading, setTxInfo, setTxError, afterTransaction) => {
        try {
          setLoading(true);
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              stakingContractAddress,
              {
                claim_reward: {},
              },
              { uusd: 1000000 }
            ),
          ];
          // const response = await executeContract(msgs);

          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          afterTransaction();
          setLoading(false);

          console.log("response", response);
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        stakingContractAddress,
        executeContractAndWaitForResult,
      ]
    ),
    submitToUnbond: useCallback(
      async (
        unbondingPeriod,
        amount,
        setLoading,
        setTxInfo,
        setTxError,
        afterTransaction
      ) => {
        try {
          setLoading(true);
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              stakingContractAddress,
              {
                submit_to_unbond: {
                  unbonding_period: unbondingPeriod,
                  amount,
                },
              },
              { uusd: 1000000 }
            ),
          ];
          // const response = await executeContract(msgs);

          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          afterTransaction();
          setLoading(false);
          console.log("response", response);
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        stakingContractAddress,
        executeContractAndWaitForResult,
      ]
    ),
    unbond: useCallback(
      async (
        unbondingKey,
        instantWithdraw = false,
        setLoading,
        setTxInfo,
        setTxError,
        afterTransaction
      ) => {
        try {
          setLoading(true);
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              stakingContractAddress,
              {
                unbond: {
                  unbonding_key: unbondingKey,
                  instant_withdraw: instantWithdraw,
                },
              },
              {}
            ),
          ];
          // const response = await executeContract(msgs);

          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          afterTransaction();
          setLoading(false);
          console.log("response", response);
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        stakingContractAddress,
        executeContractAndWaitForResult,
      ]
    ),
  };
};

export default useStakingApi;
