import "./Period.scss";
import SmallTitle from "../SmallTitle/SmallTitle";
import ReactSlider from "react-slider";
import { ReactComponent as Indicator } from "../Counter/indicator.svg";
import Text from "../Text/Text";
import { useState } from "react";

const Period = ({ min, max, value, onChange, className }) => {
  const [isDescriptionVisible, setDescriptionVisibility] = useState(false);
  return (
    <div className={`period ${className}`}>
      <div className="period__title">
        <SmallTitle text="unbonding period" />
        &nbsp;
        <SmallTitle className="period__days" text="in days" />
        &nbsp;
        <div
          className="period__description-button"
          onClick={() => setDescriptionVisibility((prev) => !prev)}
        >
          <SmallTitle text="(?)" />
        </div>
      </div>

      <Text
        className={`period__description ${
          isDescriptionVisible && "period__description--visible"
        }`}
        text="Unbonding period defines the amount of time for the unstaked tokens after which you can withdraw them without any commission"
      />

      <ReactSlider
        className="period__slider"
        thumbClassName="period__thumb"
        trackClassName="period__track"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        renderThumb={(props, state) => (
          <div {...props}>
            <Indicator className="period__indicator" />
            <SmallTitle text={state.valueNow} />
          </div>
        )}
      />
      <div className="period__min-max">
        {value !== min && <SmallTitle text={min} className="period__min" />}
        {value !== max && <SmallTitle text={max} className="period__max" />}
      </div>
    </div>
  );
};

export default Period;
