import "./Hamburger.scss";

const Hamburger = ({ onClick, isOpen, className }) => (
  <button
    className={`hamburger ${isOpen ? "hamburger--is-open" : ""} ${className}`}
    onClick={onClick}
  >
    <div className="hamburger__line" />
    <div className="hamburger__line" />
    <div className="hamburger__line" />
  </button>
);

export default Hamburger;
