import ImageUtils from "./ImageUtils";
import minting from "../assets/images/chests/minting.jpg";

const ALL_NFTS_COUNT = 1800;

const PlaynityTheGenesisUtils = {
  getRarityScoreFromDna: (dna) => dnaMap[dna],
  getRankFromDna: (dna) => {
    console.log(dna);
    let dnaScore = dnaMap[dna];
    const scores = Object.keys(dnaMap)
      .map((key) => dnaMap[key])
      .sort((a, b) => b - a);
    let rank = 1;
    for (let index in scores) {
      if (dnaScore >= scores[index]) {
        return rank;
      }
      rank++;
    }
    return "-";
  },
  getPlaynityTheGenesisTokenInfo: (attributes, image) => {
    if (!attributes) {
      return {
        name: "Playnity The Genesis",
        rarity: "",
        image: minting,
      };
    }

    const nftAttributes = attributes
      .filter((item) => traits.includes(item.trait_type))
      .map((attribute) => {
        return {
          percent:
            (statistics?.[attribute.trait_type]?.[attribute.value] /
              ALL_NFTS_COUNT) *
            100,
          ...attribute,
        };
      });

    return {
      name: "Playnity The Genesis",
      image: ImageUtils.resolveIPFSImage(image),
      mintReward: attributes?.[0].value,
      rarity: attributes?.[4].value,
      pexPoints: attributes?.[1].value,
      votingAndAirdropPower: attributes?.[3].value,
      presaleTickets: attributes?.[2].value.substring(
        0,
        attributes?.[2].value.indexOf(" ")
      ), // TODO: można to jakoś lepiej wystawić w SC?
      attributes: nftAttributes,
    };
  },
};

export default PlaynityTheGenesisUtils;

const statistics = {
  Background: {
    Pink: 1,
    "Gradient purple orange": 202,
    "Gradient pink green": 145,
    "Gradient yellow orange": 132,
    "Gradient blue purple": 191,
    "Gradient pink purple": 201,
    "Gradient navy blue": 237,
    "Gradient purple green": 205,
    "Gradient green blue": 200,
    "Gradient navy purple": 140,
    "Gradient blue orange": 146,
  },
  Body: {
    Normal: 1100,
    Golden: 20,
    Shine: 60,
    Rainbow: 120,
    Darker: 500,
  },
  Mouth: {
    Smile: 335,
    Braces: 36,
    "Shine teeth": 303,
    "Tongue out": 420,
    Cigarette: 110,
    "Rainbow teeth": 261,
    "Golden tooth": 144,
    Normal: 191,
  },
  Tattoos: {
    None: 1214,
    Tattoos: 586,
  },
  Hair: {
    Pink: 1,
    Original: 473,
    Golden: 42,
    Ombre: 243,
    Messy: 214,
    Short: 189,
    Samurai: 196,
    Rainbow: 150,
    "Short curly": 127,
    Shine: 97,
    Platinum: 68,
  },
  Ears: {
    Medium: 810,
    "Small golden": 9,
    "Medium golden": 11,
    "Small shine": 33,
    "Medium shine": 27,
    "Small rainbow": 63,
    "Medium rainbow": 57,
    Small: 790,
  },
  Clothes: {
    "Hoodie black with rose": 155,
    "PN costume": 155,
    "Tshirt PlayNity orange": 151,
    "Trooper costume": 141,
    "Hoodie purple": 222,
    "Teddybear costume": 110,
    "Tshirt PlayNity grey": 178,
    "Hoodie orange": 297,
    "Tshirt PlayNity pink": 138,
    "Astronaut costume": 99,
    "Tshirt PlayNity green": 154,
  },
  "Face Details": {
    None: 659,
    Freckless: 328,
    Mustache: 230,
    Scars: 353,
    "Short beard": 230,
  },
  Eyebrows: {
    Pink: 1,
    Thick: 432,
    Neutral: 818,
    "Sad normal": 320,
    "Angry normal": 229,
  },
  Eyes: {
    "Big hearts": 1,
    "Normal gold": 75,
    "Big gold": 54,
    Big: 410,
    Normal: 484,
    "Big two colors": 265,
    "Big neons": 124,
    "Normal two colors": 256,
    "Normal neons": 131,
  },
  Jewelry: {
    None: 742,
    "Gun necklace gold": 79,
    "PN necklace": 293,
    "Pad necklace gold": 84,
    "Pad necklace": 352,
    "Gun necklace": 250,
  },
  Headwear: {
    None: 1017,
    "Black bandana": 387,
    Crown: 117,
    "Purple bandana": 279,
  },
  Glasses: {
    None: 791,
    "Round glasses": 327,
    Sunglasses: 297,
    Monocle: 385,
  },
  "Head Features": {
    Hearts: 1,
    "Silver earring right": 142,
    "Golden earring left": 80,
    "Silver earring left": 156,
    Airpods: 322,
    "Golden earring right": 70,
    "Diamond earring right": 78,
    "Diamond earring left": 70,
    None: 641,
    Headphones: 240,
  },
};

const traits = [
  "Background",
  "Body",
  "Mouth",
  "Tattoos",
  "Hair",
  "Ears",
  "Clothes",
  "Face Details",
  "Eyebrows",
  "Eyes",
  "Jewelry",
  "Headwear",
  "Glasses",
  "Head Features",
];

const dnaMap = {
  "00000000000000": 440.56181351805657,
  "010111011111011": 438.79783856382176,
  "020002120220120": 402.193448578294,
  "010000000012022": 435.686525505279,
  "030211132111233": 396.0347906808464,
  "040111142133004": 394.1696159808474,
  "020201053101005": 429.61609166350786,
  "010013163044326": 391.4030902461324,
  "010311170155026": 390.66842014290813,
  "030201183204032": 390.36569935175567,
  "000014010163012": 426.79900772945894,
  "050002192223000": 389.3147539690612,
  "050015131151121": 388.80892655506085,
  "060406102001205": 388.3383006859468,
  "060003001073017": 424.02599666342576,
  "050000081320033": 422.4559496328211,
  "070017000034005": 421.87838464430575,
  "050015152333137": 384.97856362617,
  "020511094104002": 421.20984912193046,
  "000311151040223": 384.7957976406779,
  101603272101206: 214.02713347221732,
  111316343312210: 225.49639260475527,
  "1110132a4124227": 212.32526609254327,
  151002383131217: 223.78180829833198,
  "1611082a3112134": 210.68284314754803,
  161611372222223: 222.63296782851336,
  141005201032004: 210.1716873716403,
  131018394165107: 221.73575808018197,
  "1410172a0022122": 209.56574212071249,
  181012304171008: 221.4827126585698,
  101010312022004: 221.35495745885248,
  "1812073a2365224": 220.90726236664747,
  "1912133a3373334": 220.92046145913784,
  151719310140004: 220.5848615507449,
  181515361003304: 219.91549103770603,
  101110230212226: 206.82476782469223,
  171004262254307: 206.8177205706429,
  131716280343004: 206.38703094867284,
  181311223131015: 206.30752765532375,
  "1212002a4212321": 206.34294864665813,
  "1614123a4003102": 218.27576162969933,
  181414273370102: 205.76612849017832,
  "1517082a1260106": 205.69468046452687,
  171014381142338: 217.63216283069636,
  "1410103a4355007": 216.85450320863936,
  "1710162a1124300": 204.55551828566576,
  191101372262318: 216.27273434881403,
  171205281115031: 203.76622261974035,
  131311361362321: 215.83793366443672,
  171511383251037: 215.7218536299979,
  "1317022a0112311": 203.46471681429733,
  111301241072000: 203.32339378033763,
  101500360203024: 215.4980643101578,
  171108261270301: 203.28392083124248,
  171101302164203: 214.5707194837895,
  131014364144022: 214.45951208430105,
  121517381303007: 213.9111993686811,
  121315292103300: 201.60522810450888,
  141517211330126: 201.495236304665,
  161718343030001: 213.58942465740213,
  171614254012213: 201.39555305170032,
  121201283242127: 201.12794606249636,
  101013292322127: 200.98551568010484,
  101616224244204: 200.93668264849998,
  181210381050105: 212.56966410288146,
  101405291330035: 200.30588193392913,
  101618354044201: 212.28837885677416,
  "1710153a4234008": 211.85059723129194,
  101116233304126: 199.48685269387113,
  191709203072001: 199.43113209889745,
  171507204111207: 199.2713924089095,
  161111391125005: 210.985075642257,
  "1113183a0215038": 210.68526297839145,
  161412271000103: 197.82287962500894,
  191316344153207: 209.5670908811997,
  151109244132336: 196.99956578975795,
  121501283032033: 196.63500267497088,
  151712263014212: 196.65564088847304,
  "1716022a1115237": 196.54613194057887,
  101016243132107: 196.48208284571314,
  242005453231107: 155.21573656380707,
  282206402101001: 155.20171401177302,
  232400420114010: 155.08809390619814,
  222711534321107: 158.0421607503319,
  282611570162307: 158.0001587755607,
  232105581233034: 158.04430201981972,
  292000421032123: 154.8881154593774,
  232103551243314: 157.7867933176121,
  292311552254007: 157.58010968434172,
  232202530240336: 157.49464446453305,
  262112583143026: 157.4374233113965,
  212014510132307: 157.28828549345053,
  232401461244103: 154.12789580854417,
  272313543143325: 157.23202022194695,
  292015411121017: 154.03520060152735,
  222218481105000: 153.65561613591953,
  202106541114302: 156.51028610655717,
  242211553072002: 156.37563418020596,
  202513473325216: 153.46941071868724,
  202605570002005: 156.49144518527007,
  212400484112017: 153.27624168710568,
  212013510121007: 155.88269386120314,
  242709540101007: 155.54941489263902,
  242513561302201: 155.56301093817893,
  "2422125a0212005": 155.4801757944871,
  282200510253310: 155.2966852750436,
  242715570114302: 155.06904708104983,
  262309400160000: 152.01871682839828,
  272217463071235: 151.9475817893783,
  "2323114a0024022": 151.83671389870523,
  282209514133023: 154.86675026943263,
  202117574155014: 154.86133029746188,
  202212440143104: 151.65956671716683,
  272610481365324: 151.59087331017676,
  232013450145007: 151.53615969748523,
  272210464143215: 151.5070934316752,
  282713520114000: 154.3768801281835,
  222204570061201: 154.21668761768822,
  202307491115010: 151.04162643190332,
  252013471032337: 151.08177238071067,
  242402522022201: 154.11193424853263,
  272316540101001: 154.08815042557796,
  252514562050001: 153.97675799016898,
  232616492074101: 150.86472867753824,
  282300551005136: 153.85018432165126,
  242318404153308: 150.66316263048628,
  "2726164a3224201": 150.6306883699542,
  222619464133008: 150.44135597310827,
  272119533072021: 153.34360798280974,
  "2824135a4244021": 153.0696487583517,
  212003431142017: 149.80905714374626,
  252715410050100: 149.73558447079682,
  202701432062137: 149.52780617485615,
  232411453132137: 149.39047612955207,
  202204494023035: 149.39756730433186,
  212318464232204: 149.34141770067245,
  282110453173031: 149.18362844226908,
  252706582134126: 152.13673353144767,
  292414421153137: 149.0475013191966,
  202418530034026: 152.03290522452457,
  222211561262323: 151.90471437863275,
  "2027035a4014023": 151.9454242206852,
  202113512100008: 151.60177717449534,
  222515563051314: 151.43462039504777,
  212618504361022: 151.37496931944008,
  212316451102016: 148.21255793548957,
  "2525115a1061038": 151.11650094451417,
  "2725134a3130115": 148.08633930316753,
  "2726005a2111317": 150.91199462142393,
  "2421034a3000017": 147.77168342773612,
  "2323194a4162301": 147.6337437363465,
  272216434123034: 147.54028599920838,
  262313410020036: 147.44112469657946,
  242010451142028: 147.4079572762224,
  272604543232126: 150.33315459329273,
  252710591103022: 150.2285204724194,
  252612561343004: 150.19709457851465,
  292311551034000: 150.09792386936164,
  282316461330004: 147.11288294039616,
  272702504011307: 150.09977152604432,
  252304553074205: 150.02448994675697,
  282718401062016: 146.8489495483875,
  292318453340038: 146.81958366100838,
  "2525114a0242137": 146.55680687160736,
  292318531361304: 149.54870679961687,
  262414493101313: 146.32421332183608,
  262419501054307: 149.35243383533106,
  272500500215000: 149.33327806159093,
  222310581130004: 149.16655845539557,
  202212494251026: 146.0138304122946,
  292304440073137: 146.1031290018946,
  232616583162034: 149.08383817607879,
  272618574120027: 149.00208834503144,
  232315464330006: 145.95817304966124,
  252619440154027: 145.93998085243297,
  "2225154a4253017": 145.93742930405296,
  212114420030322: 145.90725495514587,
  222312490005110: 145.68279676831602,
  232212573325205: 148.59654796692402,
  272510401012001: 145.5718814123472,
  242415503161125: 148.4400118973622,
  202702500215107: 148.38691904758787,
  "2925184a3034104": 145.2639723973661,
  242004461132013: 145.12408566551383,
  252418411135004: 145.1006405039215,
  262106511255004: 148.12456937477097,
  272213574101322: 148.11007788937872,
  232418411134004: 144.84342392833062,
  252715464254306: 144.83978186772444,
  222111403024007: 144.72445557133045,
  292310462140026: 144.68335687214463,
  262509420222208: 144.64582681906984,
  "2525195a1062200": 147.4035136721326,
  "2823085a2152120": 147.3948957719149,
  292518581162228: 147.35973492347696,
  222113451130004: 144.35017892535672,
  292511434164007: 144.26978684861612,
  262309434055123: 144.2987129789234,
  252216402072306: 144.23569141908285,
  232316442134324: 144.21329006289733,
  363117653162211: 106.45756148627424,
  363615632070033: 106.43051347337581,
  383100770372135: 106.51467682955472,
  343119670374103: 106.43379514445382,
  "3137006a0223203": 106.40747817848201,
  333219731120313: 106.390313692945,
  343716653120322: 106.32196490571843,
  333515743271001: 106.3825930285665,
  373710750374306: 106.34678336268374,
  393315701054136: 106.3408574010497,
  353202732015123: 106.26165783996824,
  353708662042306: 106.18280373455846,
  383415752121004: 106.19795735639099,
  313710753112010: 106.1263195589749,
  313108634361203: 106.02431255631979,
  333113780233020: 106.08585226312003,
  "3834156a4062005": 105.97000313389891,
  "3232176a4164232": 105.94582595001825,
  393700713353027: 105.97177339133536,
  "3236136a3242135": 105.88670383791849,
  333211634121137: 105.76215745458471,
  "3032107a2133024": 105.85797568580833,
  323413601140337: 105.77325529999528,
  353415670052233: 105.73712759666427,
  393213640174101: 105.7508283538606,
  363300720003033: 105.70615099859809,
  363100722022216: 105.65505129872568,
  373210623111207: 105.49415803555905,
  323516721360103: 105.58461440479826,
  "3336176a3032321": 105.5134678862013,
  313612721331104: 105.543416005415,
  363411661162003: 105.39139748006343,
  383318740040007: 105.50973619836384,
  353715694301108: 105.37697817964144,
  363608664034004: 105.37688544927005,
  323711731132013: 105.32822647022131,
  343316670274011: 105.3307383789346,
  363316723105112: 105.38719298657075,
  333617612131105: 105.21538125357495,
  303106703004318: 105.25019497306353,
  343410773112103: 105.1046920502059,
  393312743302032: 105.11569388426442,
  383106653055227: 105.03927065456384,
  373613660214033: 104.87181791485959,
  383307744244237: 104.85510375533704,
  363400794354010: 104.77934695024659,
  "3733126a3133106": 104.7746252587514,
  363300732120026: 104.67639365747473,
  343515643101102: 104.61221815611627,
  313212703272331: 104.68584263611204,
  393202702133330: 104.64951501615643,
  343316652073317: 104.58431720199553,
  343612771341131: 104.62125061770062,
  323617620152318: 104.46697244187482,
  353619600054003: 104.40316326689955,
  383400703121014: 104.41134476915383,
  353605610245031: 104.14352844473096,
  383119680134138: 104.10249333779973,
  393316781121306: 104.0644703778967,
  343213602341331: 103.90648450633816,
  323604761061115: 103.97627821766963,
  333511710132307: 103.82799486134421,
  303216794020022: 103.794898223825,
  333517640063008: 103.82222922167848,
  333618731175130: 103.82497777952653,
  373707752242035: 103.85140008842366,
  373508630012007: 103.7225606637821,
  383219610133007: 103.61581715977782,
  303212703111308: 103.66904946687413,
  363304762274125: 103.69464430626704,
  343719600154103: 103.60260782543054,
  363201631142028: 103.49351430834952,
  363104694324024: 103.4867217017183,
  343111662242003: 103.42348081157232,
  383206671165036: 103.49032214426688,
  373217750115037: 103.49488076340559,
  303709723065127: 103.53862189255852,
  373412724123317: 103.5078877114709,
  303612764115027: 103.43862655890563,
  343212602343012: 103.3739262994475,
  313511731122038: 103.33003493734674,
  363213773005010: 103.38586997895217,
  343710691251014: 103.10730108516748,
  333315621352106: 103.13908029753819,
  383319681065013: 103.09407626237382,
  333217772032325: 103.10188746935515,
  333719661234005: 102.9957768138516,
  343512602011037: 102.8997904430239,
  313116741162306: 102.90973171201216,
  373317762173138: 102.90282438156407,
  363319791254123: 102.79888396467669,
  353505752112303: 102.84073563946309,
  373515633374200: 102.6430360936599,
  393207712372032: 102.58219367654932,
  343517771334104: 102.49503191355078,
  313511661225007: 102.3332972459293,
  393119643042028: 102.22317303034836,
  353216652160010: 102.1785457012694,
  303610764275202: 102.22839920387179,
  363312793071210: 102.16257396073689,
  363110624211027: 102.16385506353498,
  303214742302103: 102.1617045139873,
  373212644165135: 102.11033863083748,
  333508610071138: 102.05652263909738,
  313415671164001: 101.93180383219548,
  393603671332105: 101.91759880102221,
  353112683320327: 101.88447785962845,
  383503644371013: 101.85715293796869,
  "3531176a2201008": 101.84193851398155,
  333609764145108: 101.85122351379282,
  383515751174232: 101.79530663007486,
  303115654004030: 101.68012470748013,
  "3233177a1363008": 101.74180745824954,
  373307690025135: 101.61386642524835,
  333303610070103: 101.63200248186715,
  343610780121106: 101.66665321462997,
  "3533006a1120121": 101.5870771958255,
  373202604261104: 101.55407021583483,
  343213631054336: 101.52167188928001,
  393316743232135: 101.61174555732062,
  363519731002027: 101.57950249337475,
  393100742165006: 101.40627142116591,
  323703743034001: 101.42942553703425,
  393112631220310: 101.2535223879961,
  303419660225023: 101.23427424037867,
  303112692172125: 101.15832172449446,
  "3131166a1101000": 101.14443617030885,
  343218733112003: 101.04877277806861,
  313313631263217: 101.03730200773317,
  373607771174100: 101.05567421494173,
  353211661062028: 100.87646546099543,
  333300621012030: 100.95372666577434,
  393715732232315: 101.03490966314477,
  "3431157a3360133": 100.82100777733429,
  393119692342137: 100.69705427494856,
  373610680101033: 100.65917592203625,
  343508780242012: 100.68454210295675,
  383309681244003: 100.58723268201558,
  353609681061007: 100.56753464345017,
  "3832006a2072011": 100.55156658936177,
  343712790221220: 100.52847238192874,
  353613733160318: 100.54734192005868,
  333410751125334: 100.43012675939892,
  383503643131004: 100.34423031331718,
  383203750021306: 100.33356433719351,
  303400634055207: 100.25923783702089,
  363205702332311: 100.18778549572647,
  303616703032135: 100.19908876626432,
  333318661132206: 100.08331528781966,
  "3837136a4122026": 100.07317644938644,
  343516792004137: 100.06685143273081,
  393117663001317: 99.98159352605911,
  363512762135231: 100.0117601003584,
  303213781271312: 99.97311692935615,
  "3033107a1223004": 99.98662100128186,
  333519622152017: 99.91329719228493,
  353311721034037: 99.84900515151907,
  363619640135003: 99.6625127002035,
  "3031146a2231014": 99.67075470060536,
  373216664175228: 99.65091983794298,
  363207741051102: 99.66341668374193,
  303707754130008: 99.64662247860358,
  383409703122037: 99.62353160614802,
  343210651115102: 99.48159701755459,
  313513741102000: 99.52572355495663,
  323219631145100: 99.42029103383098,
  393203650005023: 99.39442175419727,
  323418691244008: 99.28254072422148,
  313410604233037: 99.35276870985548,
  393117732223137: 99.31638254015526,
  303617631134311: 99.21789649206539,
  383504741155032: 99.22302081349284,
  363300782062125: 99.24224203096709,
  383414651002003: 99.12140035806229,
  363400682334102: 99.009552184459,
  353712751153123: 99.07840191193733,
  373600654072213: 98.95010325810266,
  303710752272011: 99.00593370586488,
  303217714132134: 98.95119979972694,
  383317674033017: 98.88743208570045,
  "3737036a1172200": 98.85201146945873,
  353206651012137: 98.76621219538384,
  333314693174021: 98.63568592250954,
  323313631154135: 98.7114011834003,
  313505673325217: 98.67836205290195,
  333303661124226: 98.67080129866852,
  313204761114117: 98.66422857864282,
  333600623060137: 98.60590934777301,
  363416743022112: 98.64509156365304,
  303619760135338: 98.5375079284755,
  353315664122224: 98.4320575857868,
  343604750133002: 98.46139343978871,
  393700600072001: 98.43146310552936,
  373319690265303: 98.335460179993,
  383106653344217: 98.36680725355501,
  363206764323113: 98.4153579161774,
  333115630252021: 98.34671092706799,
  383212762111107: 98.2976030965512,
  393600653035015: 98.17676488985684,
  343410632220117: 98.12739247220446,
  "3133007a1011027": 98.15685510239041,
  383213700322135: 98.18317553237641,
  393615703030037: 98.00951034692847,
  313603722172000: 97.95559719940239,
  "3233166a3032024": 97.8708180076737,
  343505791362036: 97.80682681022171,
  "3436146a1121135": 97.84116850910368,
  393514791232325: 97.81365196702538,
  363417694024032: 97.71551138266567,
  393510693072021: 97.71131133578743,
  "3935037a3335233": 97.80342144319616,
  393216791144036: 97.67349881685195,
  333712662322325: 97.71271596092481,
  343215620124336: 97.61631059408018,
  "3834106a3052022": 97.59631272709872,
  363105660151021: 97.53754115423915,
  393208640141028: 97.47074240410203,
  323206763030113: 97.53570465075197,
  333610761112118: 97.49822900130759,
  353503722131014: 97.49652750379624,
  393404770145002: 97.47456489357795,
  323210624020327: 97.37401521523087,
  353513792234115: 97.39319404513047,
  373612693220008: 97.26995084840533,
  "3434127a2352117": 97.36797844425085,
  343113722064006: 97.3316108761561,
  353613634025006: 97.26197905468015,
  323204631025115: 97.2563049956353,
  313205671023002: 97.20884051462232,
  "3332197a3121030": 97.2718211157592,
  323300610145016: 97.21153020979236,
  343310791014113: 97.11138069695336,
  333500651305308: 97.15127309704039,
  353716663202313: 97.08083889692875,
  353214610333000: 96.93454932472491,
  393310791142204: 96.9187949606609,
  393210792302303: 96.91098482780741,
  333305761252125: 96.99133915335025,
  393203704140027: 96.91864179870194,
  363516753332104: 96.89592734072065,
  353312750043122: 96.88732697063439,
  303303791365004: 96.75359788377588,
  333212672150007: 96.73416724820586,
  313316710140307: 96.78210258111893,
  373112700374203: 96.7745267852607,
  383613731134125: 96.79088552638737,
  373500603105032: 96.6921832132771,
  "3631107a1034204": 96.68129733468865,
  343203614162210: 96.60295838418708,
  363213794112037: 96.57805693959727,
  353303614322014: 96.59754357949146,
  363610711112008: 96.60431884072338,
  393517714362012: 96.63669499494279,
  313203763131326: 96.59739860496668,
  343317721161025: 96.55524236296743,
  323617700221002: 96.51404507243683,
  303710784331203: 96.48245263588399,
  353303763112323: 96.46375493500535,
  "3234046a2065017": 96.41263039736171,
  343617723224207: 96.45195112122735,
  "3536007a2142106": 96.42485239925162,
  313219771134032: 96.41041932581972,
  303519611245103: 96.36917435179043,
  393215760321034: 96.34854119964325,
  363216773045001: 96.36206486756734,
  "3332157a2134304": 96.33110578591695,
  373316662333333: 96.20217272937501,
  "3536107a2024305": 96.2506984258761,
  303302671273017: 96.14943727812593,
  353610760054035: 96.1286186203512,
  "3033176a2152332": 95.99704955813343,
  363417751171108: 96.03336113862375,
  363107634335312: 95.99633172338865,
  313116673132131: 95.97380033860199,
  303617760154100: 95.92678722693405,
  373212661233005: 95.77249783999115,
  373706721142035: 95.812582908382,
  303715793124135: 95.69966432700484,
  393306760054110: 95.74128406251407,
  343719602225007: 95.66267833931194,
  393314764221036: 95.70427728116042,
  303510691363032: 95.56595928687035,
  343116773334001: 95.63804781431149,
  333718701274033: 95.62475852043994,
  "3031127a2244031": 95.61079939917953,
  343615713131218: 95.4718046302958,
  343219752062113: 95.46818051209276,
  363316650360107: 95.38248830981179,
  313202780044300: 95.29013687412257,
  373306682342032: 95.24980256914021,
  353210701272315: 95.30352230792349,
  363317654174112: 95.23159584012696,
  353300734112000: 95.25176294537654,
  343507730030033: 95.276853654218,
  383312633052031: 95.11635472388488,
  313309601154007: 95.0921833846254,
  343514651161335: 95.10322430558084,
  353113780002008: 95.11560629381614,
  363112692222024: 94.85832668097015,
  303112652145301: 94.92241833407762,
  373519750332002: 94.9176070454454,
  333604701174030: 94.91823701022774,
  353504774223003: 94.91440599473204,
  373117634121006: 94.84681683919695,
  393710784174018: 94.75651772170971,
  303102682022001: 94.68899086143435,
  303208752174337: 94.71895960258755,
  373518641322030: 94.65089882198247,
  303514601174001: 94.58058621298352,
  313106723325032: 94.60385174829645,
  373202720023002: 94.55391297448237,
  343212684041122: 94.40128938563409,
  323117680244137: 94.39042212084692,
  383110641032136: 94.3555294947054,
  333608654135337: 94.3676730498772,
  333115663352001: 94.36081059697679,
  353116710171130: 94.39188379925962,
  393614664324300: 94.31160572039032,
  333203714034101: 94.33127776786853,
  353216680232001: 94.25801441685752,
  303515651252001: 94.25232814989829,
  393210734340033: 94.275599426933,
  313616773052027: 94.16490593004045,
  333518614162207: 94.05482054009289,
  343310693322326: 93.96629899699869,
  353219720144133: 94.07673219142333,
  353209793261017: 93.98307170234831,
  "3531096a1152307": 93.99755627218111,
  363112780342330: 94.04087992269032,
  383407650031033: 93.9523845055953,
  303709793142033: 93.92188595916915,
  343606754365008: 93.980847774135,
  363212644072207: 93.85700580272797,
  393116791132024: 93.8650350146005,
  373119651032130: 93.87397185477191,
  333504614033017: 93.8818136971802,
  333218622245013: 93.73290116904114,
  303410660231021: 93.75122979395735,
  303215642155018: 93.65346293162747,
  323304742174033: 93.67564580166183,
  373516751133128: 93.66134287057709,
  373106641152318: 93.52823032913227,
  "3333096a0225017": 93.54825232149351,
  353719712142008: 93.56173814512309,
  393214660120032: 93.43828669331087,
  323309771142117: 93.50038912466302,
  373610791160303: 93.34481875339682,
  353513652134335: 93.38306820889433,
  383714631054100: 93.34873121921831,
  313212770120013: 93.32690460174722,
  313404630025000: 93.26952547146973,
  333412674141000: 93.22747167826516,
  313213673262213: 93.20708548516508,
  393104793032101: 93.1936916798575,
  383716642334027: 93.10741050770442,
  313204781072002: 93.0910939754671,
  333316721131135: 93.03460207100154,
  323313732132321: 92.98626300145948,
  383314603224005: 92.93495942164175,
  333118663132034: 92.89095426346438,
  "3336177a1262010": 92.91089886758422,
  313115781331022: 92.90406695463096,
  383116663075102: 92.83476666999329,
  333107650352337: 92.76928213609456,
  "3833007a1231016": 92.79436760569777,
  333718691161128: 92.64095961830603,
  323700631224025: 92.63567905719327,
  393116793151327: 92.522891403028,
  393514653002027: 92.49673466482548,
  333512602143307: 92.42879610764327,
  313114794234203: 92.38629232404607,
  373413771261000: 92.4320866898009,
  333315641062101: 92.3439991257553,
  373714721101037: 92.40362700593099,
  343219713062127: 92.32348306531128,
  353100713270007: 92.31475338405016,
  373110764252011: 92.33547043356354,
  383313744125300: 92.29898088807262,
  333510603035016: 92.21756903019016,
  353718611021010: 92.18339685171455,
  333116760321238: 92.22806227355393,
  373314621131306: 92.11615761499664,
  363710631112038: 92.05944629634016,
  383617681232103: 92.03523433239852,
  393610601230003: 92.04789495435526,
  313312741023008: 92.09413922198162,
  313110724162015: 92.12214796543428,
  363613793324132: 91.99403022974082,
  343614691043007: 91.86958715379491,
  303400710262300: 91.92390808620289,
  303700621102000: 91.87966829708829,
  393610604155103: 91.81162160678282,
  393714663043003: 91.76460570073495,
  333310693112137: 91.62657041814862,
  "3833037a0032006": 91.75169069640631,
  353213603102118: 91.67837834735049,
  373516713055127: 91.68009136667627,
  303516761044232: 91.63180038673059,
  303207693133017: 91.49388138401467,
  343108661134202: 91.37024189226304,
  333318634152000: 91.36968057405552,
  373515754061217: 91.4184307670945,
  383700764121004: 91.41744517455498,
  353414630074038: 91.34921684219651,
  333212751355022: 91.35494925442939,
  "3132137a2102003": 91.335662985012,
  393503600041020: 91.24017459153485,
  343118683142113: 91.20304122579294,
  363108641164002: 91.19607397492095,
  333717741052027: 91.22492790257468,
  383302762334005: 91.20245265886905,
  343510704135232: 91.17534430108454,
  383216663131200: 91.0940716224115,
  353314744162001: 91.15849368184247,
  313110603102123: 91.10505736491135,
  373217732124238: 91.10604988786454,
  393209733122113: 91.09232227353614,
  "3934106a4124328": 91.03381122238571,
  "3632147a4132034": 91.0579082935632,
  323115732021227: 91.00959810335424,
  "3234157a4242017": 90.98519476492193,
  303504671104003: 90.9497358873384,
  373210683002008: 90.90257820254755,
  383705724165127: 90.95871804045312,
  363610631144026: 90.8818094759838,
  343314710152227: 90.9300930046526,
  383600773272013: 90.91131625425332,
  343717741235327: 90.87310596709563,
  303703791133023: 90.80930600198333,
  353418741232017: 90.82399978117125,
  323310664172035: 90.80817408264808,
  "3233046a1342237": 90.70614817464224,
  343710662133010: 90.6750452195298,
  373213764143308: 90.6809517888363,
  353417611024138: 90.5857008825911,
  353718620121000: 90.5900538267263,
  363210752321031: 90.59783509474148,
  303518693025123: 90.43986277824818,
  "3734176a1152017": 90.48661668742896,
  343508762321133: 90.55572321929147,
  353218602124020: 90.47962945157775,
  343114733151332: 90.51343039513569,
  363718601164118: 90.41163640264656,
  313604654224038: 90.34406015733721,
  393516622135323: 90.34406562282011,
  363700764060008: 90.37515359136177,
  373719703162107: 90.3603988264428,
  343503743055107: 90.29360175069681,
  333102751161021: 90.2716106562342,
  383114734045207: 90.24437204149021,
  303613660140107: 90.18806859065373,
  393515723045102: 90.2596270618178,
  363414692154007: 90.09572663712164,
  "3737056a4171033": 90.13564047529621,
  363610761152004: 90.1414765132154,
  363414641134032: 90.08473666337385,
  373100653122324: 90.09460874642775,
  343314631130207: 90.08182666517479,
  363204661344031: 90.07493090626605,
  303118633122005: 90.05941246141975,
  353113631245021: 90.03915948841372,
  333314652232006: 90.00666012029816,
  303200621241228: 89.9868044846421,
  363412670161308: 89.99003586562091,
  383217661124011: 89.97931478013068,
  383105692262102: 89.86164599106566,
  313302691121006: 89.83899916297364,
  393212703134011: 89.99017733739439,
  343308721162130: 89.90532005317694,
  323200650034035: 89.83052435069446,
  323116750054307: 89.87006274519835,
  353112660304117: 89.73403379226622,
  323718731032100: 89.73222117534347,
  333508771042000: 89.7089432380899,
  353413763122004: 89.66713368804179,
  383102670135110: 89.58162558675254,
  313110651262034: 89.55022671053388,
  363314701154312: 89.62042488529279,
  353508630321002: 89.5158211969,
  303315784145313: 89.48857080613426,
  363100781022105: 89.37643442479401,
  393715773045317: 89.36376747020833,
  313413722362007: 89.31288400265731,
  343110700124304: 89.30907408429107,
  363317663302037: 89.24359719746923,
  343519690232027: 89.13651014306592,
  "3133106a1053007": 89.1946426318782,
  373104620354017: 89.1846988747529,
  353105762372100: 89.18546629158662,
  353315680331022: 89.13046564482951,
  363519751131018: 89.1899220011598,
  323506690125000: 89.03981928966382,
  343518753162020: 89.14535806411267,
  333303664232135: 89.07489542110756,
  323203660223107: 89.04170938570884,
  313508794034107: 88.99037632409475,
  343705790054307: 88.94560038704941,
  373213670125001: 88.95680136506873,
  333310722031035: 89.01970100997964,
  313410681144038: 88.85289411233335,
  404213631142126: 86.7209197828731,
  414215701025227: 86.72270615616884,
  494717633165113: 86.65217621592323,
  454714711032006: 86.66366616028695,
  424217763031237: 86.63710100010074,
  424210621104008: 86.51328851150016,
  "4643156a1021011": 86.48564008759517,
  464218681331107: 86.42233084986798,
  414113672164030: 86.44312288326253,
  414104714164313: 86.50296798066239,
  424104760321207: 86.49787399807224,
  484604693122012: 86.35283307156189,
  494509721122037: 86.4497501776521,
  454210711143030: 86.36586113173689,
  494416634021003: 86.30371562324916,
  424305780261107: 86.29636178608057,
  474212731371020: 86.26824617117917,
  464110663221136: 86.22698435870741,
  464518660254107: 86.1130801861497,
  424312664234005: 86.1375457325261,
  424705672031033: 86.10826223888866,
  404210620324223: 86.08399807368572,
  454102701144006: 86.14014555036583,
  404216692162203: 85.97219628101459,
  484702791324118: 86.03935771456594,
  444112643252303: 85.99158008722254,
  414305750132202: 86.01391299269697,
  414616791165013: 85.9519537893471,
  454215651142335: 85.95948145107947,
  464315610154030: 85.9510448213574,
  "4542056a4322332": 85.93818240394116,
  404215630074020: 85.93223449241795,
  494610631140037: 85.84617141862886,
  434112751101103: 85.87406159299721,
  474416663145113: 85.80343412286835,
  414505671224318: 85.78424632577776,
  444513661072223: 85.76457976826225,
  404716710022112: 85.81785406433853,
  464304711204007: 85.82073034707521,
  434210630040108: 85.7106085035025,
  474309733145003: 85.77859372438895,
  424710602152133: 85.70526570627328,
  454300643204007: 85.6714532403849,
  434110633045004: 85.63763779957505,
  434506640144108: 85.63567643675796,
  454704654122208: 85.58835952400209,
  484516732152023: 85.64850982455044,
  484600671155107: 85.51928104012752,
  474108610221307: 85.52980232898615,
  414400692132323: 85.39982904013276,
  424518671321007: 85.453268507888,
  444310641232036: 85.41217642789198,
  414110763172218: 85.4732122192515,
  464713683022030: 85.34212161968578,
  454516661131106: 85.32868437610459,
  434313791002013: 85.31997737441098,
  454304741155038: 85.3478793246892,
  454308624042337: 85.27970005304827,
  494312751261227: 85.31613453693123,
  424504731235132: 85.29171849447161,
  484308724141007: 85.26803045333395,
  434200750133333: 85.25446474026657,
  444717690241008: 85.10084132151947,
  494600723171003: 85.20575438909736,
  454600681325337: 85.12947697631742,
  404306601140007: 85.1025543322321,
  444516714132330: 85.14430601317231,
  404210691162325: 85.02188142102986,
  454500734132325: 85.1522126439063,
  454514661375323: 85.05160401696021,
  494312693202007: 84.97973020002428,
  454205761061004: 85.03133790128228,
  464308660131110: 84.99622053031426,
  414705760142322: 85.02597662591381,
  434303763361217: 84.99945779854153,
  424410721322002: 84.96076743643881,
  404300671233108: 84.90659289653603,
  474215674325002: 84.8021401456622,
  474700613120037: 84.80841028570714,
  484319751142008: 84.81909246770022,
  444102722051017: 84.79053814841468,
  414215682334003: 84.64827446905895,
  484304773362002: 84.69717364124058,
  424219661045007: 84.62613499199614,
  454207674244137: 84.60681179894199,
  444414613131028: 84.59699691873371,
  454707700125002: 84.65418038134621,
  "4643007a1231025": 84.67273245707673,
  444112633235005: 84.51821461943815,
  "4542007a3055323": 84.53262347445487,
  434113731360007: 84.54290505068928,
  484302751143107: 84.53694121112684,
  454316762342227: 84.49681509233422,
  414716783122117: 84.49686283968565,
  404604664021032: 84.4356048407952,
  494208731022022: 84.4364045071673,
  474200733025021: 84.45983454496923,
  474412741132012: 84.42197129667727,
  474718611131028: 84.36737303874415,
  414314714134130: 84.403157561736,
  404214724371007: 84.39511487432503,
  404212693042005: 84.25220445777487,
  494700661105003: 84.31668634441964,
  464518750164018: 84.35132547134683,
  464616724132000: 84.3370202903627,
  "4843146a1324207": 84.26014774449676,
  414518693121307: 84.18979024003995,
  444307691032208: 84.1605060134597,
  474110720132026: 84.28757622634488,
  484113631222218: 84.22008133163934,
  464417733041037: 84.27796993693063,
  404408661135307: 84.20832698237452,
  434516634221102: 84.21993167636309,
  "4542057a2130007": 84.21059187861769,
  464212650122104: 84.1601395614793,
  404315781335000: 84.19189333771556,
  494715622025307: 84.16209273892963,
  414113772164138: 84.20457662194325,
  464310750302033: 84.19084612023451,
  484712633174018: 84.06626199155853,
  424105781135303: 84.09715036281511,
  434315663242006: 83.99764196313147,
  474414653224023: 83.98970319333519,
  434413723234137: 84.05383355481992,
  454319703142137: 84.01425668757233,
  484114680162133: 83.90662175716253,
  454604771325137: 83.96525842833354,
  434114741344317: 83.91174499307412,
  494700710172002: 83.90399597719694,
  "4043006a2151313": 83.85298397264498,
  464512620025300: 83.8396772481063,
  484212720141318: 83.88147997580914,
  494102622334038: 83.83721402354819,
  404514640324337: 83.82317342737095,
  434610703125122: 83.88014371680792,
  424617734161007: 83.87004155072253,
  424400673022038: 83.80640620443674,
  464703610364013: 83.82408856524987,
  444714741322000: 83.79366149812073,
  494310720231227: 83.8119737678997,
  484412660134030: 83.67231138105025,
  "4341107a3120117": 83.69458824669007,
  424700604262028: 83.64783620642515,
  "4943176a3141002": 83.63671934240698,
  414110661002000: 83.60729997771524,
  424718611121037: 83.59780325229558,
  454310723120028: 83.65486246459939,
  454303723372108: 83.59684987143746,
  444318722122000: 83.52540732070004,
  424213751132035: 83.52956268837471,
  484112731374303: 83.47384203076555,
  444112610065010: 83.40131936264353,
  474604730034012: 83.41851100755254,
  424605620135107: 83.35843052704082,
  464102631022212: 83.30871748139681,
  454415631172002: 83.27385012208417,
  "4947186a1162307": 83.2635032538172,
  484518773124107: 83.28981536044425,
  404319662122327: 83.23211449712265,
  454615741335027: 83.18910439495316,
  454719791132307: 83.11216863615014,
  444314621231322: 83.14143751349516,
  454302631205037: 83.13618445700273,
  474203613244012: 83.07833683080612,
  484213624235038: 83.06406865709157,
  454306632364123: 83.05208360821453,
  444500783032110: 83.06811498751979,
  414117610041027: 83.01833195573792,
  444106754264023: 83.01124749152562,
  444215782172003: 82.91988042341475,
  434213662233003: 82.84456755411676,
  484206652145307: 82.82724946727075,
  474110660062024: 82.80438545435527,
  424702663022203: 82.79093381118132,
  434117622141137: 82.74212457775997,
  "4247127a2162113": 82.78322698624986,
  454703701365337: 82.78762880245456,
  444110742054027: 82.7236465022116,
  444712602061018: 82.68275294769545,
  464313721265020: 82.7351560840789,
  414315681024138: 82.63473233904492,
  464600734222122: 82.69804700521718,
  464415672222003: 82.61534456120614,
  434112771032218: 82.64527467015428,
  414512684162013: 82.58912082463654,
  494710621342030: 82.59688743587596,
  484317601135102: 82.58640971189833,
  414710743145337: 82.63169839911397,
  414718761165008: 82.5613926626325,
  444115631130127: 82.48448698180765,
  474417671124017: 82.4400221193861,
  414310701224005: 82.49818048718191,
  "4047107a1132036": 82.46263623298944,
  424210601122025: 82.42464647192257,
  434100781345127: 82.44251708743502,
  404505651030007: 82.28925466818464,
  454610750171028: 82.31295371518759,
  494402662322027: 82.23177393647205,
  454613641124313: 82.19311836030093,
  "4641137a1252000": 82.23813958739642,
  "4142037a0022132": 82.20851153977989,
  474309690142007: 82.05971101153042,
  424119761035007: 82.17640894775403,
  404115603171038: 82.11802755859833,
  474115734272133: 82.16389265850896,
  474305704235000: 82.16047155400567,
  464410740032002: 82.09413319065162,
  454316742025013: 82.08939211724115,
  454107671342108: 81.91926096435333,
  404312624224302: 81.93190006978955,
  454307611141030: 81.8936576404449,
  474700650075033: 81.90242621401593,
  "4347167a4142317": 81.94324286752087,
  414413733124108: 81.92458169133687,
  474304661151207: 81.86009026730764,
  484200783322133: 81.89619084146355,
  404417761334008: 81.87573819710263,
  464215662043007: 81.80659155056232,
  484518751022303: 81.85177495372186,
  "4243027a1142217": 81.85185795449978,
  424217640162107: 81.76794585285697,
  434215640341107: 81.70455564954113,
  474307710172008: 81.76612934923259,
  474115693352027: 81.61850550831736,
  434410710232313: 81.7152445488436,
  444517630172303: 81.64630315244541,
  444610671165012: 81.62159706653587,
  464114771325138: 81.6943207165061,
  464115640125000: 81.60978549426984,
  "4546126a3244108": 81.58894610721158,
  464214691132006: 81.46820477996323,
  494117670121103: 81.56606335692517,
  414308721144107: 81.58154791226433,
  434410634134100: 81.50465295749835,
  444315713342100: 81.52324852399842,
  434705733222132: 81.53168518008117,
  484716611244003: 81.44111864375294,
  "4141176a0222038": 81.43137583941848,
  484518663122313: 81.37431056700078,
  434213771252108: 81.37463734190675,
  494513652122300: 81.33614663627583,
  434403770134027: 81.38849874460793,
  454300670102008: 81.31956619423116,
  454513723272023: 81.37727113881519,
  404514613332100: 81.30018586919384,
  494105682332007: 81.24996862254082,
  444512782135127: 81.30401304950978,
  494514754234317: 81.3070783513129,
  414317730341027: 81.30088462133284,
  "4541057a3221338": 81.27870926246699,
  414116774331007: 81.1977381591593,
  424316790135002: 81.12380329773924,
  494112781025023: 81.165034708205,
  424312714234308: 81.15850658555851,
  474602602125033: 81.102686994815,
  494112720365107: 81.15446500578456,
  464115641171027: 81.06401220946374,
  414216703152017: 81.12163228594478,
  414710652061317: 81.0286816399413,
  474403754135037: 81.07266059422186,
  474702743142327: 81.01994381314123,
  464715710231327: 81.00181044717083,
  494317622324007: 80.93880843898164,
  454308724044007: 80.94229362790227,
  404510750125001: 80.94689544193085,
  474713730072018: 80.93625752337799,
  404307620164038: 80.84446580061267,
  "4642127a1133137": 80.86096740040794,
  464506774125008: 80.86936666048578,
  404400743232307: 80.83453779940028,
  424202661172302: 80.77167884638523,
  414614652125037: 80.76141820935477,
  404200630202017: 80.74918044157407,
  "4946176a1122117": 80.74347723989776,
  484310791120027: 80.7043878038644,
  464216684162008: 80.70594196080361,
  444708663165037: 80.7111105987396,
  414107641131017: 80.69837427241349,
  454310653021011: 80.71369912570088,
  484505751225317: 80.74721212045719,
  "4342087a4132103": 80.71703345871106,
  424218601024003: 80.55792217602523,
  424316603035127: 80.58097506723057,
  414312701144332: 80.62075302111651,
  424216711134102: 80.55734391689676,
  454110621271138: 80.514974134927,
  444710612164012: 80.43104446764735,
  414218691121008: 80.30882020845823,
  404210721162006: 80.40185011611247,
  464106723132020: 80.42465363256474,
  444610691241000: 80.22868872329637,
  414614603144003: 80.23309597877935,
  494204611265008: 80.20967222956341,
  454512613072002: 80.17683769544145,
  454613734134022: 80.24000591712115,
  404708761121018: 80.17878645366177,
  444515770052017: 80.1555613688415,
  474210672235030: 80.05478482751955,
  464710750175337: 80.0837762089534,
  454310612043007: 80.01536751198444,
  454210721131015: 80.08274671235473,
  414112741022012: 80.05013540298299,
  414502711142302: 80.04070305532554,
  474716663122322: 79.9924170557287,
  "4943147a3365003": 80.05038535462785,
  404514680162038: 79.94501425578642,
  494706703134307: 80.01988062340332,
  404315742172003: 79.96994577110092,
  444610714224108: 79.96052749087093,
  454404770124037: 79.95790050706849,
  444714771131012: 79.95135529602268,
  494306671141023: 79.87649532701464,
  444513731054002: 79.8804619675985,
  444303681335008: 79.82042302738046,
  454210661230000: 79.75725956567673,
  "4347186a3042007": 79.77223791317033,
  434510604075107: 79.74841047844272,
  474118653165037: 79.73517995511304,
  "4145056a2021017": 79.72154141922643,
  454516651162203: 79.58443989391482,
  484713734172037: 79.6443546337365,
  444202701272018: 79.63242976171175,
  454217711041327: 79.61358237042599,
  464310640155008: 79.52655787173394,
  414110601225207: 79.52905823456172,
  "4443056a2364027": 79.51304675596097,
  414610671134338: 79.49553829718717,
  414514600161013: 79.51579460566987,
  464416634142003: 79.47399886436162,
  444103694034000: 79.39716093204464,
  474203710065337: 79.51216753468331,
  454115741334002: 79.48867847704696,
  444514610235018: 79.44371440354561,
  414404731144037: 79.48720202773048,
  474503742342007: 79.41622218584848,
  464110750075018: 79.40591292518211,
  404703710142138: 79.38634679990511,
  474216613142030: 79.27421229840573,
  424517603122127: 79.29597483175715,
  "4343056a0074037": 79.27395585444332,
  434316603131318: 79.2821811246788,
  464107691344037: 79.18858258601796,
  444103684142008: 79.2095260937877,
  494616752122017: 79.26397998724097,
  404316601242318: 79.18173640070113,
  "4745176a3061003": 79.17536506980564,
  474317612134002: 79.16395423502368,
  494517773132337: 79.21914523345085,
  "4545167a1364013": 79.17846695621773,
  454402731164002: 79.12136573088506,
  454212661024025: 79.08677097984042,
  474605762135108: 79.10314404889071,
  454212701222001: 79.10397128411532,
  454319761032308: 79.07956733164919,
  454718753135107: 79.0391505457374,
  424205711025023: 79.0091197144776,
  484704653161003: 78.95746188569305,
  474300752121227: 79.00358600512722,
  454707700032023: 79.0116045515201,
  484315642145007: 78.90410776553041,
  454704734235117: 78.97141260652107,
  434507611221027: 78.87328158608281,
  474100720131237: 78.91726457333502,
  424517631132100: 78.83514362219273,
  434415651261007: 78.8096710976358,
  424310644332127: 78.78361320154045,
  454715670325037: 78.77911917299242,
  444206791064317: 78.73505508185252,
  454112731035001: 78.81119091537349,
  474606660222303: 78.73706458612625,
  "4943106a2232112": 78.73976031373198,
  444605622122307: 78.7131346110332,
  494210602175037: 78.6819716505683,
  494306764062002: 78.69545765612408,
  434312762223007: 78.69888460671318,
  464614633025127: 78.6296349941181,
  434114674231033: 78.62199150692835,
  454415643232007: 78.56982932779252,
  424713670132133: 78.60615601958655,
  464404663145003: 78.58646405776643,
  464100701122004: 78.633816787124,
  464302601172102: 78.58023002492797,
  474212600354007: 78.56618924963261,
  424213772242017: 78.61376442244857,
  474310680134300: 78.53852521770801,
  414203661154018: 78.52517094219083,
  424314721064038: 78.59759179309646,
  "4141107a0161102": 78.57385556148112,
  "4346077a1142003": 78.557943997948,
  454312741121130: 78.51727386990902,
  464300794242302: 78.40620989399054,
  464505663132005: 78.41853577402387,
  464115714031028: 78.40845575878889,
  454114723241127: 78.40267305762376,
  434115622264017: 78.33418072105185,
  "4141107a3064118": 78.31219525973857,
  474604631322018: 78.24446765692977,
  434503734054007: 78.30261891281084,
  494208663124137: 78.18758595250323,
  404110732124001: 78.23860477685449,
  494305693272007: 78.08542482002949,
  444510750030007: 78.17909271562965,
  484715601362003: 78.10346436331292,
  464106643142008: 78.07845643173721,
  494612732124013: 78.13684609235763,
  414213750232030: 78.08703006792317,
  424318651122303: 77.99772183497133,
  444612610142133: 77.94762105450361,
  434403732332007: 77.97841777372628,
  454713663175013: 77.9367870898029,
  404514783041007: 77.96585566309028,
  474604750122028: 77.95761743219732,
  494215793135113: 77.86048407017616,
  484110720152103: 77.91386198015437,
  484313754144133: 77.91319283362833,
  414315780044007: 77.87673057472871,
  444314731321207: 77.86399232444458,
  404716640022037: 77.76383885533431,
  434110754264313: 77.82231616071532,
  484107731142002: 77.79979299132,
  434715692121137: 77.6807962621669,
  494312662031207: 77.74721736013858,
  464713764172023: 77.6874287065593,
  484300752344033: 77.67496697677338,
  494316734132022: 77.66057531113664,
  454102701241138: 77.66105464069493,
  424210680122000: 77.56408278303607,
  424312781132118: 77.64138442111201,
  494712631135030: 77.58185508814417,
  424510634142020: 77.53928666624539,
  454315663132232: 77.50914320818997,
  424703710222037: 77.56098246007525,
  434306654161008: 77.4990984935324,
  484110781035127: 77.51956572439383,
  464513634254007: 77.472126948195,
  484104662145137: 77.4660392892798,
  494610663321337: 77.46420834918013,
  434306671172027: 77.42039867201476,
  414214661155033: 77.39595823749787,
  434314713262333: 77.44995968447124,
  474110761262101: 77.44454394628102,
  444312790122320: 77.35148435142266,
  454410654145017: 77.31935689173503,
  "4342106a3044020": 77.31079419102677,
  464615631361003: 77.31539912283219,
  454600712022113: 77.3621659377799,
  464112692174037: 77.21669900549753,
  404310701371013: 77.35258862581806,
  444110664122015: 77.29732610474723,
  444516704332027: 77.33208842177805,
  454510673061323: 77.2244437872383,
  484515691232000: 77.11442279977345,
  414700654232133: 77.1985122755207,
  434610773052007: 77.23205150642491,
  474317731132208: 77.20159759373098,
  484314761245333: 77.19068293816116,
  444206751322123: 77.13685887768204,
  424507610132003: 77.02568399458073,
  444318793222027: 76.98698882864613,
  414513691325017: 76.94027880262942,
  444312661005007: 77.00194153551021,
  474500782262037: 77.03194521022527,
  "4647156a2035003": 76.9651804125542,
  474119760122003: 77.00932272725859,
  494100763155013: 77.00231001966846,
  454215644124127: 76.9070059369689,
  454310780161138: 76.9548885178826,
  474110632032001: 76.87863657559032,
  404700713155007: 76.91691099885313,
  464115700125000: 76.91531114687594,
  444510640065008: 76.79343002273013,
  464500763172132: 76.86058159207616,
  474314662272117: 76.79270079463139,
  424400710232007: 76.82385712194784,
  404514641162028: 76.75572017397226,
  464714712342007: 76.80475950169102,
  404110710145030: 76.77709746337507,
  474410601122012: 76.6843044277328,
  454412730134028: 76.7230911179594,
  454717662221017: 76.66142911007343,
  424506663332033: 76.66337435181416,
  474213711064313: 76.69165197459515,
  464713781032023: 76.65869301869202,
  454710601252127: 76.59700565180182,
  424310681034002: 76.56537667590192,
  464300711272123: 76.60332194292904,
  "4945106a4025013": 76.50797333308367,
  484315600022000: 76.47288131490639,
  "4444007a1162303": 76.50548752345617,
  484210751022208: 76.42009372535527,
  484313771045008: 76.43113955431488,
  "4541176a3164137": 76.3174837671618,
  464514770162002: 76.37126720986882,
  434400730142317: 76.35877717435751,
  424113663262000: 76.2929108809903,
  484200751361023: 76.32149819338456,
  484512691031327: 76.1960550437586,
  424512753034317: 76.31300259977473,
  "4642057a0031317": 76.29326119766432,
  494500640125027: 76.24187792091591,
  454310651365302: 76.22839322991148,
  494503671132313: 76.23331694802576,
  474410771022133: 76.24090530131755,
  454310741074117: 76.16336519403623,
  484210671145000: 76.06646135092092,
  444310712364038: 76.1409827662426,
  414703781132003: 76.12978563599123,
  404210781264337: 76.1052343850658,
  474210692062022: 75.9516807911567,
  404313740262137: 76.07353383228933,
  474213710235337: 76.06928858972175,
  454217613225037: 75.97085940410165,
  434307781042007: 76.02139265897216,
  444308753224007: 75.9781704940968,
  404110621321032: 75.87318666326927,
  454710704064038: 75.91784316084055,
  414314661102007: 75.83661121643362,
  404704691022002: 75.70011717949077,
  444704653135103: 75.6812077499853,
  434516693172007: 75.59442389020853,
  494302792224017: 75.65888725366469,
  434403660142307: 75.63174781066355,
  424110722022327: 75.67516325727765,
  444503653222000: 75.56744672510814,
  434612700122317: 75.61066703656691,
  404515653222313: 75.53129572982748,
  464314724324003: 75.57931887566912,
  404705713042027: 75.54847067222924,
  404300734224207: 75.52002275304912,
  464210680122318: 75.44283866259764,
  404312721232122: 75.51904258439689,
  404405721124007: 75.50008020028443,
  444505603125117: 75.43235582625486,
  444310693120007: 75.3289433944735,
  444713703132128: 75.45726455626726,
  474317622224007: 75.37477930696035,
  454703790141027: 75.29237696675632,
  484303673124117: 75.29519024695982,
  "4742146a4332103": 75.27460185994416,
  404117661061317: 75.263592215736,
  414316711232018: 75.29337781646765,
  444517631262038: 75.2331731497429,
  454514790154007: 75.19743124993262,
  424500721062018: 75.22058920700486,
  414117751125027: 75.1982955397386,
  404302773322018: 75.18403877105631,
  474202702362003: 75.1590110634948,
  434616663122002: 75.0257101020106,
  464710690172137: 74.95348699201558,
  484113671224008: 74.99952130679058,
  484210690065033: 74.91098474710326,
  474102671172008: 74.96548420407026,
  "4345106a4072003": 74.95039345276501,
  444115733172038: 74.99635532370816,
  "4342057a3322103": 74.94807773144713,
  474100613231333: 74.90038170937295,
  474213730251007: 74.91590680576152,
  424712701035003: 74.93096478260351,
  464203663122208: 74.83167630337115,
  404205731152108: 74.86299256505013,
  494212691164002: 74.72425989453845,
  444707661034127: 74.79400397052555,
  444515714132123: 74.82681652908639,
  434213771224327: 74.83008055688057,
  454612631222338: 74.75120861605947,
  444702683122107: 74.7494261262892,
  "4743006a1062332": 74.73038403990869,
  404214691225337: 74.64373087247759,
  404313631244022: 74.68869563343178,
  474303624025003: 74.61171654687095,
  454100791254027: 74.56525434134977,
  454617664142003: 74.55053770366261,
  404212632154107: 74.53204557461044,
  434310751122004: 74.53301876018546,
  484212734322013: 74.51036881289494,
  404312630132001: 74.43209872207771,
  434710694161307: 74.34435166198521,
  484317661032338: 74.38787897029354,
  454303734172013: 74.44396181223978,
  444516633224013: 74.39376256352779,
  444514691241027: 74.26061468387306,
  464200620245008: 74.30610434068332,
  444105711232138: 74.31432118508778,
  "4745156a1231337": 74.26363865915327,
  454710714032032: 74.26392918642826,
  464110711034332: 74.24077659648701,
  454317671131133: 74.14944805155235,
  414210721131100: 74.15300100404595,
  464514653132328: 74.11123865243869,
  454503683032027: 74.0641107547006,
  464306681122137: 74.06089841637127,
  434102721132207: 74.06967240395795,
  464113631252137: 74.0147086591302,
  464313724162133: 74.04117249456687,
  434516730141008: 73.99589348889637,
  "4643007a3272008": 73.98252378943302,
  404502711342033: 73.96719739633916,
  444300681172027: 73.89537553267952,
  414303763121032: 73.97477087675601,
  454502644332007: 73.87764799268373,
  474510730075017: 73.88524366707534,
  424510673132118: 73.82345148200427,
  464204634162002: 73.7834734921223,
  414302641131307: 73.73038830819927,
  434613601234037: 73.73189398096461,
  424312673022337: 73.73123788570572,
  474513632141117: 73.68339822161103,
  494108763122107: 73.7238199514966,
  404612664162038: 73.64913037250722,
  414710732041003: 73.71315355634522,
  404300763022238: 73.69110204962304,
  444112644124017: 73.6212165868569,
  484513791232337: 73.61170462899423,
  454205751131100: 73.64769389660452,
  434115660171017: 73.60495261545987,
  424500713022303: 73.64467123679586,
  424305671144003: 73.55305140830005,
  454114611242002: 73.4798713828584,
  434306611142018: 73.48531379825546,
  404704631164127: 73.49381847031786,
  "4741126a3172027": 73.48220388067273,
  464115760022112: 73.52771079854942,
  454512691035333: 73.38717258760576,
  494500621132100: 73.40663547287082,
  434312730162112: 73.46249140358425,
  474613620132307: 73.38696395511255,
  454210671155008: 73.35187506668005,
  464513623032113: 73.3520127418889,
  444214600122032: 73.29447013638364,
  494210660123007: 73.27002555300255,
  454105690162018: 73.18228422376912,
  444710711221133: 73.30943016081592,
  474413662042007: 73.24145184705594,
  464610750342017: 73.24489735803928,
  414210621332000: 73.1577952393997,
  414205703022008: 73.19746986261556,
  434306601142317: 73.16750094603142,
  434116611022018: 73.1151917705514,
  434500642032307: 73.09299053153578,
  484113641124107: 73.08453201787826,
  474500794034038: 73.07210321370474,
  474710633252107: 73.08000718283367,
  444212624025003: 73.06158238149057,
  454110781032030: 73.0846469288743,
  474317721144027: 73.0990658502314,
  494610660264107: 73.02872454598813,
  404202711151007: 73.0434779336222,
  414304623141007: 73.00859358720639,
  444212760244138: 73.03929967607783,
  404614763332008: 73.0426615704724,
  484513652322007: 72.97464996897341,
  494114721232103: 72.97479552709207,
  "4941146a1134303": 72.90459044109707,
  474202651324038: 72.8639491835556,
  464317624122027: 72.82800131145576,
  424510732125023: 72.88146629192315,
  404212673172037: 72.7955204260104,
  464315723034303: 72.82911642512651,
  474305600145017: 72.7759068851167,
  474110711264308: 72.79267815238228,
  484300762145018: 72.78900448084002,
  464517641132017: 72.71458739670162,
  454310660004007: 72.70977379766929,
  414510653144133: 72.69176678852432,
  424212752122038: 72.71474343095093,
  464205711221008: 72.67985425950467,
  404316600045007: 72.65134498091749,
  494310620132302: 72.62607645356468,
  404303790161307: 72.60545487678053,
  "4541127a1164313": 72.65184508651555,
  454304761162330: 72.64360373701169,
  404217761065307: 72.58005991495234,
  474210630141302: 72.51779329906235,
  "4243137a1162018": 72.58267592273343,
  424200611322023: 72.49415563625512,
  424200672122013: 72.49598790736549,
  434115711332038: 72.5482212538273,
  444302630235018: 72.49302369826114,
  404215713235017: 72.51154454037929,
  464317671242037: 72.4522849430434,
  454502652144008: 72.41969076251814,
  454315670132030: 72.40356106423522,
  474710722032038: 72.47595858458997,
  424203651224017: 72.40623316218085,
  404110721144012: 72.4714355046537,
  424702661322008: 72.41202424874844,
  484514612132003: 72.34635592842804,
  404613631325007: 72.30704329617947,
  444200603172137: 72.27758943281123,
  484100771234103: 72.33911027556381,
  414114661031308: 72.26773469712371,
  454210641021123: 72.21198937162828,
  414213661141333: 72.18473357358863,
  454515651335307: 72.17481635946449,
  404710663132320: 72.17516413518067,
  "4541106a0074037": 72.13254138396941,
  494213611161027: 72.12005870312987,
  454104791242013: 72.09806136414808,
  444310672362307: 72.1100016361553,
  454317734141003: 72.15748152643089,
  434210772162002: 72.11141516226063,
  454600760221127: 72.11383126837227,
  444314773221003: 72.09908528809808,
  404212752032000: 72.06054508322413,
  454610751032100: 72.05612135484921,
  444310773022207: 72.03772465347426,
  434410661334307: 71.95475429278117,
  454700722131307: 72.00124641987105,
  434312651035313: 71.93420101534885,
  414312604145007: 71.93375881202557,
  404103711062023: 71.94553049049652,
  434715623125007: 71.8825935024841,
  414510793062117: 71.81309643360362,
  474705730162033: 71.8831005290362,
  424302670232037: 71.81425946142525,
  404313723332117: 71.83948293289083,
  454315710034133: 71.78606647713849,
  424110760334018: 71.7958172216109,
  474612634322007: 71.71289639937531,
  424313643124007: 71.6953606496425,
  444512633324103: 71.68869661566742,
  444703661222000: 71.67548636813997,
  494710791342037: 71.63214799177472,
  484210751035123: 71.66459714939798,
  414512752022027: 71.6751616727013,
  464312792145017: 71.58569895416565,
  454217760332127: 71.61228424331829,
  414202701132000: 71.59210791906752,
  404214670022013: 71.52775310304862,
  434503771042027: 71.51688233817859,
  454615621132117: 71.4265020689225,
  414110734165307: 71.45869024841248,
  404500631332328: 71.40008244058728,
  404212600035137: 71.35370332115153,
  454510623135000: 71.34444613113962,
  444116631334008: 71.31504359496967,
  434700773131307: 71.38097747982617,
  454702760062018: 71.3596471663636,
  474714764164107: 71.31238375385483,
  474116693222107: 71.16993063173275,
  494218791132007: 71.19946100613444,
  454707663035007: 71.22156250647097,
  484102600032027: 71.18629147278106,
  464500793262013: 71.1277935121598,
  "4042107a2244027": 71.17118643561501,
  454112622061007: 71.07115596095235,
  454316791234117: 71.04401880998265,
  "4742107a1135010": 71.06595279620464,
  414105764132303: 71.073919446856,
  454314630131338: 71.03144072578434,
  474304793242003: 70.98699714382937,
  464316732135307: 71.05276661186076,
  444310791171033: 70.90788592548891,
  444204720032008: 70.94951001361763,
  494305612132017: 70.90301410745118,
  464310680045007: 70.895353481451,
  464313723232108: 70.93671866515905,
  484200662032118: 70.82002613348828,
  444512600161107: 70.81113811510683,
  454313751325013: 70.84824987211786,
  434210770032032: 70.82679318699779,
  424514731234017: 70.83404629574744,
  484118651132007: 70.7384940053567,
  484212794022037: 70.71035632057944,
  464102603131013: 70.69820359678033,
  474400631062017: 70.64531440549624,
  "4042106a3231133": 70.62217429913548,
  404216751062103: 70.6659607440132,
  464213631041018: 70.62260696166845,
  474302600032002: 70.61961250099803,
  494710721144017: 70.64245203509147,
  464102733032032: 70.61751318553537,
  454513631244038: 70.55983576786434,
  404217631142108: 70.48364190685598,
  444210701252017: 70.52533198178314,
  464217750164007: 70.52356106531464,
  464700751041008: 70.48833240379845,
  474712731124333: 70.48992592947917,
  404112703234008: 70.37164902279767,
  404103643232007: 70.2792534304512,
  464113650322307: 70.28545657230467,
  464714711222017: 70.30159948809536,
  454212694164027: 70.13976154844673,
  484115661322023: 70.21167694524104,
  424313731022333: 70.27014494404418,
  "4145007a1142303": 70.25817493699768,
  414306761122000: 70.21621894179201,
  454314660072307: 70.16248555393966,
  434310710024308: 70.21893636634354,
  434313611135133: 70.15028854969677,
  434305691034013: 70.03191626016688,
  "4043026a1122030": 70.09189534176534,
  454314763162207: 70.08770640498071,
  404107791122027: 70.01078375418324,
  484100720161007: 70.07222111618879,
  454314753144038: 70.06533104293192,
  "4041127a0124127": 70.0568060229228,
  474510614161107: 69.89991230984606,
  444515752322007: 69.9014482989803,
  444502791124127: 69.81426491048164,
  444315731152033: 69.83946976630064,
  404616761161007: 69.82916288212238,
  444515702032003: 69.81911078149004,
  454712613044007: 69.71377569474467,
  484212764262107: 69.73892570291056,
  404214793032023: 69.64956538868235,
  464700671042008: 69.64572362455428,
  "4341147a2132008": 69.69131774180863,
  424214733122127: 69.67460228606792,
  444217630131017: 69.58424016914226,
  "4741137a1132323": 69.61673273976929,
  424210691034038: 69.44514439370823,
  444712691132002: 69.4016640047312,
  434310792165027: 69.42220041472042,
  424315771164007: 69.4771870384814,
  444515770132137: 69.44425173756885,
  434213764064027: 69.44285544943035,
  464514713131107: 69.41579304980112,
  474303721124127: 69.40506535832667,
  454215781332007: 69.32129099735779,
  474307761064107: 69.28390557706544,
  434110661152000: 69.16754200704686,
  454203780032007: 69.20060133084553,
  414302760234137: 69.23408715780998,
  "4346106a0162037": 69.15794834810269,
  434310752231013: 69.20320159894668,
  494200704132013: 69.14283951168619,
  454213701232308: 69.11824459080376,
  424205750142007: 69.05229503982297,
  414515731122327: 69.06802429453066,
  "4843136a4124007": 68.96775200997573,
  494200761171003: 69.00579841874513,
  "4143107a4142137": 68.9703669530023,
  474510651122130: 68.90625971819061,
  434217761262027: 68.88076609570798,
  404110713132318: 68.87578171343662,
  434202723131107: 68.85396769061646,
  "4543006a1052027": 68.78343055353932,
  464316690132013: 68.71813191021333,
  "4542127a0222023": 68.81084106225259,
  "4443126a1064033": 68.75620748379613,
  434210703232123: 68.78402999375629,
  454204611162117: 68.69555708451335,
  424312731125023: 68.77786370476757,
  444517631121107: 68.67476418998308,
  444410763042003: 68.70222936600014,
  464505691232027: 68.5437998316482,
  "4343037a0032033": 68.6304958124118,
  494400764132007: 68.5701079441814,
  434510691342008: 68.4358373080603,
  414310631252037: 68.5061647732175,
  "4343026a0031037": 68.50594838791984,
  434610694134007: 68.39661641175225,
  424212660035017: 68.47488989757159,
  474306771132137: 68.5270938430044,
  454515694132008: 68.34632503840487,
  484304661152007: 68.38506054514396,
  444313761125012: 68.44540996938007,
  404310701361027: 68.41744972788537,
  494300702021007: 68.39936503766353,
  494510703124307: 68.38011050187892,
  404204753231007: 68.34683894641935,
  484200751124317: 68.33474344523373,
  494302791062037: 68.22986145701232,
  474210753021317: 68.25225497321406,
  444113671031107: 68.18710531596231,
  494305633161007: 68.16437938373494,
  404210741162038: 68.18496748829028,
  474513631141103: 68.1416856312583,
  494104760232017: 68.18059390925418,
  434710721161037: 68.17701171687125,
  "4841106a4125007": 68.10327729551263,
  404514773222007: 68.16617144860942,
  464513711222008: 68.124373897294,
  444214661132112: 68.02505745120483,
  474500661174008: 68.02441944273869,
  424314611062007: 67.91653315917907,
  404204660121013: 67.88514247554711,
  444502663024013: 67.8843051328575,
  494112662142008: 67.86907912664492,
  464604661131037: 67.84276330812122,
  474102610122137: 67.83780306101234,
  464110641224003: 67.72736834457541,
  414204630034007: 67.68984378468015,
  464703731062003: 67.70118966835977,
  434307631224007: 67.63530855326447,
  484514663032307: 67.62542465343533,
  474214760244037: 67.62733895010302,
  484100714122037: 67.6063249221365,
  464312664064008: 67.55865522632132,
  484110661162030: 67.51544623193382,
  484300773142017: 67.57892820149951,
  464210781031037: 67.55226297942463,
  464500741141007: 67.55401182675878,
  "4741137a3141007": 67.52672817609275,
  474317604132007: 67.43821809268238,
  "4442107a4322107": 67.45705768658826,
  494110681132027: 67.31337825549976,
  464316664022033: 67.31226764993515,
  484700661131023: 67.2924206075523,
  454302612162003: 67.25812250361507,
  424500621062007: 67.24345609078753,
  404502621322007: 67.17248336669357,
  474315721022013: 67.21165024433525,
  454410733134107: 67.13688385874774,
  404500653121103: 67.00561056581857,
  444516661161003: 66.99573839462067,
  "4343007a0125008": 67.01417336453937,
  404110771035033: 66.95039115116523,
  474116663122108: 66.88501013959441,
  414310791362037: 66.81760165782741,
  444102761332018: 66.86140458548833,
  444100752162008: 66.82940529826027,
  454113653234107: 66.69206333349837,
  "4742147a2134007": 66.69564691847506,
  434310771141013: 66.68857814514865,
  "4041106a0042033": 66.59468001359262,
  464513601032103: 66.60223295810454,
  434213712142007: 66.62503844345407,
  464317711222007: 66.583662176549,
  424205621122007: 66.49103156897029,
  434212771064007: 66.54223882152648,
  404514632132008: 66.49762035537644,
  "4042107a1322337": 66.51822344987725,
  444318761024007: 66.51137421554202,
  494310610165007: 66.4549639200666,
  404300791232123: 66.40464890083747,
  494104731332007: 66.47797766630183,
  404200760162323: 66.38434627281507,
  444100703164003: 66.38083462328217,
  444110703221037: 66.22285171399714,
  454710721242003: 66.19833540728881,
  434300662242117: 66.13943722688721,
  404206760131037: 66.13514383625049,
  444502631164027: 66.01296581363538,
  444110671235003: 65.997074315915,
  434115661134308: 65.94200329351428,
  494710634122017: 65.9330503527374,
  464510643121007: 65.8743124783234,
  484215733132008: 65.90964064876623,
  474318661132307: 65.85641928336747,
  454314634134137: 65.84767666996089,
  454215711332003: 65.84195240417237,
  494312671022003: 65.75642667899332,
  444105761032002: 65.75355019358895,
  444110613162117: 65.68375966199076,
  "4641126a1062017": 65.59590352224448,
  464214631262027: 65.57899456537139,
  474310691154007: 65.46069196824159,
  404410731042007: 65.55570992219782,
  454312711322307: 65.5492535490171,
  454313613032103: 65.48120991168966,
  464302664362007: 65.46157308278426,
  414512773132007: 65.45692470612545,
  424310731324017: 65.41139380897677,
  "4445006a1142013": 65.29063217518367,
  454513693042007: 65.19466426673996,
  474210771064003: 65.29819426054648,
  414113760142017: 65.21298833939947,
  444112651132308: 65.06087047959848,
  "4741107a1232018": 65.10774320245085,
  "4541126a3122117": 65.05215885833547,
  454513632332007: 65.03695674026139,
  444512723124007: 65.07983066004404,
  404313760124018: 65.0326456519805,
  474514751124003: 65.0016218744027,
  424314733131007: 64.96715846337376,
  404210653124108: 64.77345216921864,
  474116751121007: 64.76961324481417,
  464710793122008: 64.68178915281615,
  404303791232107: 64.44341168313053,
  494110760145037: 64.46710574330018,
  474110640132017: 64.36680405830123,
  414115662132037: 64.36476309718866,
  434310621164008: 64.24483860212612,
  474110640122037: 64.20877721563882,
  404110761135128: 64.17781184154313,
  464510631172017: 64.11277737427366,
  454514701132033: 64.13783043731287,
  464213681132007: 64.01835177564374,
  404312613142003: 64.0193723564155,
  404510763361007: 64.05872335869957,
  424200763142008: 63.92559784833342,
  464300762332137: 63.88465501493689,
  444312631132338: 63.801463433088806,
  474315760021107: 63.850476054852805,
  "4342107a3022033": 63.80580808935012,
  454313791162033: 63.72664016388793,
  434105631232003: 63.72358724636453,
  444300641125007: 63.71609803597082,
  494512761032103: 63.65066635588616,
  404310763132112: 63.598989624959934,
  414210764122127: 63.41315382547233,
  414510651142027: 63.358347834861846,
  464212761142308: 63.396624539037916,
  474512661025017: 63.27678564605016,
  444510711142137: 63.26017755420081,
  404300731144303: 63.251992256885785,
  "4743007a0122117": 63.234315866727734,
  494112662122037: 63.17470669302392,
  494213663062007: 63.09157728999808,
  454316671132107: 63.07707704903578,
  454110633232327: 62.9797890811168,
  404212763032108: 62.98704972663443,
  464210660061027: 62.82870883750879,
  464115721132107: 62.87991936450743,
  484114660132037: 62.809966826832046,
  444103661031107: 62.793203658456505,
  404315751222107: 62.79759161922113,
  464110713032008: 62.800868049242084,
  434610661062037: 62.74002674059499,
  454114661332307: 62.7331172699802,
  454300663032338: 62.7146507595731,
  454214732131007: 62.7400883933935,
  404515600132007: 62.63210966467304,
  444312761264017: 62.69040667319021,
  444710661322307: 62.529817094863354,
  474210660031023: 62.517929084401814,
  404300731041027: 62.56923264106645,
  474200771021007: 62.4939172646962,
  444310761121102: 62.43530119075788,
  474510700042007: 62.43892296020254,
  404510790042007: 62.349814049311455,
  434212762332007: 62.34763560232475,
  404310651121303: 62.23851378465736,
  474300651125307: 62.19129874110027,
  414103633132007: 62.10211618681733,
  464302663142033: 62.01774004385958,
  474215651042007: 61.98048609046355,
  464210634135003: 61.989673081328554,
  444312630232027: 61.99406039542085,
  414200761022127: 61.87362630995499,
  464312731164027: 61.69468054717107,
  484310603232007: 61.62520361968749,
  "4443127a1022017": 61.53101251714684,
  464710761125008: 61.535877064326094,
  474610761224007: 61.47756518038887,
  464313601042007: 61.4016390118256,
  454300710124003: 61.42094289221706,
  454500631135137: 61.32085986506231,
  464713631162007: 61.30783287874615,
  414310653022037: 61.29393666502681,
  474313663034017: 61.279895391658286,
  464110703142037: 61.30834486446691,
  464100693132027: 61.152893436344115,
  404210662144107: 61.15895674638424,
  404312601332007: 61.14838552653393,
  484310603132107: 61.0819439035154,
  454212660131008: 60.873531036087385,
  484210621132003: 60.85537775568875,
  444310764162013: 60.85285025393072,
  464200631131013: 60.656299256871485,
  464316601122007: 60.62203746169327,
  454112662132033: 60.55159042792906,
  434315760134003: 60.50813667217256,
  404302661164107: 60.40653926757742,
  404310753134008: 60.18980399276162,
  404215731032017: 60.09892661210321,
  474310660135117: 60.06426925707051,
  464510633122117: 60.011553460226416,
  464510761064017: 60.06626412390679,
  464112760162037: 59.98541018413832,
  434610761135007: 59.892265945964816,
  454310614132027: 59.75805541427421,
  494300701132107: 59.831058590345926,
  454513751232007: 59.76752923703077,
  404112731142003: 59.55035031481877,
  444514661135007: 59.48201168568712,
  414310791122017: 59.346085029726446,
  464110731242003: 59.24254745437813,
  434312761124033: 59.04643676889038,
  434110661062033: 58.962570587309195,
  "4643007a1142037": 58.95010518353019,
  444110633131037: 58.56605116544159,
  454115761022027: 58.53018445801972,
  434304761222007: 58.306886120696554,
  454310631134327: 58.22041974532685,
  434500661231007: 58.189385134767036,
  424300661134003: 58.118558001675545,
  444214730132007: 58.044624787540855,
  474310794122007: 57.797981003390554,
  464310764132013: 57.36587244267456,
  434710761062007: 57.32064513102883,
  444500660122017: 57.235161481333854,
  404210771132003: 57.242905592151864,
  454310601242007: 57.06870312462326,
  404510661024003: 57.05236565717464,
  474310733232003: 57.10147422259056,
  474510761222027: 56.971362106589176,
  434300631021007: 56.83895204848832,
  404300631142027: 56.82932748240285,
  434110661064007: 56.46865698321995,
  444312761131037: 56.15409980083066,
  404310761032038: 55.44845307619052,
  474100731132107: 54.65237612305157,
  454105761122007: 54.36797177674871,
  464510661132307: 53.49654032449398,
  404314761122007: 52.78021189496927,
  464110631132037: 52.30610747809868,
  "0a421a691482009": 9031.530124474972,
};
