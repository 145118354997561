import { useEffect, useState } from "react";
import "./CollectionSale.scss";
import Container from "../../components/Container/Container";
import Counter from "../../components/Counter/Counter";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import SmallTitle from "../../components/SmallTitle/SmallTitle";
import Chance from "../../components/Chance/Chance";
import Price from "../../components/Price/Price";
import Timer from "../../components/Timer/Timer";
import utils from "../../utils/utils";
import MessageBar from "../../components/MessageBar/MessageBar";
import Separator from "../../components/Separator/Separator";
import useContractApi from "../../hooks/useContractApi";
import useTerra from "../../hooks/useTerra";
import { useConnectedWallet } from "@terra-money/use-wallet";
import BoxImagesCarousel from "../../components/BoxImagesCarousel/BoxImagesCarousel";
import { BuyCollectionNft } from "../BuyCollectionNft/BuyCollectionNft";
import minting from "../../assets/images/chests/minting.jpg";

const CollectionSale = ({
  collectionAddress,
  mintManagerAddress,
  getCollectionConfig,
  getMintManagerConfig,
  tokensAmountQuery,
  tokensAmountResultPropertyName,
  getWalletInfo,
  whitelistedAddressesResultPropertyName,
  NFTImages,
  title,
  titleSuffix,
  description,
  chances,
  chancesText,
  chancesType = "chances",
  getNftInfo,
  congratulationsPage,
  returnedDrawedAttrName,
  attrValueThatHasToChange,
}) => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  const [error, setError] = useState(null);
  const [collectionLimit, setCollectionLimit] = useState(0);

  const [walletInfo, setWalletInfo] = useState(null);

  const [numTokens, setNumTokens] = useState(0);
  const [saleLaunchTimestamp, setSaleLaunchTimestamp] = useState(0);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [collectionView, setCollectionView] = useState("loading");
  const [startTime, setStartTime] = useState();
  const [publicStartTime, setPublicStartTime] = useState();

  useEffect(() => {
    const init = async () => {
      const contractConfig = await getCollectionConfig();
      setError(null);
      if (contractConfig === null) {
        setError(
          "There was a problem while fetching the NFT collection details."
        );
      }
      setCollectionLimit(parseInt(contractConfig?.collection_supply_limit));
    };
    init();
  }, [getCollectionConfig]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      const getWalletInfoResult = await getWalletInfo();
      setWalletInfo(getWalletInfoResult);
    }, 3000);
    return () => clearInterval(intervalId);
  }, [getWalletInfo]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      const contractQueryResult = await queryContract(
        collectionAddress,
        tokensAmountQuery
      );
      if (!contractQueryResult) {
        return;
      }
      if (contractQueryResult[tokensAmountResultPropertyName]) {
        setNumTokens(
          parseInt(contractQueryResult[tokensAmountResultPropertyName])
        );
      }
    }, 3000);
    return () => clearInterval(intervalId);
  }, [
    collectionAddress,
    network.name,
    queryContract,
    tokensAmountQuery,
    tokensAmountResultPropertyName,
  ]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      if (isWhitelisted) {
        if (saleLaunchTimestamp > 0 && Date.now() > saleLaunchTimestamp) {
          setIsWhitelisted(false);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [isWhitelisted, saleLaunchTimestamp]);

  useEffect(() => {
    const getTimerInfo = async () => {
      if (!connectedWallet?.terraAddress) {
        setCollectionView("connectWallet");
        return;
      }

      const contractInforResult = await getMintManagerConfig();

      const getWalletInfoResult = await getWalletInfo();
      const whitelisted = getWalletInfoResult?.allowed_whitelist;

      const whitelistSaleLaunch =
        contractInforResult?.whitelist_sale_launch_timestamp;
      const publicSaleLaunch = contractInforResult?.sale_launch_timestamp;
      const launch = whitelisted ? whitelistSaleLaunch : publicSaleLaunch;

      setIsWhitelisted(whitelisted && Date.now() < publicSaleLaunch * 1000);
      setSaleLaunchTimestamp(publicSaleLaunch * 1000);
      setStartTime(new Date(launch * 1000));
      setPublicStartTime(new Date(publicSaleLaunch * 1000));
      if (new Date(launch * 1000) > new Date()) {
        setCollectionView("timer");
      } else if (
        whitelisted &&
        new Date(publicSaleLaunch * 1000) > new Date()
      ) {
        setCollectionView("saleWithTimer");
      } else {
        setCollectionView("sale");
      }
    };

    getTimerInfo();
  }, [
    connectedWallet,
    getWalletInfo,
    network.name,
    collectionAddress,
    whitelistedAddressesResultPropertyName,
    getMintManagerConfig,
  ]);

  const mintEventHandler = async () => {
    const getWalletInfoResult = await getWalletInfo();
    setWalletInfo(getWalletInfoResult);
  };

  return (
    <div className="collection-sale">
      <Container className="collection-sale__container" narrow>
        <div className="collection-sale__photos">
          <BoxImagesCarousel
            className="collection-sale__boxes"
            NFTImages={NFTImages ? NFTImages : [minting]}
          />
          <Counter
            className="collection-sale__counter"
            left={collectionLimit - numTokens}
            all={collectionLimit}
          />
        </div>
        <div>
          <Text
            className="collection-sale__pre-title"
            text="Created by <span>MintDAO</span>"
          />
          <Title
            className="collection-sale__title"
            text={title}
            suffix={titleSuffix}
            inverted
          />

          <SmallTitle text="description" />
          <div className="collection-sale__description">{description}</div>

          {collectionView === "loading" && (
            <div className="collection-sale__starts">
              <SmallTitle text="Loading..." />
            </div>
          )}

          {collectionView === "connectWallet" && (
            <>
              <Separator />

              <div className="collection-sale__connect-wallet-message">
                <Text text="Connect your wallet first." />
              </div>
            </>
          )}

          {collectionView === "timer" && (
            <>
              <div className="collection-sale__starts">
                <SmallTitle text="Event starts in" />
              </div>
              <Timer
                className="collection-sale__timer"
                expiryTimestamp={startTime}
                onExpire={() => setCollectionView("sale")}
              />
              {isWhitelisted && (
                <div className="collection-sale__starts">
                  <Title
                    className="small-title"
                    text={"Your address is"}
                    suffix={"whitelisted"}
                    inverted
                  />
                </div>
              )}
            </>
          )}

          {(collectionView === "sale" ||
            collectionView === "saleWithTimer") && (
            <>
              {collectionView === "saleWithTimer" && (
                <>
                  <div className="collection-sale__starts">
                    <SmallTitle text="Whitelist sale ends in" />
                  </div>
                  <Timer
                    className="collection-sale__timer"
                    expiryTimestamp={publicStartTime}
                    onExpire={() => setCollectionView("sale")}
                  />
                </>
              )}
              <SmallTitle text="PRICE" />
              <Price
                price={
                  walletInfo
                    ? (walletInfo?.current_price / 1000000).toFixed(2)
                    : "-"
                }
                denom={walletInfo?.denom}
              />

              <Chance
                whitelisted={isWhitelisted}
                chances={chances}
                chancesText={chancesText}
                chancesType={chancesType}
              />

              <footer className="collection-sale__footer">
                <BuyCollectionNft
                  walletInfo={walletInfo}
                  availableTokens={Math.max(0, collectionLimit - numTokens)}
                  getNftInfo={getNftInfo}
                  collectionAddress={collectionAddress}
                  mintManagerAddress={mintManagerAddress}
                  congratulationsPage={congratulationsPage}
                  returnedDrawedAttrName={returnedDrawedAttrName}
                  attrValueThatHasToChange={attrValueThatHasToChange}
                  mintEvent={mintEventHandler}
                />
                {error && <MessageBar text={error} type="error" />}
              </footer>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default CollectionSale;
