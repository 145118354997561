import { contractAddresses } from "../../../config";
import useTerra from "../../../hooks/useTerra";
import CollectionSale from "../../../containers/CollectionSale/CollectionSale";
import NFTImages from "../../../assets/images/PlaynityTheGenesis";
import Text from "../../../components/Text/Text";
import usePlaynityTheGenesisApi from "../../../hooks/usePlaynityTheGenesisApi";

const PlaynityTheGenesisCollection = () => {
  const { network } = useTerra();
  const {
    getWalletInfo,
    getNftInfo,
    getCollectionConfig,
    getMintManagerConfig,
  } = usePlaynityTheGenesisApi();

  const description = (
    <>
      <Text
        text={`Our factory is compact and prepared to start the minting process.
              PlayNity the genesis NFT have the following utilities:`}
      />

      <ul className="collection-sale__description-benefits">
        <li>
          <Text text="<span>PEX points daily booster</span>*" />
        </li>
        <li>
          <Text text="Scholarship program earnings booster" />
        </li>
        <li>
          <Text text="Higher voting power and a chance for Airdrop*" />
        </li>
        <li>
          <Text text="Presale allocations slot for Guild Clans token" />
        </li>
        <li>
          <Text text="Discounts on future PlayNity Merch Collection" />
        </li>
        <li>
          <Text text="Higher chance of winning Giveaways" />
        </li>
        <li>
          <Text text="Higher chance of getting Whitelist slot for the first PlayNity Lands NFT collection" />
        </li>
        <li>
          <Text text="MINT tokens Yield farming" />
        </li>
        <li>
          <Text text="Early Access Pass to Academy with a discount" />
        </li>
        <li>
          <Text text="Presale tickets with discount for PlayNity Lands NFT*" />
        </li>
      </ul>
      <Text
        className="collection-sale__disclaimer"
        text="*The actual amount or value depends on the rarity of the NFT."
      ></Text>
    </>
  );

  const distribution = [
    { name: "mythic", chance: 20 },
    { name: "legendary", chance: 60 },
    { name: "epic", chance: 120 },
    { name: "rare", chance: 500 },
    { name: "common", chance: 1100 },
  ];

  const chance = {
    chances: distribution,
    whitelistChances: distribution,
  };

  return (
    <CollectionSale
      // contract interactions
      collectionAddress={contractAddresses[network.name].playnityTheGenesis}
      mintManagerAddress={
        contractAddresses[network.name].playnityTheGenesisMintManager
      }
      getCollectionConfig={getCollectionConfig}
      getMintManagerConfig={getMintManagerConfig}
      tokensAmountQuery={{ num_tokens: {} }}
      tokensAmountResultPropertyName="count"
      getWalletInfo={getWalletInfo}
      getNftInfo={getNftInfo}
      whitelistedAddressesResultPropertyName="whitelisted"
      // UI
      NFTImages={NFTImages}
      title="Playnity"
      titleSuffix="The Genesis Collection"
      description={description}
      chances={chance}
      chancesText="Rarity distribution"
      chancesType="distribution"
      congratulationsPage="/collections/playnity-the-genesis/nft"
      // Drawing attributes
      returnedDrawedAttrName="Rarity"
      attrValueThatHasToChange={null}
    ></CollectionSale>
  );
};

export default PlaynityTheGenesisCollection;
