import { MsgExecuteContract } from "@terra-money/terra.js";
import { useState } from "react";
import useContractApi from "./useContractApi";
import Modal from "../components/Modal/Modal";
import LoadingPopup from "../components/LoadingPopup/LoadingPopup";
import Text from "../components/Text/Text";
import TxHash from "../components/TxHash/TxHash";
import { useConnectedWallet } from "@terra-money/wallet-provider";

/**
 * @description Hook handling the logic of harvesting and rendering the modal
 * @returns {{HarvestModal: (function(): JSX.Element), harvest: (function(string, number, string): Promise<void>)}}
 */
export const useHarvest = () => {
  const connectedWallet = useConnectedWallet();
  const { executeContractAndWaitForResult } = useContractApi();
  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  /**
   * @returns {JSX.Element}
   */
  const HarvestModal = () =>
    isModalOpen && (
      <Modal
        id="modal"
        type={loading ? "warning" : txError ? "error" : "success"}
        onCloseClick={() => {
          setModalOpen(false);
        }}
        showCloseButton
      >
        <LoadingPopup
          status={loading ? "loading" : txError ? "error" : "success"}
          loadingComponent={
            <>
              {!txInfo ? (
                <Text
                  className="loading-popup__copy"
                  text="Approve transaction"
                />
              ) : (
                <>
                  <TxHash
                    txhash={txInfo?.result.txhash}
                    network={connectedWallet?.network.name}
                  ></TxHash>
                </>
              )}
            </>
          }
          successComponent={
            <TxHash
              txhash={txInfo?.result.txhash}
              network={connectedWallet?.network.name}
            ></TxHash>
          }
          errorMsg={txError ? txError.toString() : null}
        />
      </Modal>
    );

  /**
   * @param {string} contractAddress
   * @param {number} harvestFee
   * @param {string} tokenId
   * @returns {Promise<void>}
   */
  const harvest = async (contractAddress, harvestFee, tokenId) => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    let msgs = [
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddress,
        {
          harvest: {
            token_id: tokenId,
          },
        },
        harvestFee ? { uusd: harvestFee } : {}
      ),
    ];

    setLoading(true);
    const txResult = await executeContractAndWaitForResult(
      msgs,
      setTxInfo,
      setTxError
    );
    setLoading(false);
    console.log(txResult);
  };

  return { harvest, HarvestModal };
};
