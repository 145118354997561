// import RoadMap from "../../components/RoadMap/RoadMap";
// import Chests from "../../components/Chests/Chests";
// import CollectionAnouncement from "../../components/CollectionAnouncement/CollectionAnouncement";
// import HappyHourAnnouncement from "../../components/HappyHourAnnouncement/HappyHourAnnouncement";
import { useEffect } from "react";
// import Treasury from "../Treasury/Treasury";
// import BuyMint from "../BuyMint/BuyMint";
import "./HomePage.scss";
import TerraWalletToETH from "../../components/TerraWalletToETH/TerraWalletToETH";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const additionalInfo = <></>;

  return (
    <>
      <TerraWalletToETH />
      {/* <HappyHourAnnouncement />
      <Treasury displayAsComponent={false} className="homepage__treasury" />
      <BuyMint displayAsComponent={false} className="homepage__buy-mint" />
      <Chests />
      <RoadMap /> */}
    </>
  );
};

export default HomePage;
