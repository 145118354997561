import "./Staking.scss";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import Tabs from "../../components/Tabs/Tabs";
import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import BalanceInput from "../../components/BalanceInput/BalanceInput";
import Text from "../../components/Text/Text";
import Button from "../../components/Button/Button";
import Title from "../../components/Title/Title";
import SmallTitle from "../../components/SmallTitle/SmallTitle";
import Timer from "../../components/Timer/Timer";
import Period from "../../components/Period/Period";
import Modal from "../../components/Modal/Modal";
import utils from "../../utils/utils";
import UnstakePopup from "../../components/UnstakePopup/UnstakeConfirmationPopup";
import useStakingApi from "../../hooks/useStakingApi";
import useUSTRewardsApi from "../../hooks/useUSTRewardsApi";
import useBalances from "../../hooks/useBalances";
import useStakingApr from "../../hooks/useStakingApr";
import useForceUpdate from "../../hooks/useForceUpdate";
import { TableVirtuoso } from "react-virtuoso";
import { tokensPrecision } from "../../config";
import ProvideLP from "../../containers/ProvideLP/ProvideLP";
import WithdrawLP from "../../containers/WithdrawLP/WithdrawLP";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import { contractAddresses } from "../../config";
import useTerra from "../../hooks/useTerra";
import TxHash from "../../components/TxHash/TxHash";
import { Link } from "react-router-dom";
import { ReactComponent as Chart } from "./chart.svg";
import Table from "../../components/Table/Table";
import InstantWithdrawConfirmationPopup from "../../components/InstantWithdrawConfirmationPopup/InstantWithdrawConfirmationPopup";

import { ReactComponent as MintLogo } from "../../assets/images/mint-logo.svg";
import { ReactComponent as UstLogo } from "../../assets/images/ust.svg";

const Staking = () => {
  const forceUpdate = useForceUpdate();
  const connectedWallet = useConnectedWallet();
  const { balances, updateBalances } = useBalances();

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const { network } = useTerra();

  const [choosenUnbondingRow, setChoosenUnbondingRow] = useState(null);
  const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);
  const [chosenInstantWithdrawRow, setChosenInstantWithdrawRow] =
    useState(null);
  const [isInstantWithdrawModalOpen, setInstantWithdrawModalOpen] =
    useState(false);
  const [unbondAmount, setUnbondAmount] = useState("");
  const { type, action } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [stakingType, setStakingType] = useState(type || "mint");
  const { calculateRewardPoints, calculateAPR, calculateUSTAPR } =
    useStakingApr(stakingType);
  const stakingContractAddress =
    stakingType === "mint"
      ? contractAddresses[network.name].stakingSAS
      : contractAddresses[network.name].stakingLP;
  const {
    getConfig,
    getStakerInfo,
    getStakerUnbondings,
    sendAndBond,
    claimRewards,
    submitToUnbond,
    unbond,
  } = useStakingApi(stakingType);
  const { getConfig: getUstRewardsConfig } = useUSTRewardsApi();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateState = useCallback(() => {
    const config = async () => {
      const config = await getConfig();
      if (config) {
        setContractConfig(config);
      }
    };
    const ustRewardsConfig = async () => {
      const config = await getUstRewardsConfig();
      if (config) {
        setUstRewardsContractConfig(config);
      }
    };
    const stakerInfo = async () => {
      if (connectedWallet) {
        const stakerInfo = await getStakerInfo(connectedWallet.walletAddress);
        if (stakerInfo) {
          setStakingTableRows(stakerInfo?.unbonding_periods_bond_amounts);
          setPendingReward(stakerInfo?.pending_reward / 1000000);
        }
      } else {
        setStakingTableRows([]);
        setPendingReward(0);
      }
    };
    const stakerUnbondings = async () => {
      if (connectedWallet) {
        const stakerUnbondings = await getStakerUnbondings({
          staker: connectedWallet.walletAddress,
        });
        if (stakerUnbondings) {
          setUnbondingTableRows(stakerUnbondings);
        }
      } else {
        setUnbondingTableRows([]);
      }
    };
    config();
    ustRewardsConfig();
    updateBalances();
    stakerInfo();
    stakerUnbondings();
  }, [
    connectedWallet,
    getConfig,
    getStakerInfo,
    getStakerUnbondings,
    updateBalances,
    getUstRewardsConfig,
  ]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(updateState, 10000);
    return () => clearInterval(intervalId);
  }, [updateState]);

  const [lpStakingTab, setLpStakingTab] = useState(action || "stake");
  const [tabs, setTabs] = useState([
    {
      name: "first",
      items: [
        { name: "MINT Staking", active: stakingType === "mint" },
        { name: "LP Staking", active: stakingType === "lp" },
      ],
    },
    {
      name: "second",
      items: [
        { name: "Stake", active: lpStakingTab === "stake" },
        { name: "Provide", active: lpStakingTab === "provide" },
        { name: "Withdraw", active: lpStakingTab === "withdraw" },
      ],
    },
  ]);

  useEffect(() => {
    const targetPath = `/stake/${stakingType}/${
      stakingType === "mint" ? "" : lpStakingTab
    }`;
    if (location.pathname !== targetPath) {
      navigate(targetPath);
    }
  }, [stakingType, lpStakingTab, action, type, navigate, location]);

  const onTabClick = (item, rowName) => {
    updateBalances();
    setMintInputAmount("");
    setLpInputAmount("");
    setTabs((prevState) => {
      return [
        ...prevState.map((row) => {
          if (row.name !== rowName) {
            return row;
          }

          return {
            ...row,
            items: row.items.map((i) => ({
              ...i,
              active: i.name === item.name,
            })),
          };
        }),
      ];
    });
  };

  const balanceFormetterWrapper = (setterFunction) => {
    return (inputAmount) => {
      let amount = inputAmount;
      if (inputAmount < 0) {
        amount = Math.abs(inputAmount);
      }

      setterFunction(amount);
    };
  };

  const balanceValidationWrapper = (balance, amount) => {
    return amount * tokensPrecision > balance ? "Invalid funds" : null;
  };

  const [unbondingPeriod, setUnbondingPeriod] = useState(31);
  const [pendingReward, setPendingReward] = useState(0);
  const [stakingTableRows, setStakingTableRows] = useState([]);
  const [unbondingTableRows, setUnbondingTableRows] = useState([]);
  const [contractConfig, setContractConfig] = useState({});
  const [ustRewardsContractConfig, setUstRewardsContractConfig] = useState({});
  const [mintInputAmount, setMintInputAmount] = useState("");
  const [lpInputAmount, setLpInputAmount] = useState("");

  const mintPowerMultiplier =
    parseInt(
      ustRewardsContractConfig?.staking_score_points_multipliers?.find(
        (e) => e[0] === stakingContractAddress
      )?.[1]
    ) / tokensPrecision || 1;

  const stakerTotalBondAmount = stakingTableRows.reduce(
    (prev, curr) => prev + parseInt(curr.bonded_amount),
    0
  );
  const stakerTotalScorePoints = stakingTableRows.reduce(
    (prev, curr) => prev + parseInt(curr.score_points),
    0
  );
  const stakerTotalUnbondingAmount = unbondingTableRows.reduce(
    (prev, curr) => prev + parseInt(curr.amount),
    0
  );

  const beforeTransaction = () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
  };

  const claim = () => {
    beforeTransaction();
    claimRewards(setLoading, setTxInfo, setTxError, updateState);
  };

  const stake = () => {
    const inputAmount =
      stakingType === "mint" ? mintInputAmount : lpInputAmount;
    console.log("stake", inputAmount);
    beforeTransaction();
    sendAndBond(
      Math.floor(inputAmount * tokensPrecision),
      unbondingPeriod,
      setLoading,
      setTxInfo,
      setTxError,
      () => {
        setMintInputAmount("");
        setLpInputAmount("");
        updateState();
      }
    );
  };

  const startUnbond = (unbonding_period, amount) => {
    beforeTransaction();
    setIsUnstakeModalOpen(false);
    submitToUnbond(
      unbonding_period,
      amount,
      setLoading,
      setTxInfo,
      setTxError,
      updateState
    );
  };

  const withdraw = (unbondingRow) => {
    beforeTransaction();
    const instantWithdraw =
      Date.now() > unbondingRow.end_time / 1000000 ? false : true;
    unbond(
      unbondingRow.unbonding_key,
      instantWithdraw,
      setLoading,
      setTxInfo,
      setTxError,
      updateState
    );
  };

  const calculateBurnPercentage = (unbondingPeriod) => {
    if (unbondingPeriod === 0) {
      return (0).toFixed(2);
    }
    if (unbondingPeriod <= 5) {
      return (5).toFixed(2);
    }
    const ratio = (unbondingPeriod - 5) / (31 - 5);
    return (ratio * 45 + 5).toFixed(2);
  };

  const unstake = (unbondingRow) => {
    setUnbondAmount("");
    setChoosenUnbondingRow(unbondingRow);
    setIsUnstakeModalOpen(true);
  };

  const validateUnbondAmount = () => {
    if (unbondAmount === "") return null;
    if (
      unbondAmount <= 0 ||
      unbondAmount >
        parseInt(choosenUnbondingRow?.bonded_amount) / tokensPrecision
    ) {
      return "Invalid amount";
    }
    return null;
  };

  const isStackingHidden = () => {
    return lpStakingTab === "provide" || lpStakingTab === "withdraw";
  };

  return (
    <div className="staking">
      <AppBgLayout>
        {isModalOpen && (
          <Modal
            id="modal"
            type={loading ? "warning" : txError ? "error" : "success"}
            onCloseClick={() => {
              setModalOpen(false);
            }}
            showCloseButton
          >
            <LoadingPopup
              status={loading ? "loading" : txError ? "error" : "success"}
              loadingComponent={
                <>
                  {!txInfo ? (
                    <Text
                      className="loading-popup__copy"
                      text="Approve transaction"
                    />
                  ) : (
                    <>
                      <TxHash
                        txhash={txInfo?.result.txhash}
                        network={connectedWallet?.network.name}
                      />
                    </>
                  )}
                </>
              }
              successComponent={
                <TxHash
                  txhash={txInfo?.result.txhash}
                  network={connectedWallet?.network.name}
                />
              }
              errorMsg={txError ? txError.toString() : null}
            />
          </Modal>
        )}

        {isUnstakeModalOpen && (
          <Modal onCloseClick={() => setIsUnstakeModalOpen(false)}>
            <UnstakePopup
              onCancel={() => setIsUnstakeModalOpen(false)}
              onConfirm={() =>
                startUnbond(
                  choosenUnbondingRow.unbonding_period,
                  (unbondAmount * tokensPrecision).toString()
                )
              }
              onConfirmDisabled={
                !(
                  unbondAmount > 0 &&
                  unbondAmount <=
                    parseInt(choosenUnbondingRow?.bonded_amount) /
                      tokensPrecision
                )
              }
            >
              <div className="staking__details staking__details--highlighted">
                <div className="staking__details-row">
                  <Text className="staking__detail" text="unbonding period" />
                  <Text className="staking__value" text={"0"} />
                </div>
              </div>
              <div className="staking__details">
                <div className="staking__details-row">
                  <Text className="staking__detail" text="Est Tx Fee" />
                  <Text className="staking__value" text="~ 1 UST" />
                </div>
              </div>
              <div className="staking__condensed_input_container">
                <BalanceInput
                  onChange={setUnbondAmount}
                  value={unbondAmount}
                  balance={choosenUnbondingRow?.bonded_amount}
                  placeholderValue={"0,00"}
                  currency="mint"
                  condensed
                  maxAmountDescription="Available"
                  error={validateUnbondAmount()}
                />
              </div>
            </UnstakePopup>
          </Modal>
        )}

        {isInstantWithdrawModalOpen && (
          <Modal
            showCloseButton={true}
            onCloseClick={() => setInstantWithdrawModalOpen(false)}
          >
            <InstantWithdrawConfirmationPopup
              row={chosenInstantWithdrawRow}
              confirm={() => {
                setInstantWithdrawModalOpen(false);
                withdraw(chosenInstantWithdrawRow);
              }}
              cancel={() => setInstantWithdrawModalOpen(false)}
            />
          </Modal>
        )}

        <div className="staking__columns">
          {/* TABS */}
          <div className="staking__tabs">
            <div>
              {tabs.map((row) => {
                if (stakingType === "mint" && row.name === "second")
                  return null;
                return (
                  <Tabs
                    className="staking__tabs-item"
                    key={row.name}
                    onClick={(item) => {
                      if (row.name === "first") {
                        setStakingType(
                          item.name === "LP Staking" ? "lp" : "mint"
                        );
                        if (item.name === "MINT Staking") {
                          setUnbondingPeriod((prev) =>
                            Math.max(
                              prev,
                              contractConfig.minimum_unbonding_period || 5
                            )
                          );
                        }
                        setLpStakingTab("stake");
                        onTabClick(tabs[1].items[0], "second");
                      } else if (row.name === "second") {
                        setLpStakingTab(item.name.toLowerCase());
                      }
                      onTabClick(item, row.name);
                    }}
                    items={row.items}
                    primary={row.name === "first"}
                  />
                );
              })}
            </div>
            {!(lpStakingTab === "provide" || lpStakingTab === "withdraw") && (
              <div className="staking__chart">
                <SmallTitle text="instant withdrawal fee" />
                <Chart />
              </div>
            )}
          </div>
          {isStackingHidden() ? (
            <>
              {lpStakingTab === "provide" && <ProvideLP />}
              {lpStakingTab === "withdraw" && <WithdrawLP />}
            </>
          ) : (
            <div className="staking__content">
              {/* HEADER */}
              <div className="staking__header">
                <div>
                  <SmallTitle text="stakable" />
                  <Title
                    className="staking__header-value"
                    text={(balances?.[stakingType] / tokensPrecision)?.toFixed(
                      2
                    )}
                  />
                  <SmallTitle text={stakingType === "mint" ? "mint" : "lp"} />
                </div>
                <div>
                  <SmallTitle text="staked" />
                  <Title
                    className="staking__header-value"
                    text={(stakerTotalBondAmount / tokensPrecision).toFixed(2)}
                  />
                  <SmallTitle text={stakingType === "mint" ? "mint" : "lp"} />
                </div>
                <div>
                  <SmallTitle text="reward" />
                  <Title
                    className="staking__header-value"
                    text={pendingReward.toFixed(2)}
                  />
                  <SmallTitle text="mint" />
                </div>
              </div>

              {/* INPUTS */}
              {stakingType === "mint" ? (
                <BalanceInput
                  onChange={balanceFormetterWrapper(setMintInputAmount)}
                  value={mintInputAmount}
                  balance={balances?.mint}
                  placeholderValue={"0,00"}
                  currency="mint"
                  error={balanceValidationWrapper(
                    balances?.mint,
                    mintInputAmount
                  )}
                />
              ) : (
                <>
                  {lpStakingTab === "stake" && (
                    <BalanceInput
                      onChange={balanceFormetterWrapper(setLpInputAmount)}
                      value={lpInputAmount}
                      balance={balances?.lp}
                      placeholderValue={"0,00"}
                      currency="lp"
                      error={balanceValidationWrapper(
                        balances?.lp,
                        lpInputAmount
                      )}
                    />
                  )}
                </>
              )}

              {/* PERIOD */}
              <Period
                className="staking__period"
                onChange={setUnbondingPeriod}
                value={unbondingPeriod}
                min={contractConfig.minimum_unbonding_period || 0}
                max={contractConfig.maximum_unbonding_period || 31}
              />

              {/* DETAILS */}
              <div className="staking__details staking__details--highlighted">
                <div className="staking__details-row">
                  <Text className="staking__detail" text="mint power" />
                  <Text
                    className="staking__value"
                    text={calculateRewardPoints(
                      stakingType === "mint" ? mintInputAmount : lpInputAmount,
                      unbondingPeriod
                    )}
                  />
                </div>
                <div className="staking__details-row">
                  <Text
                    className="staking__detail"
                    text="instant unbonding fee"
                  />
                  <Text
                    className="staking__value"
                    text={`${calculateBurnPercentage(unbondingPeriod)}%`}
                  />
                </div>
                <div className="staking__details-row">
                  <Text className="staking__detail" text="apr" />
                  <div className="staking__apr-container">
                    <Text
                      className="staking__value"
                      text={`${calculateAPR(
                        parseFloat(calculateRewardPoints(1, unbondingPeriod)),
                        1 * (mintPowerMultiplier || 1)
                      )}%`}
                    />
                    <MintLogo className="staking__token-logo" />
                    <Text className="staking__add-sign" text="+" />
                    <Text
                      className="staking__value"
                      text={`${calculateUSTAPR(
                        parseFloat(calculateRewardPoints(1, unbondingPeriod)),
                        1
                      )}%`}
                    />
                    <UstLogo className="staking__token-logo" />
                  </div>
                </div>
                <div className="staking__details-row">
                  <Text className="staking__detail" text="total apr" />
                  <Text
                    className="staking__value"
                    text={`${(
                      parseFloat(
                        calculateAPR(
                          parseFloat(calculateRewardPoints(1, unbondingPeriod)),
                          1 * (mintPowerMultiplier || 1)
                        )
                      ) +
                      parseFloat(
                        calculateUSTAPR(
                          parseFloat(calculateRewardPoints(1, unbondingPeriod)),
                          1
                        )
                      )
                    ).toFixed(2)}%`}
                  />
                </div>
              </div>

              {/* DETAILS */}
              <div className="staking__details">
                <div className="staking__details-row">
                  <Text className="staking__detail" text="Est Tx Fee" />
                  <Text className="staking__value" text="~ 1 UST" />
                </div>
              </div>
              <Button
                disabled={
                  stakingType === "mint"
                    ? !mintInputAmount ||
                      balanceValidationWrapper(balances?.mint, mintInputAmount)
                    : !lpInputAmount ||
                      balanceValidationWrapper(balances?.lp, lpInputAmount)
                }
                onClick={stake}
                variant="contained"
                fullWidth
                className="staking__button"
              >
                Stake
              </Button>
            </div>
          )}
        </div>

        {/* TABLES */}
        {!isStackingHidden() && (
          <div className="staking__tables">
            <Text text="Staking Table" className="staking__table-title" />
            <Table>
              <thead>
                <tr>
                  <th>Unbonding Period</th>
                  <th>Bond Amount</th>
                  <th>Mint Power</th>
                  <th>APR</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {stakingTableRows
                  .filter((row) => row.bonded_amount !== "0")
                  .map((row) => (
                    <tr key={row.unbonding_period}>
                      <td
                        style={{ "--title": "'Unbonding Period'" }}
                      >{`0 Days`}</td>
                      <td style={{ "--title": "'Bond Amount'" }}>{`${(
                        row.bonded_amount / tokensPrecision
                      ).toFixed(2)} ${
                        stakingType === "mint" ? "MINT" : "LP"
                      }`}</td>
                      <td style={{ "--title": "'Mint Power'" }}>
                        {(
                          (row.score_points * mintPowerMultiplier) /
                          tokensPrecision
                        ).toFixed(2)}
                      </td>
                      <td style={{ "--title": "'APR'" }}>{`${calculateAPR(
                        row.score_points,
                        row.bonded_amount
                      )}% MINT + ${calculateUSTAPR(
                        row.score_points * mintPowerMultiplier,
                        row.bonded_amount
                      )}% UST`}</td>
                      <td style={{ "--title": "'Unstake'" }}>
                        <Button
                          onClick={() => unstake(row)}
                          variant="contained"
                          className="table__button"
                        >
                          Unstake
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td>
                    {`${(stakerTotalBondAmount / tokensPrecision).toFixed(2)} ${
                      stakingType === "mint" ? "MINT" : "LP"
                    }`}
                  </td>
                  <td>
                    {(
                      (stakerTotalScorePoints * mintPowerMultiplier) /
                      tokensPrecision
                    ).toFixed(2)}
                  </td>
                  <td>{`${calculateAPR(
                    stakerTotalScorePoints,
                    stakerTotalBondAmount
                  )}% MINT + ${calculateUSTAPR(
                    stakerTotalScorePoints * mintPowerMultiplier,
                    stakerTotalBondAmount
                  )}% UST`}</td>
                  <td />
                </tr>
              </tfoot>
            </Table>

            <div className="staking__details">
              <div className="staking__details-row">
                <Text className="staking__detail" text="Pending Reward" />
                <Text
                  className="staking__value"
                  text={`${pendingReward.toFixed(2)} MINT`}
                />
              </div>
              <div className="staking__details-row">
                <Text className="staking__detail" text="Est Tx Fee" />
                <Text className="staking__value" text="~ 1 UST" />
              </div>
            </div>

            <div className="staking__claim-rewards-container">
              <Button
                disabled={!pendingReward}
                onClick={claim}
                variant="contained"
                className="staking__button staking__claim_rewards_button"
              >
                Claim Rewards
              </Button>
              <Link to="/treasury">
                <Button
                  variant="contained"
                  className="staking__button staking__claim_rewards_button"
                >
                  UST Rewards
                </Button>
              </Link>
            </div>
            <Text text="Unbonding Table" className="staking__table-title" />

            <TableVirtuoso
              className="staking__table"
              useWindowScroll
              totalCount={unbondingTableRows.length + 1}
              components={{
                Table: ({ children, className }) => (
                  <Table className={className}>
                    <thead>
                      <tr>
                        <th>Unbonding Amount</th>
                        <th>Time Left</th>
                        <th>Withdrawal Fee</th>
                        <th />
                      </tr>
                    </thead>
                    {children}
                  </Table>
                ),
              }}
              itemContent={(index) => {
                if (index < unbondingTableRows.length) {
                  const row = unbondingTableRows[index];
                  return (
                    <>
                      <td style={{ "--title": "'Unbonding Amount'" }}>
                        {`${(row.amount / tokensPrecision).toFixed(2)} ${
                          stakingType === "mint" ? "MINT" : "LP"
                        }`}
                      </td>
                      <td style={{ "--title": "'Time Left'" }}>
                        <Timer
                          className="unbonding_table_timer"
                          showDays={true}
                          expiryTimestamp={Date.now()}
                          onExpire={forceUpdate}
                        />
                      </td>
                      <td style={{ "--title": "'Withdrawal Fee'" }}>{`0`}</td>
                      <td style={{ "--title": "'Withdraw'" }}>
                        <Button
                          onClick={() => {
                            setChosenInstantWithdrawRow(row);
                            setInstantWithdrawModalOpen(true);
                          }}
                          variant="contained"
                          className="table__button"
                        >
                          Withdraw
                        </Button>
                      </td>
                    </>
                  );
                }
                return (
                  <>
                    <td style={{ "--title": "'Unbonding Amount'" }}>
                      {`${(
                        stakerTotalUnbondingAmount / tokensPrecision
                      ).toFixed(2)} ${stakingType === "mint" ? "MINT" : "LP"}`}
                    </td>
                    <td />
                    <td style={{ "--title": "'Withdrawal Fee'" }}>{`0`}</td>
                    <td />
                  </>
                );
              }}
            />
          </div>
        )}
      </AppBgLayout>
    </div>
  );
};

export default Staking;
