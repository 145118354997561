import React from "react";
import "./TextInput.scss";

/**
 * @class TextInput
 * @description A text-input in the form.
 */
const TextInput = (props) => {
  const { required, name, value, placeholder, type, handleChange, className } =
    props;
  return (
    <input
      required={required}
      name={name}
      value={value}
      placeholder={placeholder}
      type={type ? type : "text"}
      className={`text-input ${className}`}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

export default TextInput;
