import React, { useEffect, useState } from "react";
import useMintChestsApi from "../../../hooks/useMintChestsApi";
import mintChestUtils from "../../../utils/mintChestsUtils";
import useLeaderboardHooks from "../hooks/LeaderboardHooks";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../../hooks/useTerra";
import utils from "../../../utils/utils";
import leaderboardUtils from "../utils/LeaderboardUtils";
import LeaderboardContainer from "../../../containers/LeaderboardContainer/LeaderboardContainer";

const MintChestLeaderboard = () => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getChestsInfo, getChestTokens } = useMintChestsApi();
  const { fetchCollectionData } = useLeaderboardHooks();
  const [tokenCollection, setTokenCollection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ownedTokensIds, setOwnedTokensIds] = useState([]);
  const [error, setError] = useState(null);
  const [sortMethod, setSortMethod] = useState("pending_reward");
  const [sortDir, setSortDir] = useState("desc");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      await fetchCollectionData(
        getChestsInfo,
        getChestTokens,
        setTokenCollection,
        setOwnedTokensIds,
        setError
      );
    }, 10000);
    return () => clearInterval(intervalId);
  }, [
    connectedWallet,
    network.name,
    getChestsInfo,
    getChestTokens,
    fetchCollectionData,
  ]);

  const onHeaderClick = (columnName) => {
    if (sortMethod === columnName) {
      if (sortDir === "asc") {
        setSortDir("desc");
      } else {
        setSortDir("asc");
      }
    }

    setSortMethod(columnName);
  };

  const headersArr = [
    { key: "type", title: "Type" },
    { key: "token_id", title: "Chest ID" },
    { key: "power", title: "Power" },
    { key: "pending_reward", title: "tokens inside" },
  ];

  const rowData = (props, index, tokenData) => {
    const tokenReward = tokenData.pending_reward / 1_000_000;
    const tokenId = tokenData.token_id;
    const chestPower = tokenData.power;
    const chestAdditionalInfo = mintChestUtils.getChestByPower(chestPower);

    return (
      <tr {...props}>
        <td style={{ "--title": "'No.'" }}>{index + 1}</td>
        <td style={{ "--title": "'Type'" }}>{`${chestAdditionalInfo.type}`}</td>
        <td style={{ "--title": "'Chest ID'" }}>{`#${tokenId}`}</td>
        <td style={{ "--title": "'Power'" }}>{`${chestPower}`}</td>
        <td style={{ "--title": "'Tokens inside'" }}>
          {`${utils.addSeparatorsToNumber(
            Number.parseFloat(tokenReward).toFixed(2)
          )}`}
        </td>
      </tr>
    );
  };

  return (
    <LeaderboardContainer
      collectionName="Mint Chests"
      renderRow={leaderboardUtils.renderRow(
        tokenCollection,
        filter,
        rowData,
        sortMethod,
        sortDir
      )}
      renderListHeaders={leaderboardUtils.renderListHeaders(
        headersArr,
        sortMethod,
        sortDir,
        onHeaderClick
      )}
      tokenCollection={tokenCollection}
      error={error}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default MintChestLeaderboard;
