import "./Circle.scss";

const Circle = ({ className, text }) => {
  return (
    <div className="circle__item">
      <div className={`circle__percent ${className}`}>{text}</div>
    </div>
  );
};

export default Circle;
