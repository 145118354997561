import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";
import useGenericCollectionHooks from "./GenericHooks/useGenericCollectionHooks";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { useConnectedWallet } from "@terra-money/use-wallet";

export const useThorstarterShieldsApi = () => {
  const { network } = useTerra();
  const { queryContract, executeContractAndWaitForResult } = useContractApi();
  const connectedWallet = useConnectedWallet();

  const getThorstarterShieldsTokens = useCallback(
    async (params) => {
      try {
        const cw721Limit = 30;
        let { owner, startAfter, limit } = params;
        let response = [];
        let chestsIds = [];
        do {
          const query = {
            owner,
            start_after: startAfter,
            limit,
          };
          if (response.length > 0) {
            query.start_after = response[response.length - 1];
          }
          const result = await queryContract(
            contractAddresses[network.name].thorstarterShields,
            { tokens: query }
          );
          chestsIds = result.tokens;
          limit -= chestsIds.length;
          response = response.concat(chestsIds);
        } while (limit > 0 && chestsIds.length === cw721Limit);
        if (!response || typeof response !== "object") {
          return null;
        }
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );
  const getThorstarterShieldsInfo = useCallback(
    async (params) => {
      try {
        const { tokenIds = [], startAfter, limit } = params;
        const response = await queryContract(
          contractAddresses[network.name].thorstarterShields,
          {
            get_tokens_by_ids: {
              token_ids: tokenIds,
              start_after: startAfter,
              limit,
            },
          }
        );
        if (!response || typeof response !== "object") {
          return null;
        }
        return response.tokens.sort(
          (a, b) => parseInt(a.token_id) - parseInt(b.token_id)
        );
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );

  
  const getAllThorstarterShieldsInfo = useCallback(
    async (params) => {
      try {
        const cw721Limit = 100;
        let { startAfter, limit } = params;
        let allShields = [];
        let shields = [];
        do {
          const query = {
            token_ids: [],
            start_after: startAfter,
            limit: cw721Limit,
          };
          if (allShields.length > 0) {
            query.start_after = allShields[allShields.length - 1].token_id;
          }
          const result = await queryContract(
            contractAddresses[network.name].thorstarterShields,
            {
              get_tokens_by_ids: query,
            }
          );
          shields = result.tokens;
          limit -= shields.length;
          allShields = allShields.concat(shields);
        } while (limit > 0 && shields.length === cw721Limit);

        if (!allShields || typeof allShields !== "object") {
          return null;
        }
        return allShields;
      } catch (err) {
        console.log(err); 
      }
      return null;
    },
    [queryContract, network.name]
  );
  return {
    getWhitelistedAddresses: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].thorstarterShields,
          { get_whitelisted_addresses: { limit: 1000 } }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getNftInfo: useCallback(
      async (tokenId) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].thorstarterShields,
            { nft_info: { token_id: tokenId } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getOwner: useCallback(
      async (tokenId) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].thorstarterShields,
            { owner_of: { token_id: tokenId } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getState: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].thorstarterShields,
          { get_state: {} }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getConfig: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].thorstarterShields,
          { get_config: {} }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),

    getXruneTokenBalance: useCallback(
      async (connectedWalletAddress, contractAddress) => {
        try {
          const response = await queryContract(contractAddress, {
            balance: { address: connectedWalletAddress },
          });
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract]
    ),
    getNftBurnings: useCallback(
      async (tokenId, from, to) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].thorstarterShields,
            { get_nft_burnings: { token_id: tokenId, from, to } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [network.name, queryContract]
    ),
    burnXruneTx: useCallback(
      async (tokenId, xruneAmount, contractConfig, setTxInfo, setTxError) => {
        let msgs = [];

        msgs.push(
          new MsgExecuteContract(
            connectedWallet?.walletAddress,
            contractConfig?.xrune_token_address,
            {
              increase_allowance: {
                amount: xruneAmount,
                spender: contractAddresses[network.name].thorstarterShields,
              },
            },
            {}
          )
        );

        msgs.push(
          new MsgExecuteContract(
            connectedWallet?.walletAddress,
            contractAddresses[network.name].thorstarterShields,
            {
              burn_xrune: {
                token_id: tokenId,
                amount_to_burn: xruneAmount,
              },
            },
            { uusd: contractConfig?.harvest_fee }
          )
        );

        return executeContractAndWaitForResult(msgs, setTxInfo, setTxError);
      },
      [
        connectedWallet?.walletAddress,
        executeContractAndWaitForResult,
        network.name,
      ]
    ),
    setEthAddressTx: useCallback(
      async (tokenId, ethAddress, setTxInfo, setTxError) => {
        let msgs = [];

        msgs.push(
          new MsgExecuteContract(
            connectedWallet?.walletAddress,
            contractAddresses[network.name].thorstarterShields,
            {
              update_eth_address: {
                token_id: tokenId,
                eth_address: ethAddress,
              },
            },
            {  }
          )
        );

        return executeContractAndWaitForResult(msgs, setTxInfo, setTxError);
      },
      [
        connectedWallet?.walletAddress,
        executeContractAndWaitForResult,
        network.name,
      ]
    ),
    getThorstarterShieldsTokens,
    getThorstarterShieldsInfo,
    getAllThorstarterShieldsInfo,
    ...useGenericCollectionHooks(
      getThorstarterShieldsTokens,
      getThorstarterShieldsInfo,
      1000,
      10000
    ),
  };
};

export default useThorstarterShieldsApi;
