import "./Footer.scss";
import { ReactComponent as DiscordIcon } from "./discord.svg";
import { ReactComponent as MediumIcon } from "./medium.svg";
import { ReactComponent as TwitterIcon } from "./twitter.svg";
import Container from "../Container/Container";
import Text from "../Text/Text";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <Container className="footer__container">
        <ul className="footer__icons">
          <li className="footer__icon">
            <a
              href="https://discord.gg/BRF2PEetea"
              target="_blank"
              rel="noreferrer"
            >
              <DiscordIcon />
            </a>
          </li>
          <li className="footer__icon">
            <a
              href="https://medium.com/@mintdao"
              target="_blank"
              rel="noreferrer"
            >
              <MediumIcon />
            </a>
          </li>
          <li className="footer__icon">
            <a
              href="https://twitter.com/mintdao_io"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </li>
        </ul>

        <div className="footer__wrapper">
          <Text
            text="© Copyright MintDAO. All Rights Reserved"
            className="footer__wrapper__copy"
          />
          <div className="footer__wrapper__divider" />
          <div className="footer__wrapper__links text">
            <Link to="vesting">Vesting</Link>
            <Link to="airdrop">Airdrop</Link>
            <a
              href="https://mintdao.io/PRIVACY_POLICY.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              href="https://mintdao.io/TERMS_OF_USE.pdf"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
