import common from "../assets/images/SpaceCartelsAmulets/common.png";
import epic from "../assets/images/SpaceCartelsAmulets/epic.png";
import mythic from "../assets/images/SpaceCartelsAmulets/mythic.png";
import minting from "../assets/images/chests/minting.jpg";
import Text from "../components/Text/Text";

const spaceCartelsAmuletsUtils = {
  getSpaceCartelsAmuletsByTokenInfo: (rarity) => {
    const key = `${rarity}`;
    const propertyInfoMap = {
      Common: {
        image: common,
        rarity,
        utilityComponent: commonUtilityComponent,
      },
      Epic: { image: epic, rarity, utilityComponent: epicUtilityComponent },
      Mythic: {
        image: mythic,
        rarity,
        utilityComponent: mythicUtilityComponent,
      },
    };

    return (
      propertyInfoMap[key] || {
        image: minting,
        type: "minting",
        name: "Minting...",
        utilityComponent: mintingUtilityComponent,
      }
    );
  },
  calculateBoostIn: (lastTransferredAttr) => {
    const timeFromLastTransfer =
      new Date().getTime() - lastTransferredAttr * 1000;

    const boostUpgradePeriod = 4 * 7 * 24 * 60 * 60 * 1000;
    return new Date(
      new Date().getTime() +
        (boostUpgradePeriod - (timeFromLastTransfer % boostUpgradePeriod))
    );
  },
};

const mintingUtilityComponent = (
  <div className="collection__description">
    <Text text="..." />
  </div>
);

const commonUtilityComponent = (
  <div className="collection__description">
    <ul className="collection__description-benefits">
      <li>
        <Text text="<span>4% more</span> DMT from each Daily Quest completed." />
      </li>
      <li>
        <Text text="Each 4 weeks of holding gives <span>0.2% more</span> power*." />
      </li>
      <li>
        <Text text="Access to a special group on Discord." />
      </li>
      <li>
        <Text text="MintDAO Yield Farming." />
      </li>
      <li>
        <Text text="NFT holders will get an extra chance for future whitelist allocations." />
      </li>
      <li>
        <Text text="Early access to Space Cartels for testing." />
      </li>
    </ul>
    <Text
      className="collection__disclaimer"
      text="*Only for the first 24 weeks after the collection is minted."
    ></Text>
  </div>
);

const epicUtilityComponent = (
  <div className="collection__description">
    <ul className="collection__description-benefits">
      <li>
        <Text text="<span>6% more</span> DMT from each Daily Quest completed." />
      </li>
      <li>
        <Text text="Each 4 weeks of holding gives <span>0.3% more</span> power*." />
      </li>
      <li>
        <Text text="SPC Whitelist allocation for <span>$140</span>." />
      </li>
      <li>
        <Text text="Access to a special group on Discord." />
      </li>
      <li>
        <Text text="MintDAO Yield Farming." />
      </li>
      <li>
        <Text text="NFT holders will get an extra chance for future whitelist allocations." />
      </li>
      <li>
        <Text text="Early access to Space Cartels for testing." />
      </li>
    </ul>
    <Text
      className="collection__disclaimer"
      text="*Only for the first 24 weeks after the collection is minted."
    ></Text>
  </div>
);

const mythicUtilityComponent = (
  <div className="collection__description">
    <ul className="collection__description-benefits">
      <li>
        <Text text="<span>10% more</span> DMT from each Daily Quest completed." />
      </li>
      <li>
        <Text text="Each 4 weeks of holding gives <span>0.4% more</span> power*." />
      </li>
      <li>
        <Text text="SPC Whitelist allocation for <span>$280</span>." />
      </li>
      <li>
        <Text text="Access to a special group on Discord." />
      </li>
      <li>
        <Text text="MintDAO Yield Farming." />
      </li>
      <li>
        <Text text="NFT holders will get an extra chance for future whitelist allocations." />
      </li>
      <li>
        <Text text="Early access to Space Cartels for testing." />
      </li>
    </ul>
    <Text
      className="collection__disclaimer"
      text="*Only for the first 24 weeks after the collection is minted."
    ></Text>
  </div>
);

export default spaceCartelsAmuletsUtils;
