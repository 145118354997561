import "./RoboheroAvatar.scss";
import Text from "../../../components/Text/Text";
import Title from "../../../components/Title/Title";
import SmallTitle from "../../../components/SmallTitle/SmallTitle";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NftContainer from "../../../containers/NftContainer/NftContainer";
import roboheroAvatarsUtils from "../../../utils/roboheroAvatarsUtils";
import useRoboheroApi from "../../../hooks/useRoboheroApi";

const RoboheroAvatar = () => {
  const { tokenId } = useParams();
  const { state } = useLocation();
  const { getNftInfo } = useRoboheroApi();
  const [nftInfo, setNftInfo] = useState(
    roboheroAvatarsUtils.getRoboheroByTokenInfo(state?.faction, state?.rarity)
  );

  useEffect(() => {
    const init = async () => {
      const contractQuery = await getNftInfo(tokenId);
      const attr = contractQuery?.extension?.attributes;
      setNftInfo({
        rarity: attr[0].value,
        faction: attr[1].value,
        mintReward: attr[2].value,
        ...roboheroAvatarsUtils.getRoboheroByTokenInfo(
          attr[1].value,
          attr[0].value
        ),
      });
    };

    init();
  }, [tokenId, getNftInfo]);

  const image = <img src={nftInfo?.image} alt={nftInfo?.rarity} />;

  const children = (
    <>
      <Title
        className="robohero-avatar__name"
        text={`${nftInfo?.rarity} Robohero Avatar`}
      />

      <SmallTitle text="description" />
      <div className="collection__description">
        <Text text={`This robohero avatar has the following utilities:`} />

        <ul className="robohero-avatar__description-benefits">
          <li>
            <Text text="MintDAO Yield Farming "></Text>
          </li>
          <li>
            <Text text="Access to a secret group on discord RoboHero"></Text>
          </li>
          <li>
            <Text text="NFT airdrop for the RoboHero game"></Text>
          </li>
          <li>
            <Text text="Chance for WL for RoboHero lands NFTs"></Text>
          </li>
          <li>
            <Text text="Participation in the drawing of the golden pass (premium account)"></Text>
          </li>
        </ul>
      </div>

      <p className="robohero-avatar__info">
        ID: <span>#{tokenId}</span>
      </p>
      <p className="robohero-avatar__info">
        Rarity: <span>{nftInfo?.rarity}</span>
      </p>
      <p className="robohero-avatar__info">
        Faction: <span>{nftInfo?.faction}</span>
      </p>
    </>
  );

  return (
    <NftContainer
      tokenId={tokenId}
      image={image}
      imagePath={nftInfo?.image}
      children={children}
      title="Robohero Avatars"
      harvestFee={state?.harvestFee}
      contractAddress={state?.contractAddress}
      harvestReward={nftInfo?.mintReward}
    />
  );
};

export default RoboheroAvatar;
