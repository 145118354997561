import { useCallback } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useVestingApi = () => {
  const { network } = useTerra();
  const connectedWallet = useConnectedWallet();
  const { queryContract, executeContractAndWaitForResult } = useContractApi();
  const vestingContractAddress =
    contractAddresses[network.name].privateSaleVesting;
  return {
    getVestingAccount: useCallback(
      async (address) => {
        try {
          const response = await queryContract(vestingContractAddress, {
            vesting_account: {
              address,
            },
          });
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, vestingContractAddress]
    ),
    claimTokens: useCallback(
      async (setLoading, setTxInfo, setTxError) => {
        try {
          setLoading(true);
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              vestingContractAddress,
              {
                claim: {},
              },
              { uusd: 1000000 }
            ),
          ];

          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          setLoading(false);

          console.log("response", response);
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        executeContractAndWaitForResult,
        vestingContractAddress,
      ]
    ),
  };
};

export default useVestingApi;
