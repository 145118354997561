import { useEffect, useState, useCallback } from "react";
import useContractApi from "./useContractApi";
import useMintTokenApi from "./useMintTokenApi";
import { useConnectedWallet } from "@terra-money/wallet-provider";
const useBalances = () => {
  const [balances, setBalances] = useState({
    lp: 0,
    mint: 0,
    uluna: 0,
    uusd: 0,
  });
  const { getNativeTokensBalances } = useContractApi();
  const { getMintTokenBalance, getLpTokenBalance } = useMintTokenApi();
  const connectedWallet = useConnectedWallet();

  const nativeTokens = useCallback(async () => {
    const response = await getNativeTokensBalances(
      connectedWallet?.walletAddress
    );
    const nativeBalances = response.reduce((prev, curr) => {
      prev[curr.denom] = parseFloat(curr.balance);
      return prev;
    }, {});
    setBalances((prev) => ({ ...prev, ...nativeBalances }));
  }, [connectedWallet?.walletAddress, getNativeTokensBalances]);

  const mintTokens = useCallback(async () => {
    const response = await getMintTokenBalance(connectedWallet?.walletAddress);
    setBalances((prev) => ({ ...prev, mint: parseFloat(response.balance) }));
  }, [connectedWallet?.walletAddress, getMintTokenBalance]);

  const lpTokens = useCallback(async () => {
    const response = await getLpTokenBalance(connectedWallet?.walletAddress);
    setBalances((prev) => ({ ...prev, lp: parseFloat(response.balance) }));
  }, [connectedWallet?.walletAddress, getLpTokenBalance]);

  const updateBalances = useCallback(() => {
    if (connectedWallet?.walletAddress) {
      nativeTokens();
      mintTokens();
      lpTokens();
    } else {
      setBalances({
        lp: 0,
        mint: 0,
        uluna: 0,
        uusd: 0,
      });
    }
  }, [connectedWallet?.walletAddress, nativeTokens, mintTokens, lpTokens]);

  useEffect(() => {
    updateBalances();
  }, [updateBalances]);

  return { balances, updateBalances };
};

export default useBalances;
