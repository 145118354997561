import "./ThorstarterShield.scss";
import SmallTitle from "../../../components/SmallTitle/SmallTitle";
import React, { useCallback, useEffect, useState } from "react";
import ThorstarterShieldsUtils from "../../../utils/ThorstarterShieldsUtils";
import NftContainer from "../../../containers/NftContainer/NftContainer";
import Text from "../../../components/Text/Text";
import Circle from "../../../components/Circle/Circle";
import BalanceInput from "../../../components/BalanceInput/BalanceInput";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import { useLocation, useParams } from "react-router";
import useThorstarterShieldsApi from "../../../hooks/useThorstarterShieldsApi";
import utils from "../../../utils/utils";
import { useConnectedWallet } from "@terra-money/use-wallet";
import TxModal from "../../../components/TxModal/TxModal";
import Table from "../../../components/Table/Table";
import Timer from "../../../components/Timer/Timer";

const ThorstarterShield = () => {
  const { tokenId } = useParams();
  const { state } = useLocation();

  const connectedWallet = useConnectedWallet();

  const {
    getNftInfo,
    getOwner,
    getState,
    getConfig,
    getNftBurnings,
    getXruneTokenBalance,
    burnXruneTx,
    setEthAddressTx,
  } = useThorstarterShieldsApi();

  const tokensPrecision = 1000000;
  const [xruneTokensSelectedAmount, setXruneTokensSelectedAmount] =
    useState("");
  const [xruneTokensInputError, setXruneTokensInputError] = useState(null);
  const [ethAddress, setEthAddress] = useState("");
  const [owner, setOwner] = useState("");
  const [nftInfo, setNftInfo] = useState(
    ThorstarterShieldsUtils.getThorstarterShieldsByTokenInfo(
      state?.selectedLevel
    )
  );
  const [totalXruneBurned, setTotalXruneBurned] = useState(0);
  const [contractConfig, setContractConfig] = useState(null);
  const [nftBurnings, setNftBurnings] = useState([]);
  const [totalActiveBurnings, setTotalActiveBurnings] = useState(0);
  const [xruneBalance, setXruneBalance] = useState(0);
  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onXruneValueChangeWrapper = (inputAmount, setMax = false) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    const maxPossibleXruneToBurn =
      +contractConfig?.weekly_burn_limit - totalActiveBurnings;

    if (setMax) {
      amount = Math.min(maxPossibleXruneToBurn / tokensPrecision, amount);
    }

    setXruneTokensSelectedAmount(amount);

    let error = null;
    if (amount * tokensPrecision > xruneBalance) {
      error = "Invalid funds";
    }
    if (
      contractConfig?.weekly_burn_limit &&
      amount * tokensPrecision > maxPossibleXruneToBurn
    ) {
      error = "Max burn amount exceeded";
    }

    setXruneTokensInputError(error);
  };

  const burnXruneCall = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    const xruneAmount = Math.floor(
      xruneTokensSelectedAmount * tokensPrecision
    ).toString();
    setLoading(true);
    const txResult = await burnXruneTx(
      tokenId,
      xruneAmount,
      contractConfig,
      setTxInfo,
      setTxError
    );
    setLoading(false);
    console.log(txResult);
    setXruneTokensSelectedAmount("");
    updateState();
  };

  const setEthAddressCall = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    setLoading(true);
    const txResult = await setEthAddressTx(
      tokenId,
      ethAddress,
      setTxInfo,
      setTxError
    );
    setLoading(false);
    console.log(txResult);
    updateState();
  };

  const getNftInfoCall = useCallback(async () => {
    const contractQuery = await getNftInfo(tokenId);
    const attr = contractQuery?.extension?.attributes;
    if (attr) {
      setNftInfo({
        level: attr[0].value,
        ethAddress: attr[1].value,
        totalBurnedXrune: attr[2].value,
        lastWeekBurnedXrune: attr[3].value,
        baseBoost: attr[4].value,
        totalBoost: attr[6].value,
        mintReward: attr[7].value,
        ...ThorstarterShieldsUtils.getThorstarterShieldsByTokenInfo(
          attr[0].value
        ),
      });
    }
  }, [getNftInfo, tokenId]);

  const getNftOwnerCall = useCallback(async () => {
    const contractQuery = await getOwner(tokenId);
    if (contractQuery) {
      setOwner(contractQuery.owner);
    }
  }, [getOwner, tokenId]);

  const getNextLevelAmount = () => {
    let xrune_needed = 0;
    if (!contractConfig?.levels_data || !nftInfo?.totalBurnedXrune) {
      return xrune_needed;
    }
    const levels = contractConfig?.levels_data;
    levels.sort((a, b) =>
      +a.xrune_burn_needed > +b.xrune_burn_needed ? 1 : -1
    );

    for (let level of levels) {
      if (+level.xrune_burn_needed > nftInfo?.totalBurnedXrune) {
        xrune_needed = +level.xrune_burn_needed - +nftInfo?.totalBurnedXrune;
        break;
      }
    }

    return xrune_needed;
  };

  const getTotalXRuneBurnedCall = useCallback(async () => {
    const contractState = await getState();
    if (contractState) {
      setTotalXruneBurned(contractState.total_xrune_burned);
    }
  }, [getState]);

  const getConfigCall = useCallback(async () => {
    const contractConfig = await getConfig();
    if (contractConfig) {
      setContractConfig(contractConfig);
      // get balance in xrune
      const xruneAddress = contractConfig.xrune_token_address;
      const balanceResponse = await getXruneTokenBalance(
        connectedWallet?.walletAddress,
        xruneAddress
      );
      if (balanceResponse) {
        setXruneBalance(balanceResponse.balance);
      }
    }
  }, [connectedWallet?.walletAddress, getConfig, getXruneTokenBalance]);

  const getNftBurningsCall = useCallback(async () => {
    // Timestamp 7 days ago in nano
    const from =
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime() * 1000000;

    const nftBurnings = await getNftBurnings(tokenId, from.toString());
    if (nftBurnings) {
      setNftBurnings(nftBurnings);
      const sum = nftBurnings.reduce(
        (previousValue, currentValue) =>
          previousValue + +currentValue.burned_amount,
        0
      );

      setTotalActiveBurnings(sum);
    }
  }, [getNftBurnings, tokenId]);

  const updateState = useCallback(async () => {
    getNftInfoCall();
    getNftOwnerCall();
    getTotalXRuneBurnedCall();
    getConfigCall();
    getNftBurningsCall();
  }, [
    getConfigCall,
    getNftBurningsCall,
    getNftInfoCall,
    getNftOwnerCall,
    getTotalXRuneBurnedCall,
  ]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  const image = <img src={nftInfo?.image} alt={"nft"} />;

  const OwnedByComponent = (
    <>
      <Text
        text={`Owned by <span>${utils.shortAddress(owner)}</span>`}
        className="thorstarter-shields__before-title"
      />
    </>
  );

  const children = (
    <>
      <SmallTitle
        text="description"
        className="thorstarter-shields__description"
      />
      <div>
        <Text text="Each shield allows you to receive a Thorstarter IDO allocation booster in exchange for burning XRUNE tokens." />
      </div>

      <div className="thorstarter-shields__title-section">
        <SmallTitle
          text="Your level"
          className="thorstarter-shields__title-section-name"
        />
        <div className="thorstarter-shields__separator" />
      </div>

      <div className="thorstarter-shields__level">
        <Circle text={nftInfo?.levelNumber} />
        <div className="thorstarter-shields__level-description">
          <Text
            text={`${utils.printAmount(
              nftInfo?.totalBurnedXrune
            )} <span>XRUNE</span> total burned by you`}
            className="thorstarter-shields__level-description-line"
          />
          <Text
            text={`${utils.printAmount(
              getNextLevelAmount()
            )} <span>XRUNE</span> to burn until next level`}
            className="thorstarter-shields__level-description-line"
          />
        </div>
      </div>

      <div className="thorstarter-shields__separator" />

      <div className="thorstarter-shields__total-burn">
        <SmallTitle
          text="Collection total burn"
          className="thorstarter-shields__total-burn-title"
        />
        <Text
          className="thorstarter-shields__total-burn-line"
          text={`${utils.printAmount(totalXruneBurned)} <span>XRUNE</span>`}
        />
      </div>

      <div className="thorstarter-shields__separator" />

      <div className="thorstarter-shields__score-points">
        <div className="thorstarter-shields__score-points-info">
          <SmallTitle
            text="Your Basic score points"
            className="thorstarter-shields__score-points-title"
          />
          <Text
            className="thorstarter-shields__score-points-line"
            text={`${utils.printAmount(
              nftInfo?.baseBoost
            )} <span>points</span> `}
          />
        </div>
        <div className="thorstarter-shields__separator" />

        <div className="thorstarter-shields__score-points-info">
          <SmallTitle
            text="Total score points"
            className="thorstarter-shields__score-points-title"
          />
          <Text
            className="thorstarter-shields__score-points-line"
            text={`${utils.printAmount(
              +nftInfo?.baseBoost +
                totalActiveBurnings * +contractConfig?.burning_boost_multiplier
            )} <span>points</span> `}
          />
        </div>
        <div className="thorstarter-shields__burn-table">
          <Table>
            <thead>
              <tr>
                <th>Burned XRUNE</th>
                <th>Expires in</th>
                <th>Score points</th>
              </tr>
            </thead>
            <tbody>
              {nftBurnings.map((item, index) => (
                <tr key={`burning__${index}`}>
                  <td style={{ "--title": "'Burned XRUNE'" }}>
                    {utils.printAmount(item.burned_amount)}
                  </td>
                  <td style={{ "--title": "'Time left'" }}>
                    <Timer
                      className="thorstarter-shields__timer"
                      expiryTimestamp={
                        new Date(
                          item.burning_time / 1000000 + 7 * 24 * 60 * 60 * 1000
                        )
                      }
                      onExpire={updateState}
                      showDays
                    />
                  </td>
                  <td style={{ "--title": "'Score points'" }}>
                    {utils.printAmount(
                      item.burned_amount *
                        +contractConfig?.burning_boost_multiplier
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        {owner === connectedWallet?.walletAddress && (
          <>
            <BalanceInput
              customCurrency="Xrune to burn"
              balance={xruneBalance}
              onChange={onXruneValueChangeWrapper}
              placeholderValue={0}
              value={xruneTokensSelectedAmount}
              error={xruneTokensInputError}
            />

            <Button
              variant="contained"
              className="thorstarter-shields__button"
              fullWidth
              onClick={burnXruneCall}
              disabled={
                !contractConfig?.xrune_token_address ||
                !Math.floor(xruneTokensSelectedAmount * tokensPrecision)
              }
            >
              Burn
            </Button>
            <Text
              className="thorstarter-shields__fee-info"
              text="Burning fee 1 UST."
            />
          </>
        )}
      </div>
      {owner === connectedWallet?.walletAddress && (
        <>
          <div className="thorstarter-shields__separator" />

          <div className="thorstarter-shields__eth-address">
            <Text
              className="thorstarter-shields__eth-address-text"
              text={`Current ETH address: ${
                nftInfo?.ethAddress
                  ? utils.shortAddress(nftInfo?.ethAddress)
                  : "None"
              }
            `}
            />
            <TextInput
              placeholder="Set Your ETH Address…"
              handleChange={(value) => setEthAddress(value)}
              value={ethAddress}
            />
            {ethAddress !== "" && !/^0x[a-fA-F0-9]{40}$/.test(ethAddress) && (
              <Text
                className="thorstarter-shields__eth-address-error"
                text={`Provided address is invalid`}
              />
            )}
            <Button
              variant="contained"
              className="thorstarter-shields__button"
              fullWidth
              onClick={setEthAddressCall}
              disabled={!/^0x[a-fA-F0-9]{40}$/.test(ethAddress)}
            >
              Set ETH Address
            </Button>
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      {isModalOpen && (
        <TxModal
          loading={loading}
          txError={txError}
          setModalOpen={setModalOpen}
          txInfo={txInfo}
          connectedWallet={connectedWallet}
        />
      )}
      <NftContainer
        tokenId={tokenId}
        image={image}
        imagePath={nftInfo?.image}
        children={children}
        title={`Thorstarter Shield`}
        beforeTitle={OwnedByComponent}
        congratulations={state?.congratulations}
        collectionSalePage="/collections/thorstarter-shields"
        harvestFee={state?.harvestFee}
        contractAddress={state?.contractAddress}
        harvestReward={nftInfo?.mintReward}
      />
    </>
  );
};

export default ThorstarterShield;
