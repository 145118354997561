import React from "react";
import "./ConfirmationPopup.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { ReactComponent as Icon } from "./confirmIcon.svg";
// import CheckboxInput from "../CheckboxInput/CheckboxInput";

const ConfirmationPopup = ({ onConfirm, onCancel }) => {
  // const [formData, setFormData] = useState({
  //   consent: false,
  // });

  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup__title-container">
        <Text className="confirmation-popup__title" text="Confirmation" />
        <Icon className="confirmation-popup__icon" />
      </div>

      <div className="confirmation-popup__gradient-container">
        <div className="confirmation-popup__scroll-container">
          <Text text="<span>PLEASE READ THE BELOW NOTICE CAREFULLY AS IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS TOWARDS CLAIMING THE REWARDS RESULTING FROM THE NFT COLLECTION AS A PART OF YIELD FARMING FEATURE VAILABLE THROUGH THE PLATFORM.</span><br /><br />NOTICE: THE NFT COLLECTION CALLED “PlayNity The Genesis” IS GOVERNED BY THE REWARD STRATEGY WHICH INVOLVES DISTRIBUTION OF AROUND 150000 MINT TOKENS TO ALL NFT HOLDERS FROM THAT COLLECTION OVER TWO YEARS ON TERRA BLOCKCHAIN TO ALL EXISTING NFTS AT THE MOMENT OF BLOCK MINTING PROPORTIONALLY TO THEIR ALLOCATION POINTS ATTRIBUTE. ASSIGNING MINT TOKENS TO NFTS ORIGINATING FROM THE GIVEN NFT COLLECTION STARTS WITH THE FIRST BLOCK MINTED AFTER 05/05/2022 3PM UTC AND LASTS FOR TWO YEARS." />{" "}
        </div>
      </div>
      <div className="confirmation-popup__container-confirmation-item">
        {/* <div>
          <CheckboxInput
            id="consent1"
            required={true}
            checked={formData.consent}
            handleChange={(consent1) =>
              setFormData({ ...formData, consent: consent1 })
            }
          />
        </div> */}
        <label
          htmlFor="consent1"
          className="confirmation-popup__confirmation-text"
        >
          I hereby acknowledge and agree on the reward strategy related to the
          NFT Collection called the “PlayNity The Genesis”
        </label>
      </div>

      <footer className="confirmation-popup__footer">
        <Button
          onClick={onConfirm}
          variant="contained"
          // disabled={!formData.consent}
        >
          Confirm
        </Button>
      </footer>
    </div>
  );
};

export default ConfirmationPopup;
