import React from "react";
import "./Button.scss";

import { ReactComponent as ButtonBorder } from "./buttonBorder.svg";

const Button = (props) => {
  const {
    variant,
    disabled,
    children,
    type,
    value,
    fullWidth,
    onClick,
    className,
  } = props;

  return (
    <button
      type={type ? type : null}
      value={value ? value : null}
      className={`btn btn-${variant}${disabled ? "-disabled" : ""} ${
        fullWidth ? "btn--full-width" : ""
      } ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {variant === "outlined" && <ButtonBorder />}
      {children}
    </button>
  );
};

export default Button;
