import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useUSTRewardsApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  const ustRewardsContractAddress = contractAddresses[network.name].ustRewards;
  return {
    getVestingAccount: useCallback(
      async (address) => {
        try {
          const response = await queryContract(ustRewardsContractAddress, {
            staker_info: { staker: address },
          });
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, ustRewardsContractAddress]
    ),
    getState: useCallback(async () => {
      try {
        const response = await queryContract(ustRewardsContractAddress, {
          state: {},
        });
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, ustRewardsContractAddress]),
    getConfig: useCallback(async () => {
      try {
        const response = await queryContract(ustRewardsContractAddress, {
          config: {},
        });
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, ustRewardsContractAddress]),

    getAllStakersInfo: useCallback(
      async (params) => {
        try {
          const queryLimit = 30;
          let { startAfter, limit = queryLimit } = params;
          let response = [];
          do {
            const query = {
              start_after: startAfter,
              limit,
            };

            if (response.length > 0) {
              query.start_after = response[response.length - 1]["staker"];
            }

            const result = await queryContract(ustRewardsContractAddress, {
              stakers_info: query,
            });

            const stakers = result.stakers;
            if (stakers.length === 0) {
              break;
            }
            response = response.concat(stakers);
          } while (limit > 0);
          if (!response || typeof response !== "object") {
            return null;
          }
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, ustRewardsContractAddress]
    ),
  };
};

export default useUSTRewardsApi;
