import React, { useEffect, useState } from "react";
import useLeaderboardHooks from "../hooks/LeaderboardHooks";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../../hooks/useTerra";
import utils from "../../../utils/utils";
import leaderboardUtils from "../utils/LeaderboardUtils";
import LeaderboardContainer from "../../../containers/LeaderboardContainer/LeaderboardContainer";
import useThorstarterShieldsApi from "../../../hooks/useThorstarterShieldsApi";
import { useNavigate } from "react-router-dom";

const ThorstarterShieldLeaderboard = () => {
  const navigate = useNavigate();
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getThorstarterShieldsTokens, getAllThorstarterShieldsInfo } =
    useThorstarterShieldsApi();
  const { fetchCollectionData } = useLeaderboardHooks();
  const [tokenCollection, setTokenCollection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ownedTokensIds, setOwnedTokensIds] = useState([]);
  const [error, setError] = useState(null);
  const [sortMethod, setSortMethod] = useState("pending_reward");
  const [sortDir, setSortDir] = useState("desc");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      await fetchCollectionData(
        getAllThorstarterShieldsInfo,
        getThorstarterShieldsTokens,
        setTokenCollection,
        setOwnedTokensIds,
        setError,
        500
      );
    }, 10000);
    // set default sorting key
    setSortMethod("total_xrune_score_points");
    return () => clearInterval(intervalId);
  }, [
    connectedWallet,
    network.name,
    getAllThorstarterShieldsInfo,
    getThorstarterShieldsTokens,
    fetchCollectionData,
  ]);

  const onHeaderClick = (columnName) => {
    if (sortMethod === columnName) {
      if (sortDir === "asc") {
        setSortDir("desc");
      } else {
        setSortDir("asc");
      }
    }

    setSortMethod(columnName);
  };

  const headersArr = [
    { key: "token_id", title: "Shield ID" },
    { key: "burned_xrune", title: "Burned XRUNE" },
    { key: "base_xrune_score_points", title: "Base Score Points" },
    { key: "total_xrune_score_points", title: "Total Score Points" },
    { key: "pending_reward", title: "tokens inside" },
  ];

  const rowData = (props, index, tokenData) => {
    const tokenReward = tokenData.pending_reward / 1_000_000;
    const tokenId = tokenData.token_id;
    const burned_xrune = tokenData.burned_xrune;
    const base_xrune_score_points = tokenData.base_xrune_score_points;
    const total_xrune_score_points = tokenData.total_xrune_score_points;

    return (
      <tr
        {...props}
        onClick={() => {
          navigate(`/collections/thorstarter-shields/nft/${tokenId}`);
        }}
        className="leaderboard__table-row-link"
      >
        <td style={{ "--title": "'No.'" }}>{index + 1}</td>
        <td style={{ "--title": "'Shield ID'" }}>{`#${tokenId}`}</td>
        <td style={{ "--title": "'Burned XRUNE'" }}>
          {utils.printAmount(burned_xrune)}
        </td>
        <td style={{ "--title": "'Base Score Points'" }}>
          {utils.printAmount(base_xrune_score_points)}
        </td>
        <td style={{ "--title": "'Total Score Points'" }}>
          {utils.printAmount(total_xrune_score_points)}
        </td>
        <td style={{ "--title": "'Tokens inside'" }}>
          {`${utils.addSeparatorsToNumber(
            Number.parseFloat(tokenReward).toFixed(2)
          )}`}
        </td>
      </tr>
    );
  };

  return (
    <LeaderboardContainer
      collectionName="Thorstarter Shields"
      renderRow={leaderboardUtils.renderRow(
        tokenCollection,
        filter,
        rowData,
        sortMethod,
        sortDir
      )}
      renderListHeaders={leaderboardUtils.renderListHeaders(
        headersArr,
        sortMethod,
        sortDir,
        onHeaderClick
      )}
      tokenCollection={tokenCollection}
      error={error}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default ThorstarterShieldLeaderboard;
