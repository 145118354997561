import React, { useCallback, useState } from "react";
import "../ProvideLP/ProvideLP.scss";
import Text from "../../components/Text/Text";
import { ReactComponent as Arrow } from "./plus.svg";
import Button from "../../components/Button/Button";
import BalanceInput from "../../components/BalanceInput/BalanceInput";
import useTerra from "../../hooks/useTerra";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useContractApi from "../../hooks/useContractApi";
import useTerraswapApi from "../../hooks/useTerraswapApi";
import { contractAddresses } from "../../config";
import { MsgExecuteContract } from "@terra-money/terra.js";
import debounce from "lodash.debounce";
import utils from "../../utils/utils";
import { ReactComponent as MintLogo } from "../../assets/images/mint-logo.svg";
import { ReactComponent as Ust } from "../../assets/images/ust.svg";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import Modal from "../../components/Modal/Modal";
import useBalances from "../../hooks/useBalances";
import { tokensPrecision } from "../../config";
import TxHash from "../../components/TxHash/TxHash";

const WithdrawLP = () => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { executeContractAndWaitForResult } = useContractApi();
  const { getPool } = useTerraswapApi();
  const { balances, updateBalances } = useBalances();

  const [LPTokensSelectedAmount, setLPTokensSelectedAmount] = useState("");
  const [mintTokensSelectedAmount, setMintTokensSelectedAmount] = useState(0);
  const [USTSelectedAmount, setUSTSelectedAmount] = useState(0);

  const [LPTokensInputError, setLPTokensInputError] = useState(null);

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const onLPTokensValueChangeWrapper = (inputAmount) => {
    let amount = inputAmount;
    if (inputAmount < 0) {
      amount = Math.abs(inputAmount);
    }
    setLPTokensSelectedAmount(amount);
    setLPTokensInputError(
      amount * tokensPrecision > balances?.lp ? "Invalid funds" : null
    );
    onLPTokensValueChange(amount);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLPTokensValueChange = useCallback(
    debounce(async (amount) => {
      const pool = await getPool();
      if (pool) {
        const totalLP = pool.total_share;
        const totalMINT = pool.assets[0].amount;
        const totalUST = pool.assets[1].amount;
        setUSTSelectedAmount(
          Number.parseFloat((amount / totalLP) * totalUST).toFixed(6)
        );
        setMintTokensSelectedAmount(
          Number.parseFloat((amount / totalLP) * totalMINT).toFixed(6)
        );
      } else {
        setMintTokensSelectedAmount("");
        setUSTSelectedAmount("");
        setLPTokensInputError("Could not estimate");
      }
    }, 300),
    [getPool]
  );

  const withdrawLiquidity = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    const lpTokenAmount = Math.floor(
      LPTokensSelectedAmount * tokensPrecision
    ).toString();

    let msgs = [];
    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].lpToken,
        {
          send: {
            amount: lpTokenAmount,
            contract: contractAddresses[network.name].terraswapPair,
            msg: Buffer.from(
              JSON.stringify({ withdraw_liquidity: {} })
            ).toString("base64"),
          },
        },
        {}
      )
    );

    msgs.push(
      new MsgExecuteContract(
        connectedWallet?.walletAddress,
        contractAddresses[network.name].feeCollector,
        {
          receive_funds: {},
        },
        { uusd: tokensPrecision }
      )
    );

    setLoading(true);
    const txResult = await executeContractAndWaitForResult(
      msgs,
      setTxInfo,
      setTxError
    );
    setLoading(false);
    console.log(txResult);

    setLPTokensSelectedAmount(0);
    setMintTokensSelectedAmount(0);
    setUSTSelectedAmount(0);
    setLPTokensInputError(null);
    updateBalances();
  };

  return (
    <div className="provide-lp">
      {isModalOpen && (
        <Modal
          id="modal"
          type={loading ? "warning" : txError ? "error" : "success"}
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <LoadingPopup
            status={loading ? "loading" : txError ? "error" : "success"}
            loadingComponent={
              <>
                {!txInfo ? (
                  <Text
                    className="loading-popup__copy"
                    text="Approve transaction"
                  />
                ) : (
                  <>
                    <TxHash
                      txhash={txInfo?.result.txhash}
                      network={connectedWallet?.network.name}
                    ></TxHash>
                  </>
                )}
              </>
            }
            successComponent={
              <TxHash
                txhash={txInfo?.result.txhash}
                network={connectedWallet?.network.name}
              ></TxHash>
            }
            errorMsg={txError ? txError.toString() : null}
          />
        </Modal>
      )}
      <div className={`provide-lp__inputs`}>
        <BalanceInput
          currency="lp"
          balance={balances?.lp}
          onChange={onLPTokensValueChangeWrapper}
          placeholderValue={0}
          value={LPTokensSelectedAmount}
          error={LPTokensInputError}
        />
        <Arrow className="provide-lp__arrow" />

        <div className="double-label">
          <div className="double-label__row">
            <div className="double-label__input">
              {mintTokensSelectedAmount}
            </div>
            <p className="double-label__currency">
              MINT <MintLogo />
            </p>
          </div>
          <div className="double-label__line" />
          <div className="double-label__row">
            <div className="double-label__input">{USTSelectedAmount}</div>
            <p className="double-label__currency">
              $UST <Ust />
            </p>
          </div>
        </div>
      </div>
      <div className="provide-lp__details">
        <div className="provide-lp__details-col">
          <Text
            className="provide-lp__detail"
            text={`Trading Fee <span>${utils.printAmount(
              tokensPrecision
            )} UST</span>`}
          />
          <Text
            className="provide-lp__detail"
            text="Est Tx Fee <span>&lt; 2 UST</span>"
          />
        </div>
      </div>
      <Button
        variant="contained"
        fullWidth
        className="provide-lp__button"
        onClick={withdrawLiquidity}
        disabled={LPTokensInputError || +LPTokensSelectedAmount === 0}
      >
        Remove Liquidity
      </Button>
    </div>
  );
};

export default WithdrawLP;
