import { useEffect, useRef, useState } from "react";
import "./Dashboard.scss";
import Container from "../../components/Container/Container";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import useTerra from "../../hooks/useTerra";
import Box from "../../components/Box/Box";
import utils from "../../utils/utils";
import { ReactComponent as MintToken } from "./mintToken.svg";
import { ReactComponent as Logo } from "../../assets/images/logo_shape.svg";
import useOnScreen from "../../hooks/useOnScreen";
import Button from "../../components/Button/Button";
import MessageBar from "../../components/MessageBar/MessageBar";
import { CircularProgress } from "@material-ui/core";
import useMintChestsApi from "../../hooks/useMintChestsApi";
import useRoboheroApi from "../../hooks/useRoboheroApi";
import useSpaceCartelsAmuletsApi from "../../hooks/useSpaceCartelsAmuletsApi";
import useMintChestPresenter from "./collections/MintChestsPresenter";
import useRoboheroAvatarsPresenter from "./collections/RoboheroAvatarsPresenter";
import useSpaceCartelsAmuletsPresenter from "./collections/SpaceCartelsAmuletsPresenter";
import useThorstarterShieldsApi from "../../hooks/useThorstarterShieldsApi";
import useThorstarterShieldsPresenter from "./collections/ThorstarterShieldsPresenter";
import usePlaynityTheGenesisApi from "../../hooks/usePlaynityTheGenesisApi";
import usePlaynityTheGenesisPresenter from "./collections/PlaynityTheGenesisPresenter";
import { useHarvest } from "../../hooks/useHarvest";

const Dashboard = () => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();

  const mintChestsApi = useMintChestsApi();
  const mintChestPresenter = useMintChestPresenter();
  const [chestsTokens, setChestsTokens] = useState([]);

  const roboheroApi = useRoboheroApi();
  const roboheroAvatarsPresenter = useRoboheroAvatarsPresenter();
  const [roboheroTokens, setRoboheroTokens] = useState([]);

  const spaceCartelsAmuletsApi = useSpaceCartelsAmuletsApi();
  const spaceCartelsAmuletsPresenter = useSpaceCartelsAmuletsPresenter();
  const [spaceCartelsAmulets, setSpaceCartelsAmulets] = useState([]);

  const thorstarterShieldsApi = useThorstarterShieldsApi();
  const thorstarterShieldsPresenter = useThorstarterShieldsPresenter();
  const [thorstarterShields, setThorstarterShields] = useState([]);

  const playnityTheGenesisApi = usePlaynityTheGenesisApi();
  const playnityTheGenesisPresenter = usePlaynityTheGenesisPresenter();
  const [playnityTheGenesis, setPlaynityTheGenesis] = useState([]);

  const [error, setError] = useState(null);
  const [collectionsToLoad, setCollectionsToLoad] = useState(5);
  const { harvest, HarvestModal } = useHarvest();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const decrementLoadingCollection = () => {
      setCollectionsToLoad((prevCount) => (prevCount <= 0 ? 0 : prevCount - 1));
    };

    mintChestsApi.getAllOwnedTokensInfo(
      setChestsTokens,
      decrementLoadingCollection,
      setError
    );

    roboheroApi.getAllOwnedTokensInfo(
      setRoboheroTokens,
      decrementLoadingCollection,
      setError
    );

    spaceCartelsAmuletsApi.getAllOwnedTokensInfo(
      setSpaceCartelsAmulets,
      decrementLoadingCollection,
      setError
    );

    thorstarterShieldsApi.getAllOwnedTokensInfo(
      setThorstarterShields,
      decrementLoadingCollection,
      setError
    );

    playnityTheGenesisApi.getAllOwnedTokensInfo(
      setPlaynityTheGenesis,
      decrementLoadingCollection,
      setError
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mintChestsApi.getAllOwnedTokensInfo,
    roboheroApi.getAllOwnedTokensInfo,
    spaceCartelsAmuletsApi.getAllOwnedTokensInfo,
    thorstarterShieldsApi.getAllOwnedTokensInfo,
  ]);

  const renderChestCards = () => {
    if (!chestsTokens) return;

    const items = mintChestPresenter.parseTokens(chestsTokens, network);
    return renderItemCards(items);
  };

  const renderRoboheroCards = () => {
    if (!roboheroTokens) return;

    const items = roboheroAvatarsPresenter.parseTokens(roboheroTokens, network);
    return renderItemCards(items);
  };

  const renderSpaceCartelsCards = () => {
    if (!spaceCartelsAmulets) return;

    const items = spaceCartelsAmuletsPresenter.parseTokens(
      spaceCartelsAmulets,
      network
    );
    return renderItemCards(items);
  };

  const renderThorstarterShieldsCards = () => {
    if (!thorstarterShields) return;

    const items = thorstarterShieldsPresenter.parseTokens(
      thorstarterShields,
      network
    );
    return renderItemCards(items);
  };

  const renderPlaynityTheGenesisCards = () => {
    if (!playnityTheGenesis) return;

    const items = playnityTheGenesisPresenter.parseTokens(
      playnityTheGenesis,
      network
    );
    return renderItemCards(items);
  };

  const renderItemCards = (items) => {
    const res = [];

    if (items) {
      for (let i = 0; i < items.length; i++) {
        const itemImage = items[i].tokenImage;
        const itemId = items[i].tokenId;
        const itemName = items[i].tokenName;
        const itemAttributesSection = items[i].itemAttributesSection;
        const itemReward = items[i].itemReward;
        const contractAddress = items[i].contractAddress;
        const harvestFee = items[i].harvestFee;

        res.push(
          <div key={i} className="dashboard__item">
            <Box className="dashboard__item-box">
              <img
                onClick={items[i]?.onClick}
                className={`dashboard__item-image ${
                  items[i].onClick ? "dashboard__item-image-link" : ""
                }`}
                src={itemImage}
                alt={itemName}
              />
            </Box>
            <div className="dashboard__item-info">
              <Title
                text={`${itemName} #${itemId}`}
                className="dashboard__item-title"
              />
              <div>{itemAttributesSection}</div>

              <div className="dashboard__item-reward">
                <div className="dashboard__item-reward-info">
                  <Title
                    className="dashboard__item-title"
                    text={`${utils.addSeparatorsToNumber(
                      Number.parseFloat(itemReward).toFixed(2)
                    )}`}
                    inverted
                  />
                  <div className="dashboard__item-mint">
                    <Text
                      text={`<span>MINT</span>`}
                      className="dashboard__item-attribute"
                    />
                    <MintToken className="dashboard__mint-token-icon" />
                  </div>
                </div>
                <div className="dashboard__separator" />
                <Button
                  className="dashboard__item-reward-button"
                  variant="contained"
                  onClick={() => harvest(contractAddress, harvestFee, itemId)}
                >
                  Harvest MINT
                </Button>
              </div>
            </div>
          </div>
        );
      }
    }

    return res;
  };

  return (
    <div className="dashboard" ref={ref}>
      <Logo
        className={`dashboard__logo ${
          isVisible ? "dashboard__logo--visible" : ""
        }`}
      />
      <Container className="dashboard__container" narrow>
        <div className="dashboard__page-title">
          <div>
            <Title text="my" suffix="Dashboard" inverted />
            <Text
              className="dashboard__sub-title"
              text="Preview your NFTs! You can claim the <span>MINT</span> tokens from NFTs!"
            />
            <Text
              className="dashboard__sub-title--small"
              text="Harvesting fee 1 UST."
            />
          </div>
        </div>
        <div className="dashboard__nav">
          <div className="dashboard__nav-item">
            <Text
              className="dashboard__nav-item--active"
              text="My Collection of NFTs"
            />
          </div>
        </div>
        <div className="dashboard__separator" />
        {error && <MessageBar text={error} type="error"></MessageBar>}
        <div className="dashboard__my-nfts">
          {!connectedWallet?.terraAddress ? (
            <div className="dashboard__message">
              <Text text="Connect your wallet first." />
            </div>
          ) : null}

          {collectionsToLoad !== 0 ? (
            <div className="dashboard__message">
              <CircularProgress className="dashboard__circular-progress" />
            </div>
          ) : null}
          {collectionsToLoad === 0 &&
          chestsTokens.length === 0 &&
          roboheroTokens.length === 0 &&
          spaceCartelsAmulets.length === 0 &&
          thorstarterShields.length === 0 &&
          playnityTheGenesis.length === 0 &&
          error === null ? (
            <div className="dashboard__message">
              <Text text="You do not own any NFT tokens." />
            </div>
          ) : null}

          {renderChestCards()}
          {renderRoboheroCards()}
          {renderSpaceCartelsCards()}
          {renderThorstarterShieldsCards()}
          {renderPlaynityTheGenesisCards()}
        </div>
      </Container>
      <HarvestModal />
    </div>
  );
};

export default Dashboard;
