import "./SpaceCartelsAmulet.scss";
import Text from "../../../components/Text/Text";
import Title from "../../../components/Title/Title";
import SmallTitle from "../../../components/SmallTitle/SmallTitle";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import spaceCartelsAmuletsUtils from "../../../utils/spaceCartelsAmuletsUtils";
import useSpaceCartelsAmuletsApi from "../../../hooks/useSpaceCartelsAmuletsApi";
import utils from "../../../utils/utils";
import NftContainer from "../../../containers/NftContainer/NftContainer";
import Timer from "../../../components/Timer/Timer";

const SpaceCartelsAmulet = (props) => {
  const { tokenId } = useParams();
  const { state } = useLocation();
  const { getSpaceCartelsAmuletsInfo } = useSpaceCartelsAmuletsApi();
  const [nftInfo, setNftInfo] = useState(
    spaceCartelsAmuletsUtils.getSpaceCartelsAmuletsByTokenInfo(state?.rarity)
  );

  useEffect(() => {
    const init = async () => {
      // using get_tokens_by_ids, because nft_info does not have last_transferred
      const contractQuery = await getSpaceCartelsAmuletsInfo({
        tokenIds: [tokenId],
      });
      const attr = contractQuery?.[0];
      setNftInfo({
        rarity: attr?.rarity,
        dmtBoost: attr?.dmt_boost,
        mintReward: attr?.pending_reward,
        boostIn: attr?.last_transferred
          ? spaceCartelsAmuletsUtils.calculateBoostIn(attr?.last_transferred)
          : null,
        ...spaceCartelsAmuletsUtils.getSpaceCartelsAmuletsByTokenInfo(
          attr?.rarity
        ),
      });
    };

    init();
  }, [tokenId, getSpaceCartelsAmuletsInfo]);

  const image = (
    <img
      src={
        spaceCartelsAmuletsUtils.getSpaceCartelsAmuletsByTokenInfo(
          nftInfo?.rarity
        ).image
      }
      alt={nftInfo?.rarity}
    />
  );

  const children = (
    <>
      <Title
        className="space-cartels-amulets__name"
        text={`${nftInfo?.rarity} Space Cartels Amulet`}
      />

      <SmallTitle text="description" />
      <Text text={"This amulet has the following utilities:"} />
      {
        spaceCartelsAmuletsUtils.getSpaceCartelsAmuletsByTokenInfo(
          nftInfo?.rarity
        ).utilityComponent
      }

      <p className="space-cartels-amulets__info">
        ID: <span>#{tokenId}</span>
      </p>
      <p className="space-cartels-amulets__info">
        Rarity: <span>{nftInfo?.rarity}</span>
      </p>
      <p className="space-cartels-amulets__info">
        DMT BOOST:{" "}
        <span>{`${utils.printAmount(nftInfo?.dmtBoost * 100, 1)}%`}</span>
      </p>
      <div className="dashboard__timer-wrapper">
        <p className="space-cartels-amulets__info">Increase in:</p>
        {nftInfo?.boostIn && (
          <Timer
            className="dashboard__timer"
            expiryTimestamp={nftInfo?.boostIn}
            onExpire={() => {}}
            showDays
          />
        )}
      </div>
    </>
  );

  return (
    <NftContainer
      tokenId={tokenId}
      image={image}
      imagePath={nftInfo?.image}
      children={children}
      title="Space Cartels Amulet"
      harvestFee={state?.harvestFee}
      contractAddress={state?.contractAddress}
      harvestReward={nftInfo?.mintReward}
    />
  );
};

export default SpaceCartelsAmulet;
