import React from "react";
import "./ProgressBar.scss";
import Text from "../Text/Text";
import { ReactComponent as Icon1 } from "./icon1.svg";
import { ReactComponent as Icon2 } from "./icon2.svg";
import { ReactComponent as Icon3 } from "./icon3.svg";

const isStepActive = (step, progress) => {
  const steps = {
    minting: ["minting", "drawing", "minted"],
    drawing: ["drawing", "minted"],
    minted: ["minted"],
  };

  return steps[step].includes(progress);
};

const title = {
  "minting requested": "Gathering resources...",
  minting: "Minting...",
  drawing: "Draw in progress...",
  minted: "Congratulations!",
};

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <Text className="progress-bar__title" text={title[progress]} />

      <ul className="progress-bar__items">
        {/* minting */}
        <li
          className={`progress-bar__item ${
            isStepActive("minting", progress)
              ? "progress-bar__item--active"
              : ""
          }`}
        >
          <Icon1 className="progress-bar__icon" />
        </li>

        {/* drawing */}
        <li
          className={`progress-bar__line ${
            isStepActive("drawing", progress)
              ? "progress-bar__line--active"
              : ""
          }`}
        />
        <li
          className={`progress-bar__item ${
            isStepActive("drawing", progress)
              ? "progress-bar__item--active"
              : ""
          }`}
        >
          <Icon2
            className={`progress-bar__icon ${
              progress === "drawing" ? "progress-bar__icon--active" : ""
            }`}
          />
        </li>

        {/* minted */}
        <li
          className={`progress-bar__line ${
            isStepActive("minted", progress) ? "progress-bar__line--active" : ""
          }`}
        />
        <li
          className={`progress-bar__item ${
            isStepActive("minted", progress) ? "progress-bar__item--active" : ""
          }`}
        >
          <Icon3 className="progress-bar__icon" />
        </li>
      </ul>
      {"minting" === progress || "minting requested" === progress ? (
        <Text
          className="progress-bar__desc"
          text="Robots are arriving at the factory and preparing raw minerals for new orders."
        />
      ) : null}
      {"drawing" === progress ? (
        <Text
          className="progress-bar__desc"
          text="Our factory took the order. The NFT will be delivered in a moment."
        />
      ) : null}
      {"minted" === progress ? (
        <Text
          className="progress-bar__desc"
          text="All done and ready for you to be collected."
        />
      ) : null}
    </div>
  );
};

export default ProgressBar;
