import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";
import { MsgExecuteContract } from "@terra-money/terra.js";
import { useConnectedWallet } from "@terra-money/use-wallet";

export const useTerraWalletToETHApi = () => {
  const { network } = useTerra();
  const { queryContract, executeContractAndWaitForResult } = useContractApi();
  const connectedWallet = useConnectedWallet();

  return {
    getETHAddress: useCallback(
      async (address) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].terraWalletToEthContract,
            { get_data: { address } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    setEthAddressTx: useCallback(
      async (ethAddress, setTxInfo, setTxError) => {
        let msgs = [];

        msgs.push(
          new MsgExecuteContract(
            connectedWallet?.walletAddress,
            contractAddresses[network.name].terraWalletToEthContract,
            {
              register_address: {
                address: ethAddress,
              },
            },
            {}
          )
        );

        return executeContractAndWaitForResult(msgs, setTxInfo, setTxError);
      },
      [
        connectedWallet?.walletAddress,
        executeContractAndWaitForResult,
        network.name,
      ]
    ),
  };
};

export default useTerraWalletToETHApi;
