import React from "react";
import "./MiniButton.scss";

const MiniButton = ({ onClick, children }) => (
  <button onClick={onClick} className="mini-button">
    {children}
  </button>
);

export default MiniButton;
