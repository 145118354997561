import { useCallback } from "react";
import { useConnectedWallet } from "@terra-money/use-wallet";
import utils from "../../utils/utils";

export const useGenericCollectionHooks = (
  getTokensIds,
  getTokensInfo,
  tokenLimit = 1000,
  refreshTime = 5000
) => {
  const connectedWallet = useConnectedWallet();

  return {
    getAllOwnedTokensInfo: useCallback(
      async (setItems, setLoadingFinished, setError) => {
        if (!connectedWallet?.terraAddress) {
          return;
        }
        let loadedOnce = false;
        const intervalId = utils.asyncSetInterval(async () => {
          const tokenIds = await getTokensIds({
            owner: connectedWallet?.terraAddress,
            limit: tokenLimit,
          });

          utils.isCollectionNull(tokenIds, setError);

          if (tokenIds?.length > 0) {
            const tokens = await getTokensInfo({ tokenIds, limit: tokenLimit });

            utils.isCollectionNull(tokens, setError);
            setItems(tokens);
          }
          if (!loadedOnce) {
            loadedOnce = true;
            setLoadingFinished();
          }
        }, refreshTime);
        return () => clearInterval(intervalId);
      },
      [
        connectedWallet?.terraAddress,
        getTokensIds,
        getTokensInfo,
        refreshTime,
        tokenLimit,
      ]
    ),
  };
};

export default useGenericCollectionHooks;
