export const availableNetworks = {
  0: {
    name: "testnet",
    chainID: "bombay-12",
    lcd: "https://bombay-lcd.terra.dev",
  },
  1: {
    name: "classic",
    chainID: "columbus-5",
    lcd: "https://columbus-lcd.terra.dev",
  },
};

export const harvestFees = {
  mintChests: 1000000,
  roboheroAvatars: 1000000,
  spaceCartelsAmulets: 1000000,
  thorstarterShields: 1000000,
};

export const contractAddresses = {
  testnet: {
    roboheroAvatars: "terra1rqqtz8x7kdma6kg2py9wkew57nrafzq0ks94yj",
    mintChests: "terra16vckm5m2gdg6u709dd7677qc25eqa2xcpsyf7z",
    spaceCartelsAmulets: "terra1gw7mgtdz964ztlg2552ez2j4f4sht8p6fc3ux6",
    stakingSAS: "terra1mnpx0qryyfegq7flle3ff8h5tsd083a7al7r2x",
    stakingLP: "terra18amkmx3f36xjtp2fl7wra5lwf9u8m74sucyjr9",
    ustRewards: "terra1ygjvd97297y0k95l9qe0aygnw43x6mpvf74v22",
    terraswapPair: "terra1dnwfr23wzwup6fjjluuf7ne0nmwltgmjxe2650",
    lpToken: "terra12wwj7yv22ch494dyf8kvu0fgenmfc89dps2tql",
    mintToken: "terra16re054j5pyfswlw65mjrvrnp9cffj6z8spuc8w",
    privateSaleVesting: "terra1r30sa9w0r8wxhngcxxspaemg8gvc7zek9spxe4",
    feeCollector: "terra1sddunmngjdpyr74c3qjfjt4fg6cvp4mv6dlvaq",
    airdrop: "terra1fg4dhfmrq82ag3l2vv75jxxut70gnzmyg26juf",
    daoTreasuryGateway: "terra1tlahy4ns0g2ml4xq6nktfx9kulsku53fyhyphf",
    thorstarterShields: "terra1rwadklze009gqj6tslu4mhp8uk3dy68slzzheh",
    playnityTheGenesis: "terra1ztn9nhdfm29v8cvqhu6lqgu7sqgc8k4erm8m88",
    playnityTheGenesisMintManager:
      "terra1ep6j9gu9jl00nrr0sm9f705gh0mv339ruj0d62",
    terraWalletToEthContract: "",
  },
  classic: {
    roboheroAvatars: "terra1pw0x4f7ktv4vdqvx8dfaa5d2lp0t5rpzep9ewn",
    mintChests: "terra12uxqcqfqrr9hrzen6pz0fx565hr8u88qfyusc0",
    spaceCartelsAmulets: "terra19relnlh7am0j9maggd4zdeed67v7jcm5k2clkr",
    stakingSAS: "terra1r6dqcrr2wucwf2jt0fpyhfuu2vzt6v4kxj6uc7",
    stakingLP: "terra16znwym2jaclyz3vwsjhhtnvdcfg7r9qtcxcv00",
    ustRewards: "terra1afklas37nww408d2krz8a2xl3xd7anvl247lh3",
    terraswapPair: "terra1fdwsevq7skhfq93zkg0xxr6zrv29aqupw2fura",
    lpToken: "terra19tn6v77xf2v5tkd0g3dl5wqvjdh8d0u4g3p2j7",
    mintToken: "terra1zd6let0zg0xjn2sestagxv4ax24a4ml6j40qdr",
    privateSaleVesting: "terra1nedtppdztlzzv92ycclqpsz8yec4c6jw55z867",
    feeCollector: "terra1nk63cdzj007y87dg0cgfw4l520jph3d5fklus9",
    airdrop: "terra13chx9lslnzcn70275y2tfwc6wwaky9q5yklqml",
    daoTreasuryGateway: "terra1fek4s2myjxk7ljkvc3vzkwmdrwjqaksz6cdatq",
    thorstarterShields: "terra10pxt36lyy6rhsumw7j8lahwvwrre7fxrfktgjl",
    playnityTheGenesis: "terra1ewlufj2u3fmsnsehrj7c5skhadkadlppscc29v",
    playnityTheGenesisMintManager:
      "terra16e04pqz23k2th29rt4qfgw6gdcqu0lndvk8mqt",
    terraWalletToEthContract: "terra1haft4mymtvzj8z8ugknlxjzsa9n4n89qft3vfx",
  },
};

export const tokensPrecision = 1000000;

export const happyHour = {
  startDate: "2022-05-09T17:00:00.000Z",
  distributionAmount: 10000,
};

export const IPFS_GATEWAY = "https://mintdao.mypinata.cloud/ipfs/";

export const circulatingSupplyURL =
  "https://europe-west1-mintdao-app.cloudfunctions.net/circulatingSupply";

export const tokens = {
  mainnet: [
    {
      address: "uluna",
      name: "LUNA",
      isDefault: false,
      decimal: 6,
    },
    {
      address: "uusd",
      name: "UST",
      isDefault: false,
      decimal: 6,
    }
  ],
  classic: [
    {
      address: "uluna",
      name: "LUNA",
      isDefault: false,
      decimal: 6,
    },
    {
      address: "uusd",
      name: "UST",
      isDefault: false,
      decimal: 6,
    },
    {
      address: "terra1zd6let0zg0xjn2sestagxv4ax24a4ml6j40qdr",
      name: "MINT",
      isDefault: true,
      decimal: 6,
    },
  ],
  testnet: [
    {
      address: "uluna",
      name: "LUNA",
      isDefault: false,
      decimal: 6,
    },
    {
      address: "uusd",
      name: "UST",
      isDefault: false,
      decimal: 6,
    },
    {
      address: "terra16re054j5pyfswlw65mjrvrnp9cffj6z8spuc8w",
      name: "MINT",
      isDefault: true,
      decimal: 6,
    },
  ],
};
