import { createPortal } from "react-dom";
import "./Modal.scss";
import usePortal from "../../hooks/usePortal";
import { ReactComponent as CloseIcon } from "./close.svg";

const Modal = ({
  id,
  children,
  onCloseClick,
  type = "success",
  showCloseButton = false,
}) => {
  const target = usePortal(id);

  const onBackdropClick = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (e.target === e.currentTarget) {
      onCloseClick();
    }
  };

  return createPortal(
    <div className={`modal modal--type-${type}`} onClick={onBackdropClick}>
      <div className="modal__body">
        {showCloseButton && (
          <CloseIcon className="modal__close" onClick={onCloseClick} />
        )}
        {children}
      </div>
    </div>,
    target
  );
};

export default Modal;
