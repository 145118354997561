import React, { forwardRef } from "react";
import { SnackbarContent } from "notistack";
import MessageBar from "./MessageBar";

const SnackMessage = forwardRef(({ message }, ref) => {
  const { text, variant, action } = message;

  return (
    <SnackbarContent ref={ref}>
      <MessageBar
        text={text}
        type={variant}
        isSnackbar={true}
        Action={action}
      />
    </SnackbarContent>
  );
});

export default SnackMessage;
