import "./TxHash.scss";

const TxHash = ({ txhash, network }) => (
  <a
    className="tx-hash__terra-finder-link"
    href={
      network === "classic"
        ? `https://finder.terra.money/classic/tx/${txhash}`
        : `https://finder.terra.money/testnet/tx/${txhash}`
    }
    target="_blank"
    rel="noreferrer"
  >
    Terra Finder transaction:{" "}
    <span>
      {txhash?.substring(0, 5)}...
      {txhash?.substring(txhash.length - 5, txhash.length)}
    </span>
  </a>
);

export default TxHash;
