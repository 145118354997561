import "./Navbar.scss";

import { Wallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../hooks/useTerra";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import Button from "../../components/Button/Button";
import { tokens } from "../../config";

import { Link } from "react-router-dom";
import { createTheme } from "@material-ui/core";
import Container from "../../components/Container/Container";
import { useEffect, useState } from "react";
import Hamburger from "../../components/Hamburger/Hamburger";
import Menu from "../../components/Menu/Menu";

const theme = createTheme({
  palette: {
    primary: {
      main: "#66cc9a",
    },
    secondary: {
      main: "#66cc9a",
    },
    info: {
      main: "#000",
    },
    background: {
      paper: "#000",
    },
  },
  shape: {
    borderRadius: "10px",
  },
  overrides: {
    MuiPaper: {
      elevation3: {
        boxShadow: "0 0 6px #66cc9a",
        backgroundColor: "#252525",
        border: "2px solid #66cc9a",
      },
      elevation24: {
        boxShadow: "0 0 6px #66cc9a",
        backgroundColor: "#252525",
        border: "2px solid #66cc9a",
      },
    },
    MuiButton: {
      root: {
        height: "46px",
        fontFamily: '"Poppins", sans-serif',
      },

      contained: {
        border: "2px solid #66cc9a",
        boxShadow: "0 0 6px #66cc9a",
      },

      containedPrimary: {
        backgroundColor: "#252525",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#252525",
        },
      },

      outlinedPrimary: {
        border: "2px solid #66cc9a",
        boxShadow: "0 0 6px #66cc9a",
        backgroundColor: "#252525",
        "&:hover": {
          border: "2px solid #66cc9a",
          boxShadow: "0 0 6px #66cc9a",
        },
      },

      outlinedSecondary: {
        border: "2px solid #66cc9a",
        boxShadow: "0 0 6px #66cc9a",
        backgroundColor: "#252525",
        "&:hover": {
          border: "2px solid #66cc9a",
          boxShadow: "0 0 6px #66cc9a",
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        minWidth: "300px",
        "@media (min-width: 640px)": {
          minWidth: "500px",
        },
      },
      root: {
        "& .MuiButton-outlined": {
          "&.Mui-disabled": {
            color: "#fff",
            opacity: 0.5,
            border: `3px solid #66cc9a`,
          },
        },
        "& .MuiButton-contained": {
          "&.Mui-disabled": {
            color: "#000",
            opacity: 0.5,
            backgroundColor: "#66cc9a",
          },
        },
        "& .MuiButton-outlinedSecondary": {
          borderRadius: "21px",
          marginBottom: "15px",
          backgroundColor: "#000",
          textTransform: "initial",
          border: "none",
          "&:hover": {
            border: "none",
            backgroundColor: "#66cc9a",
            color: "#000",
          },
        },
      },
    },
  },
});

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const [isTestnet, setIsTestnet] = useState(false);

  const { network } = useTerra();

  useEffect(() => {
    setIsTestnet(network.name !== "classic");
  }, [network, isTestnet]);

  return (
    <div className="navigation">
      <Menu isOpen={isOpen} onItemClick={() => setOpen(false)} />

      <div className="navbar-wrapper">
        {isTestnet && (
          <div className="navbar__testnet-info">
            <span>Switch to CLASSIC network for production app</span>
          </div>
        )}
        <div className="navbar">
          <Container className="navbar__container">
            <Link to="/">
              <div className="logo">
                <Logo alt="logo" />
              </div>
            </Link>

            <Link to="stake" className="navbar__nav-item">
              <Button>Stake</Button>
            </Link>
            <Link to="trade" className="navbar__nav-item">
              <Button>Trade</Button>
            </Link>
            <Link to="treasury" className="navbar__nav-item">
              <Button>Treasury</Button>
            </Link>
            <Link to="collections" className="navbar__nav-item">
              <Button>Collections</Button>
            </Link>

            <Link to="leaderboard" className="navbar__nav-to-leaderboard">
              <Button>Leaderboard</Button>
            </Link>

            <Link to="dashboard" className="navbar__nav-to-dashboard">
              <Button variant="outlined">Dashboard</Button>
            </Link>

            <div className="navbar__wallet">
              <Wallet
                tokens={tokens[network.name]}
                readOnlyMode={false}
                customTheme={theme}
              />
            </div>

            <Hamburger
              className="navbar__menu"
              onClick={() => setOpen(!isOpen)}
              isOpen={isOpen}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
