import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useTerraswapApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  return {
    getSimulation: useCallback(
      async (amount, msgInfo) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].terraswapPair,
            {
              simulation: {
                offer_asset: {
                  amount,
                  info: msgInfo,
                },
              },
            }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),

    getPool: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].terraswapPair,
          {
            pool: {},
          }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getLPTokenBalance: useCallback(
      async (connectedWalletAddress) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].lpToken,
            { balance: { address: connectedWalletAddress } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getReverseSimulation: useCallback(
      async (amount, msgInfo) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].terraswapPair,
            {
              reverse_simulation: {
                ask_asset: {
                  amount,
                  info: msgInfo,
                },
              },
            }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
  };
};

export default useTerraswapApi;
