import { contractAddresses } from "../../../config";
import Text from "../../../components/Text/Text";
import PlaynityTheGenesisUtils from "../../../utils/PlaynityTheGenesisUtils";
import { useNavigate } from "react-router";
import ImageUtils from "../../../utils/ImageUtils";

export const usePlaynityTheGenesisPresenter = () => {
  const navigate = useNavigate();

  return {
    parseTokens: (tokens, network) => {
      const items = tokens.map((playnityTheGenesis) => {
        const playnityTheGenesisId = playnityTheGenesis.token_id;
        const playnityTheGenesisAdditionalInfo =
          PlaynityTheGenesisUtils.getPlaynityTheGenesisTokenInfo();
        const rarity = playnityTheGenesis?.drawed_nft_info?.rarity;
        const image = playnityTheGenesis?.drawed_nft_info?.image
          ? ImageUtils.resolveIPFSImage(
              playnityTheGenesis?.drawed_nft_info?.image
            )
          : playnityTheGenesisAdditionalInfo.image;

        const playnityTheGenesisReward =
          playnityTheGenesis.pending_reward / 1_000_000;
        const playnityTheGenesisAttributeSection = (
          <>
            <Text
              text={`<span>Rarity:</span> ${rarity}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>tokens inside:</span>`}
              className="dashboard__item-attribute"
            />
          </>
        );
        return {
          tokenImage: image,
          tokenId: playnityTheGenesisId,
          tokenName: `Playnity The Genesis`,
          itemAttributesSection: playnityTheGenesisAttributeSection,
          itemReward: playnityTheGenesisReward,
          contractAddress: contractAddresses[network.name].playnityTheGenesis,
          harvestFee: 1000000, // This value should be taken from config
          onClick: () => {
            navigate(
              `/collections/playnity-the-genesis/nft/${playnityTheGenesisId}`,
              {
                state: {
                  tokenId: playnityTheGenesisId,
                },
              }
            );
          },
        };
      });

      return items;
    },
  };
};

export default usePlaynityTheGenesisPresenter;
