import "./Containter.scss";

const Container = ({ children, className, narrow }) => (
  <div
    className={`container ${narrow ? "container--narrow" : ""} ${className}`}
  >
    {children}
  </div>
);

export default Container;
