import eter1 from "../assets/images/robohero/eter1.png";
import eter2 from "../assets/images/robohero/eter2.png";
import eter3 from "../assets/images/robohero/eter3.png";
import eter4 from "../assets/images/robohero/eter4.png";
import eter5 from "../assets/images/robohero/eter5.png";
import plasma1 from "../assets/images/robohero/plasma1.png";
import plasma2 from "../assets/images/robohero/plasma2.png";
import plasma3 from "../assets/images/robohero/plasma3.png";
import plasma4 from "../assets/images/robohero/plasma4.png";
import plasma5 from "../assets/images/robohero/plasma5.png";
import deuter1 from "../assets/images/robohero/deuter1.png";
import deuter2 from "../assets/images/robohero/deuter2.png";
import deuter3 from "../assets/images/robohero/deuter3.png";
import deuter4 from "../assets/images/robohero/deuter4.png";
import deuter5 from "../assets/images/robohero/deuter5.png";
import minting from "../assets/images/chests/minting.jpg";

const roboheroAvatarsUtils = {
  getRoboheroByTokenInfo: (faction, rarity) => {
    const key = `${faction}-${rarity}`;
    const propertyInfoMap = {
      "ETER-RARE": { image: eter1, rarity, faction },
      "ETER-SUPER RARE": { image: eter2, rarity, faction },
      "ETER-EPIC": { image: eter3, rarity, faction },
      "ETER-LEGENDARY": { image: eter4, rarity, faction },
      "ETER-MYTHIC": { image: eter5, rarity, faction },
      "PLASMA-RARE": { image: plasma1, rarity, faction },
      "PLASMA-SUPER RARE": { image: plasma2, rarity, faction },
      "PLASMA-EPIC": { image: plasma3, rarity, faction },
      "PLASMA-LEGENDARY": { image: plasma4, rarity, faction },
      "PLASMA-MYTHIC": { image: plasma5, rarity, faction },
      "DEUTER-RARE": { image: deuter1, rarity, faction },
      "DEUTER-SUPER RARE": { image: deuter2, rarity, faction },
      "DEUTER-EPIC": { image: deuter3, rarity, faction },
      "DEUTER-LEGENDARY": { image: deuter4, rarity, faction },
      "DEUTER-MYTHIC": { image: deuter5, rarity, faction },
    };

    return (
      propertyInfoMap[key] || {
        image: minting,
        type: "minting",
        name: "Minting...",
      }
    );
  },
};
export default roboheroAvatarsUtils;
