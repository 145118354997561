import React from "react";
import "./BalanceInput.scss";
import { ReactComponent as MintLogo } from "../../assets/images/mint-logo.svg";
import { ReactComponent as LPTokenLogo } from "../../assets/images/lp-token.svg";
import { ReactComponent as Ust } from "../../assets/images/ust.svg";
import Text from "../Text/Text";
import MiniButton from "../MiniButton/MiniButton";
import utils from "../../utils/utils";

const BalanceInput = ({
  currency,
  customCurrency,
  balance,
  onChange,
  placeholderValue,
  value,
  disabled,
  condensed,
  error,
  maxAmountDescription = "Balance",
}) => {
  return (
    <div
      className={`balance-input ${
        condensed ? "balance-input--condensed" : ""
      } ${error ? "balance-input--has-error" : ""}`}
    >
      <label className="balance-input__label" htmlFor="from">
        {balance >= 0 && (
          <MiniButton onClick={() => onChange(balance / 1000000, true)}>
            Max
          </MiniButton>
        )}
        <input
          onChange={(event) => onChange(event.target.value)}
          value={value}
          placeholder={placeholderValue}
          type="number"
          className="balance-input__input"
          id="from"
          disabled={disabled ? true : null}
        />
        <p className="balance-input__currency">
          {currency === "lp" && (
            <>
              LP <LPTokenLogo />
            </>
          )}
          {currency === "mint" && (
            <>
              MINT <MintLogo />
            </>
          )}
          {currency === "ust" && (
            <>
              $UST <Ust />
            </>
          )}
          {!currency && customCurrency && <>{customCurrency}</>}
        </p>
      </label>
      <div className="balance-input__footer">
        {balance >= 0 && (
          <Text
            className="balance-input__balance"
            text={`${maxAmountDescription} ${utils.printAmount(balance)}`}
          />
        )}
        {error && (
          <Text className="balance-input__error-message" text={error} />
        )}
      </div>
    </div>
  );
};

export default BalanceInput;
