import "./LoadingPopup.scss";
import Text from "../Text/Text";

const LoadingPopup = ({
  status,
  loadingMsg,
  errorMsg,
  successMsg,
  loadingComponent,
  successComponent,
}) => {
  return (
    <div className="loading-popup">
      <div className="loading-popup__title-container">
        {status === "loading" && (
          <Text className="loading-popup__title" text="Processing" />
        )}
        {status === "error" && (
          <Text className="loading-popup__title" text="Error" />
        )}
        {status === "success" && (
          <Text className="loading-popup__title" text="Success" />
        )}
      </div>

      <div
        className={`loading-popup__progress loading-popup__progress--${status}`}
      >
        <div className="circular">
          <div className="inner">
            {status === "error" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27.432"
                height="23.318"
                viewBox="0 0 27.432 23.318"
              >
                <path
                  id="Union_7"
                  data-name="Union 7"
                  d="M17891.258-2111.163a2.273,2.273,0,0,1-1.607-.665l-9.141-9.143-9.141,9.143a2.286,2.286,0,0,1-1.613.665H17868l7.2-7.2,3.955-3.956-7.771-7.773-3.387-3.386h1.762a2.3,2.3,0,0,1,1.607.665l9.141,9.144,9.141-9.144a2.291,2.291,0,0,1,1.607-.665h1.76l-3.385,3.386-7.771,7.773,3.953,3.956h0l7.207,7.2Z"
                  transform="translate(-17866.793 2133.981)"
                  fill="#f85252"
                  stroke="rgba(0,0,0,0)"
                  stroke-miterlimit="10"
                  stroke-width="1"
                />
              </svg>
            )}
            {status === "success" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.664"
                height="16.464"
                viewBox="0 0 20.664 16.464"
              >
                <path
                  id="Path_3730"
                  data-name="Path 3730"
                  d="M7.084,9.187a2.276,2.276,0,0,0-1.609-.666H3.716l4.2,4.2,1.351-1.351Z"
                  transform="translate(-3.716 3.741)"
                  fill="#66cc9a"
                />
                <path
                  id="Path_3731"
                  data-name="Path 3731"
                  d="M6.932,16.959,15,8.9,23.4.5H21.637a2.279,2.279,0,0,0-1.609.666L5.581,15.608Z"
                  transform="translate(-2.732 -0.495)"
                  fill="#66cc9a"
                />
              </svg>
            )}
          </div>
          <div className="circle">
            <div className="bar left">
              <div className="progress" />
            </div>
            <div className="bar right">
              <div className="progress" />
            </div>
          </div>
        </div>
      </div>

      <footer className="loading-popup__footer">
        {status === "loading" && (
          <>
            {loadingComponent ? (
              loadingComponent
            ) : (
              <Text
                className="loading-popup__copy"
                text={loadingMsg ? loadingMsg : "Loading, please wait..."}
              />
            )}
          </>
        )}
        {status === "error" && (
          <Text
            className="loading-popup__copy"
            text={errorMsg ? errorMsg : "Error, please try again."}
          />
        )}
        {status === "success" && (
          <>
            {successComponent ? (
              successComponent
            ) : (
              <Text
                className="loading-popup__copy"
                text={successMsg ? successMsg : "Success, everything worked!"}
              />
            )}
          </>
        )}
      </footer>
    </div>
  );
};

export default LoadingPopup;
