import { contractAddresses, harvestFees } from "../../../config";
import mintChestUtils from "../../../utils/mintChestsUtils";
import Text from "../../../components/Text/Text";
import { useNavigate } from "react-router";

export const useMintChestPresenter = () => {
  const navigate = useNavigate();

  return {
    parseTokens: (tokens, network) => {
      const items = tokens?.map((chest) => {
        const chestId = chest.token_id;
        const chestAttributes = chest;
        const chestPower = chestAttributes.power;
        const chestReward = chestAttributes.pending_reward / 1_000_000;
        const chestAdditionalInfo = mintChestUtils.getChestByPower(chestPower);

        const chestAttributeSection = (
          <>
            <Text
              text={`<span>Power:</span> ${chestPower}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>tokens inside:</span>`}
              className="dashboard__item-attribute"
            />
          </>
        );

        const contractAddress = contractAddresses[network.name].mintChests;
        const harvestFee = harvestFees.mintChests;
        return {
          tokenImage: chestAdditionalInfo.image,
          tokenId: chestId,
          tokenName: chestAdditionalInfo.name,
          itemAttributesSection: chestAttributeSection,
          itemReward: chestReward,
          contractAddress,
          harvestFee,
          onClick: () => {
            navigate(`/collections/mint-chests/nft/${chestId}`, {
              state: {
                tokenId: chestId,
                contractAddress,
                harvestFee,
                chestPower: chestPower,
              },
            });
          },
        };
      });
      return items;
    },
  };
};

export default useMintChestPresenter;
