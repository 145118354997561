const utils = {
  delay: async (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration)),
  wrapWithRetries: async (
    asyncTask,
    retries = 5,
    delayBetweenRetriesInMs = 3000
  ) => {
    try {
      const taskResult = await asyncTask();
      if (!taskResult) {
        throw new Error("Result not found");
      }
      return taskResult;
    } catch (err) {
      if (retries > 0) {
        await utils.delay(delayBetweenRetriesInMs);
        return utils.wrapWithRetries(
          asyncTask,
          retries - 1,
          delayBetweenRetriesInMs
        );
      }
      throw err;
    }
  },
  delegateIntervalTask: async (func, delay = 3000) => {
    const result = await func();
    if (!result) {
      await utils.delay(delay);
      return await utils.delegateIntervalTask(func, delay);
    } else {
      return result;
    }
  },
  asyncSetInterval: (func, delay = 3000) => {
    let lock = false;
    const initial = async () => {
      lock = true;
      await func();
      lock = false;
    };
    initial();
    const intervalId = setInterval(async () => {
      if (!lock) {
        lock = true;
        await func();
        lock = false;
      }
    }, delay);
    return intervalId;
  },
  addSeparatorsToNumber: (number) => {
    const integer = Math.trunc(number);
    return `${integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${
      number.toString().split(".")[1]
    }`;
  },
  printAmount: (balance, precision = 2) => {
    if (balance === undefined || isNaN(balance)) {
      return "0";
    }
    return utils.addSeparatorsToNumber(
      Number.parseFloat(balance / 1000000).toFixed(precision)
    );
  },
  isCollectionNull: (collection, setError) =>
    collection === null
      ? setError("There was a problem while fetching the NFT collection.")
      : setError(null),
  isAddressInWL: (address, wl) => {
    return wl?.some((wlData) => {
      return wlData.address === address && parseInt(wlData.amount) > 0;
    });
  },
  shortAddress: (address) => {
    return `${address?.substring(0, 5)}...${address?.substring(
      address.length - 5,
      address.length
    )}`;
  },
};
export default utils;
