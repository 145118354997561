import { useEffect } from "react";
import "./Collections.scss";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import { Link } from "react-router-dom";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";

const Collections = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppBgLayout className="collections">
      <div className="collections__page-title">
        <div>
          <Title suffix="collections" inverted />
          <Text
            className="collections__title"
            text="Explore our NFT collections!"
          />
        </div>
      </div>

      <div className="collections__separator" />

      <div className="collections__collection">
        <Title
          suffix="Playnity The Genesis collection"
          className="collections__collection-title collections__collection-title"
        />
        <div className="collections__collection-container collections__collection-container--playnity-the-genesis">
          <div className="collections__buttons-wrapper">
            <a
              href="https://marketplace.luart.io/collections/terra1ewlufj2u3fmsnsehrj7c5skhadkadlppscc29v"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
            <Link to="/collections/playnity-the-genesis/leaderboard">
              Leaderboard
            </Link>
          </div>
        </div>
      </div>
      <div className="collections__separator" />

      <div className="collections__collection">
        <Title
          suffix="Thorstarter Shields collection"
          className="collections__collection-title collections__collection-title--right"
        />
        <div className="collections__collection-container collections__collection-container--thorstarter-shields">
          <div className="collections__buttons-wrapper">
            <a
              href="https://marketplace.luart.io/collections/terra10pxt36lyy6rhsumw7j8lahwvwrre7fxrfktgjl"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
            <Link to="/collections/thorstarter-shields/leaderboard">
              Leaderboard
            </Link>
          </div>
        </div>
      </div>
      <div className="collections__separator" />

      <div className="collections__collection">
        <Title
          suffix="Space cartels collection"
          className="collections__collection-title"
        />
        <div className="collections__collection-container collections__collection-container--space-cartels-amulets">
          <div className="collections__buttons-wrapper">
            <a
              href="https://marketplace.luart.io/collections/terra19relnlh7am0j9maggd4zdeed67v7jcm5k2clkr"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
            <Link to="/collections/space-cartels-amulets/leaderboard">
              Leaderboard
            </Link>
          </div>
        </div>
      </div>
      <div className="collections__separator" />
      <div className="collections__collection">
        <Title
          suffix="Robohero Avatars"
          className="collections__collection-title collections__collection-title--right"
        />
        <div className="collections__collection-container collections__collection-container--robohero-avatars">
          <div className="collections__buttons-wrapper">
            <a
              href="https://marketplace.luart.io/collections/terra1pw0x4f7ktv4vdqvx8dfaa5d2lp0t5rpzep9ewn"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
            <Link to="/collections/robohero-avatars/leaderboard">
              Leaderboard
            </Link>
          </div>
        </div>
      </div>
      <div className="collections__separator" />

      <div className="collections__collection">
        <Title suffix="Mint Chests" className="collections__collection-title" />
        <div className="collections__collection-container collections__collection-container--mint-chests">
          <div className="collections__buttons-wrapper">
            <a
              href="https://marketplace.luart.io/collections/terra12uxqcqfqrr9hrzen6pz0fx565hr8u88qfyusc0"
              target="_blank"
              rel="noreferrer"
            >
              Marketplace
            </a>
            <Link to="/collections/mint-chests/leaderboard">Leaderboard</Link>
          </div>
        </div>
      </div>
    </AppBgLayout>
  );
};

export default Collections;
