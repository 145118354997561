import { contractAddresses, harvestFees } from "../../../config";
import Text from "../../../components/Text/Text";
import spaceCartelsAmuletsUtils from "../../../utils/spaceCartelsAmuletsUtils";
import utils from "../../../utils/utils";
import Timer from "../../../components/Timer/Timer";
import { useNavigate } from "react-router";

export const useSpaceCartelsAmuletsPresenter = () => {
  const navigate = useNavigate();
  return {
    parseTokens: (tokens, network) => {
      const items = tokens.map((spaceCartelsAmulet) => {
        const spaceCartelsAmuletId = spaceCartelsAmulet.token_id;
        const spaceCartelsAmuletRarity = spaceCartelsAmulet.rarity;
        const spaceCartelsAmuletDmtBoost = `${utils.printAmount(
          spaceCartelsAmulet.dmt_boost * 100
        )}%`;
        const spaceCartelsAmuletBoostIn =
          spaceCartelsAmuletsUtils.calculateBoostIn(
            spaceCartelsAmulet.last_transferred
          );

        // Timer
        const spaceCartelsAmuletAdditionalInfo =
          spaceCartelsAmuletsUtils.getSpaceCartelsAmuletsByTokenInfo(
            spaceCartelsAmuletRarity
          );
        const spaceCartelsAmuletReward =
          spaceCartelsAmulet.pending_reward / 1_000_000;
        const spaceCartelsAmuletAttributeSection = (
          <>
            <Text
              text={`<span>Rarity:</span> ${spaceCartelsAmuletRarity}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>Dmt Boost:</span> ${spaceCartelsAmuletDmtBoost}`}
              className="dashboard__item-attribute"
            />
            <div className="dashboard__timer-wrapper">
              <p className="text dashboard__item-attribute">
                <span>Increase in:</span>
              </p>
              <Timer
                className="dashboard__timer"
                expiryTimestamp={spaceCartelsAmuletBoostIn}
                onExpire={() => {}}
                showDays
              />
            </div>
            <Text
              text={`<span>tokens inside:</span>`}
              className="dashboard__item-attribute"
            />
          </>
        );

        const contractAddress =
          contractAddresses[network.name].spaceCartelsAmulets;
        const harvestFee = harvestFees.spaceCartelsAmulets;
        return {
          tokenImage: spaceCartelsAmuletAdditionalInfo.image,
          tokenId: spaceCartelsAmuletId,
          tokenName: `Space Cartels Amulet`,
          itemAttributesSection: spaceCartelsAmuletAttributeSection,
          itemReward: spaceCartelsAmuletReward,
          contractAddress,
          harvestFee,
          onClick: () => {
            navigate(
              `/collections/space-cartels-amulets/nft/${spaceCartelsAmuletId}`,
              {
                state: {
                  tokenId: spaceCartelsAmuletId,
                  contractAddress,
                  harvestFee,
                  rarity: spaceCartelsAmuletRarity,
                },
              }
            );
          },
        };
      });

      return items;
    },
  };
};

export default useSpaceCartelsAmuletsPresenter;
