import { contractAddresses } from "../../../config";
import useSpaceCartelsAmuletsApi from "../../../hooks/useSpaceCartelsAmuletsApi";
import useTerra from "../../../hooks/useTerra";
import CollectionSale from "../../../containers/CollectionSale/CollectionSale";

import NFTImages from "../../../assets/images/SpaceCartelsAmulets";

import Text from "../../../components/Text/Text";

const SpaceCartelsAmuletsCollection = () => {
  const { network } = useTerra();
  const { getWhitelistedAddresses, getNftInfo } = useSpaceCartelsAmuletsApi();

  const description = (
    <>
      <Text
        text={`Our factory is compact and prepared to start the minting process.
              Amulets have the following utilities:`}
      />

      <ul className="collection-sale__description-benefits">
        <li>
          <Text text="<span>4 - 10%</span> more DMT from each Daily Quest completed."></Text>
        </li>
        <li>
          <Text text="Each 4 weeks of holding gives <span>0.2 - 0.4% more</span> power*."></Text>
        </li>
        <li>
          <Text text="Chance for SPC Whitelist allocation up to <span>$280</span>."></Text>
        </li>
        <li>
          <Text text="Access to a special group on Discord."></Text>
        </li>
        <li>
          <Text text="MintDAO Yield Farming."></Text>
        </li>
        <li>
          <Text text="NFT holders will get an extra chance for future whitelist allocations."></Text>
        </li>
        <li>
          <Text text="Early access to Space Cartels for testing."></Text>
        </li>
      </ul>
      <Text
        className="collection-sale__disclaimer"
        text="*Only for the first 24 weeks after the collection is minted."
      ></Text>
    </>
  );

  const chances = {
    chances: [
      { name: "mythic", chance: 0.05 },
      { name: "epic", chance: 0.25 },
      { name: "common", chance: 0.7 },
    ],
    whitelistChances: [
      { name: "mythic", chance: 0.1 },
      { name: "epic", chance: 0.35 },
      { name: "common", chance: 0.55 },
    ],
  };

  return (
    <CollectionSale
      // contract interactions
      contractAddress={contractAddresses[network.name].spaceCartelsAmulets}
      tokensAmountQuery={{ num_tokens: {} }}
      tokensAmountResultPropertyName="count"
      getWhitelistedAddresses={getWhitelistedAddresses}
      getNftInfo={getNftInfo}
      whitelistedAddressesResultPropertyName="whitelisted"
      // UI
      NFTImages={NFTImages}
      title="Space Cartels"
      titleSuffix="Amulets Collection"
      description={description}
      chances={chances}
      chancesText="a chance to draw space cartels amulet"
      denom="uusd"
      congratulationsPage="/collections/space-cartels-amulets/congratulations"
    ></CollectionSale>
  );
};

export default SpaceCartelsAmuletsCollection;
