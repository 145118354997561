import React from "react";
import "./InstantWithdrawConfirmationPopup.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { ReactComponent as Icon } from "./confirmIcon.svg";

const InstantWithdrawConfirmationPopup = ({ cancel, confirm }) => {
  return (
    <>
      <div className="instant-withdraw-confirmation-popup">
        <div className="instant-withdraw-confirmation-popup__title-container">
          <Text
            className="instant-withdraw-confirmation-popup__title"
            text="Confirmation"
          />
          <Icon className="instant-withdraw-confirmation-popup__icon" />
        </div>

        <div className="instant-withdraw-confirmation-popup__container-confirmation-item">
          <label
            htmlFor="consent1"
            className="instant-withdraw-confirmation-popup__confirmation-text"
          >
            By clicking "Confirm" I acknowledge that I understand the instant
            withdrawal rules and that <span>{`0 (0%)`}</span> amount of tokens
            will be burned.
          </label>
        </div>

        <footer className="instant-withdraw-confirmation-popup__footer">
          <Button onClick={cancel} variant="outlined">
            Cancel
          </Button>
          <Button onClick={confirm} variant="contained">
            Confirm
          </Button>
        </footer>
      </div>
    </>
  );
};

export default InstantWithdrawConfirmationPopup;
