import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import MintChestLeaderboard from "./MintChest/MintChest";
import PlaynityTheGenesisNftLeaderboard from "./PlaynityTheGenesisNft/PlaynityTheGenesisNft";
import RoboheroAvatarLeaderboard from "./RoboheroAvatar/RoboheroAvatar";
import SpaceCartelsAmuletLeaderboard from "./SpaceCartelsAmulet/SpaceCartelsAmulet";
import ThorstarterShieldLeaderboard from "./ThorstarterShield/ThorstarterShield";

const NftLeaderboard = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();

  const collectionIds = [
    "space-cartels-amulets",
    "thorstarter-shields",
    "robohero-avatars",
    "mint-chests",
    "playnity-the-genesis",
  ];

  useEffect(() => {
    if (!collectionIds.includes(collectionId)) {
      navigate("/");
    }
  });

  return (
    <>
      {collectionId === "mint-chests" && <MintChestLeaderboard />}
      {collectionId === "robohero-avatars" && <RoboheroAvatarLeaderboard />}
      {collectionId === "space-cartels-amulets" && (
        <SpaceCartelsAmuletLeaderboard />
      )}
      {collectionId === "thorstarter-shields" && (
        <ThorstarterShieldLeaderboard />
      )}
      {collectionId === "playnity-the-genesis" && (
        <PlaynityTheGenesisNftLeaderboard />
      )}
    </>
  );
};

export default NftLeaderboard;
