import { useCallback } from "react";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";
import useTerraswapApi from "./useTerraswapApi";

export const useTokenPrice = () => {
  const { network } = useTerra();
  const { getSimulation, getPool } = useTerraswapApi();
  return {
    getPrice: useCallback(async () => {
      try {
        const msgInfo = {
          token: {
            contract_addr: contractAddresses[network.name].mintToken,
          },
        };
        const tokensPrecision = 1000000;
        const simulation = await getSimulation(`${tokensPrecision}`, msgInfo);
        return simulation?.return_amount / tokensPrecision;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [getSimulation, network.name]),
    getLpPrice: useCallback(async () => {
      try {
        const pool = await getPool();
        if (pool) {
          const tokensPrecision = 1000000;
          const totalLP = pool.total_share;
          const totalMINT = pool.assets[0].amount;
          const totalUST = pool.assets[1].amount;

          const priceUst =
            Number.parseFloat((tokensPrecision / totalLP) * totalUST) * 2;
          const priceMint =
            Number.parseFloat((tokensPrecision / totalLP) * totalMINT) * 2;
          return {
            priceUst,
            priceMint,
          };
        }
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [getPool]),
  };
};

export default useTokenPrice;
