import { useCallback } from "react";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";
import useGenericCollectionHooks from "./GenericHooks/useGenericCollectionHooks";
import { useConnectedWallet } from "@terra-money/use-wallet";

export const usePlaynityTheGenesisApi = () => {
  const { network } = useTerra();
  const { queryContract } = useContractApi();
  const connectedWallet = useConnectedWallet();

  const getPlaynityTheGenesisTokens = useCallback(
    async (params) => {
      try {
        const cw721Limit = 30;
        let { owner, startAfter, limit } = params;
        let response = [];
        let chestsIds = [];
        do {
          const query = {
            owner,
            start_after: startAfter,
            limit,
          };
          if (response.length > 0) {
            query.start_after = response[response.length - 1];
          }
          const result = await queryContract(
            contractAddresses[network.name].playnityTheGenesis,
            { tokens: query }
          );
          chestsIds = result.tokens;
          limit -= chestsIds.length;
          response = response.concat(chestsIds);
        } while (limit > 0 && chestsIds.length === cw721Limit);
        if (!response || typeof response !== "object") {
          return null;
        }
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );
  const getPlaynityTheGenesisInfo = useCallback(
    async (params) => {
      try {
        const { tokenIds = [], startAfter, limit } = params;
        const response = await queryContract(
          contractAddresses[network.name].playnityTheGenesis,
          {
            get_tokens_by_ids: {
              token_ids: tokenIds,
              start_after: startAfter,
              limit,
            },
          }
        );
        if (!response || typeof response !== "object") {
          return null;
        }
        return response.tokens.sort(
          (a, b) => parseInt(a.token_id) - parseInt(b.token_id)
        );
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );

  const getPlaynityTheGenesisInfoAll = useCallback(
    async (params) => {
      try {
        let { startAfter, limit, cw721Limit = 500 } = params;
        let allTokens = [];
        let resultTokens = [];
        do {
          const query = {
            token_ids: [],
            start_after: startAfter,
            limit: cw721Limit,
          };
          if (allTokens.length > 0) {
            query.start_after = allTokens[allTokens.length - 1].token_id;
          }
          const result = await queryContract(
            contractAddresses[network.name].playnityTheGenesis,
            {
              get_tokens_by_ids: query,
            }
          );
          resultTokens = result.tokens;
          limit -= resultTokens.length;
          allTokens = allTokens.concat(resultTokens);
        } while (limit > 0 && resultTokens.length === cw721Limit);

        if (!allTokens || typeof allTokens !== "object") {
          return null;
        }
        return allTokens;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );

  const getAllPlaynityTheGenesisInfo = useCallback(
    async (params) => {
      try {
        const cw721Limit = 100;
        let { startAfter, limit } = params;
        let allShields = [];
        let shields = [];
        do {
          const query = {
            token_ids: [],
            start_after: startAfter,
            limit: cw721Limit,
          };
          if (allShields.length > 0) {
            query.start_after = allShields[allShields.length - 1].token_id;
          }
          const result = await queryContract(
            contractAddresses[network.name].playnityTheGenesis,
            {
              get_tokens_by_ids: query,
            }
          );
          shields = result.tokens;
          limit -= shields.length;
          allShields = allShields.concat(shields);
        } while (limit > 0 && shields.length === cw721Limit);

        if (!allShields || typeof allShields !== "object") {
          return null;
        }
        return allShields;
      } catch (err) {
        console.log(err);
      }
      return null;
    },
    [queryContract, network.name]
  );
  return {
    getWalletInfo: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].playnityTheGenesisMintManager,
          { get_wallet_info: { wallet: connectedWallet?.walletAddress } }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name, connectedWallet?.walletAddress]),
    getNftInfo: useCallback(
      async (tokenId) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].playnityTheGenesis,
            { nft_info: { token_id: tokenId } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getOwner: useCallback(
      async (tokenId) => {
        try {
          const response = await queryContract(
            contractAddresses[network.name].playnityTheGenesis,
            { owner_of: { token_id: tokenId } }
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, network.name]
    ),
    getState: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].playnityTheGenesis,
          { get_state: {} }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getCollectionConfig: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].playnityTheGenesis,
          { get_config: {} }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),
    getMintManagerConfig: useCallback(async () => {
      try {
        const response = await queryContract(
          contractAddresses[network.name].playnityTheGenesisMintManager,
          { get_config: {} }
        );
        return response;
      } catch (err) {
        console.log(err);
      }
      return null;
    }, [queryContract, network.name]),

    getPlaynityTheGenesisTokens,
    getPlaynityTheGenesisInfo,
    getAllPlaynityTheGenesisInfo,
    getPlaynityTheGenesisInfoAll,
    ...useGenericCollectionHooks(
      getPlaynityTheGenesisTokens,
      getPlaynityTheGenesisInfo,
      1000,
      10000
    ),
  };
};

export default usePlaynityTheGenesisApi;
