import "./StaticSlider.scss";
import { ReactComponent as Indicator } from "../Counter/indicator.svg";

const StaticSlider = ({ current = 0 }) => {
  return (
    <div className="static-slider" style={{ "--percent": `${current}%` }}>
      <div className="static-slider__track">
        <div className="static-slider__fill" />
      </div>

      <ul className="static-slider__items">
        {Array(11)
          .fill(null)
          .map((item, idx) => idx * 10)
          .map((item) => {
            return (
              <li
                key={`item__${item}`}
                className={`
                  static-slider__item 
                  ${item < current ? "static-slider__item--before" : ""}
                  ${item > current ? "static-slider__item--after" : ""}
                  ${item === current ? "static-slider__item--current" : ""}
                `}
              >
                <div className="static-slider__step">{item}</div>
              </li>
            );
          })}
      </ul>

      <div key="item__current" className="static-slider__current">
        <div className="static-slider__title">Here you are</div>
        <Indicator className="static-slider__indicator" />
      </div>
    </div>
  );
};

export default StaticSlider;
