import Modal from "../Modal/Modal";
import LoadingPopup from "../LoadingPopup/LoadingPopup";
import Text from "../Text/Text";
import TxHash from "../TxHash/TxHash";

const TxModal = ({
  loading,
  txError,
  setModalOpen,
  txInfo,
  connectedWallet,
}) => {
  return (
    <Modal
      id="modal"
      type={loading ? "warning" : txError ? "error" : "success"}
      onCloseClick={() => {
        setModalOpen(false);
      }}
      showCloseButton
    >
      <LoadingPopup
        status={loading ? "loading" : txError ? "error" : "success"}
        loadingComponent={
          <>
            {!txInfo ? (
              <Text
                className="loading-popup__copy"
                text="Approve transaction"
              />
            ) : (
              <>
                <TxHash
                  txhash={txInfo?.result.txhash}
                  network={connectedWallet?.network.name}
                ></TxHash>
              </>
            )}
          </>
        }
        successComponent={
          <TxHash
            txhash={txInfo?.result.txhash}
            network={connectedWallet?.network.name}
          ></TxHash>
        }
        errorMsg={txError ? txError.toString() : null}
      />
    </Modal>
  );
};

export default TxModal;
