import "./Menu.scss";
import Container from "../Container/Container";
import { ReactComponent as Logo } from "./logo.svg";
import { useNavigate } from "react-router-dom";

const Menu = ({ isOpen, onItemClick }) => {
  const navigate = useNavigate();

  return (
    <div className={`menu ${isOpen ? "menu--visible" : ""}`}>
      <Container className="menu__wrapper">
        <Logo className="menu__logo" />
        <ul className="menu__list">
          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("stake");
            }}
          >
            Stake
          </li>
          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("trade");
            }}
          >
            Trade
          </li>
          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("treasury");
            }}
          >
            Treasury
          </li>
          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("collections");
            }}
          >
            Collections
          </li>

          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("leaderboard");
            }}
          >
            Leaderboard
          </li>

          <li
            className="menu__item"
            onClick={() => {
              onItemClick();
              navigate("dashboard");
            }}
          >
            Dashboard
          </li>
        </ul>
      </Container>
    </div>
  );
};

export default Menu;
