import React, { useEffect, useRef } from "react";
import "./LeaderboardContainer.scss";
import Container from "../../components/Container/Container";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import { ReactComponent as Logo } from "../../assets/images/logo_shape.svg";
import useOnScreen from "../../hooks/useOnScreen";
import TextInput from "../../components/TextInput/TextInput";
import MessageBar from "../../components/MessageBar/MessageBar";
import { TableVirtuoso } from "react-virtuoso";
import Table from "../../components/Table/Table";

const LeaderboardContainer = ({
  collectionName,
  renderRow,
  renderListHeaders,
  tokenCollection,
  error,
  filter,
  setFilter,
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="leaderboard" ref={ref}>
      <Logo
        className={`leaderboard__logo ${
          isVisible ? "leaderboard__logo--visible" : ""
        }`}
      />
      <Container className="leaderboard__container" narrow>
        <div className="leaderboard__page-title">
          <div>
            <Title suffix="Leaderboard" inverted />
            <Text className="leaderboard__title" text="Our NFT leaderboard." />
          </div>
        </div>
        <div className="leaderboard__separator" />
        <div className="leaderboard__selected-collection">
          <Title text={collectionName} inverted />
        </div>
        {error && <MessageBar text={error} type="error" />}
        {tokenCollection !== null ? (
          <div className="leaderboard__my-nfts">
            <div className="leaderboard__my-nfts-filter">
              <TextInput
                placeholder="Filter by token ID"
                handleChange={(newFilter) => setFilter(newFilter)}
              />
            </div>
          </div>
        ) : null}
        <TableVirtuoso
          useWindowScroll
          className={"leaderboard__virtual-list"}
          components={{
            Table: ({ className, children }) => (
              <Table className={className}>
                <thead>{renderListHeaders()}</thead>
                {children}
              </Table>
            ),
            TableRow: (props) => {
              return <>{renderRow(props)}</>;
            },
          }}
          totalCount={
            filter
              ? tokenCollection?.filter((item) =>
                  item.token_id.toString().includes(filter)
                ).length
              : tokenCollection?.length || 0
          }
        />
      </Container>
    </div>
  );
};

export default LeaderboardContainer;
