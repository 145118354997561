import React, { useEffect, useState } from "react";
import useLeaderboardHooks from "../hooks/LeaderboardHooks";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../../hooks/useTerra";
import utils from "../../../utils/utils";
import leaderboardUtils from "../utils/LeaderboardUtils";
import LeaderboardContainer from "../../../containers/LeaderboardContainer/LeaderboardContainer";
import useRoboheroApi from "../../../hooks/useRoboheroApi";

const RoboheroAvatarLeaderboard = () => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getRoboheroTokens, getRoboheroTokensInfo } = useRoboheroApi();
  const { fetchCollectionData } = useLeaderboardHooks();
  const [tokenCollection, setTokenCollection] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ownedTokensIds, setOwnedTokensIds] = useState([]);
  const [error, setError] = useState(null);
  const [sortMethod, setSortMethod] = useState("pending_reward");
  const [sortDir, setSortDir] = useState("desc");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      await fetchCollectionData(
        getRoboheroTokensInfo,
        getRoboheroTokens,
        setTokenCollection,
        setOwnedTokensIds,
        setError
      );
    }, 10000);
    return () => clearInterval(intervalId);
  }, [
    connectedWallet,
    network.name,
    getRoboheroTokensInfo,
    getRoboheroTokens,
    fetchCollectionData,
  ]);

  const onHeaderClick = (columnName) => {
    if (sortMethod === columnName) {
      if (sortDir === "asc") {
        setSortDir("desc");
      } else {
        setSortDir("asc");
      }
    }

    setSortMethod(columnName);
  };

  const headersArr = [
    { key: "rarity", title: "Rarity" },
    { key: "token_id", title: "Avatar ID" },
    { key: "faction", title: "Faction" },
    { key: "pending_reward", title: "tokens inside" },
  ];

  const rowData = (props, index, tokenData) => {
    const tokenReward = tokenData.pending_reward / 1_000_000;
    const tokenId = tokenData.token_id;
    const rarity = tokenData.rarity;
    const faction = tokenData.faction;

    return (
      <tr {...props}>
        <td style={{ "--title": "'No.'" }}>{index + 1}</td>
        <td style={{ "--title": "'Rarity'" }}>
          {`${rarity ? rarity : "Minting..."}`}
        </td>
        <td style={{ "--title": "'Avatar ID'" }}>{`#${tokenId}`}</td>
        <td style={{ "--title": "'Faction'" }}>{`${faction}`}</td>
        <td style={{ "--title": "'Tokens inside'" }}>
          {`${utils.addSeparatorsToNumber(
            Number.parseFloat(tokenReward).toFixed(2)
          )}`}{" "}
        </td>
      </tr>
    );
  };

  return (
    <LeaderboardContainer
      collectionName="Robohero Avatars"
      renderRow={leaderboardUtils.renderRow(
        tokenCollection,
        filter,
        rowData,
        sortMethod,
        sortDir
      )}
      renderListHeaders={leaderboardUtils.renderListHeaders(
        headersArr,
        sortMethod,
        sortDir,
        onHeaderClick
      )}
      tokenCollection={tokenCollection}
      error={error}
      filter={filter}
      setFilter={setFilter}
    />
  );
};

export default RoboheroAvatarLeaderboard;
