import SmallTitle from "../../components/SmallTitle/SmallTitle";
import Text from "../../components/Text/Text";
import Timer from "../../components/Timer/Timer";
import useTerraWalletToETHApi from "../../hooks/useTerraWalletToETHApi";
import utils from "../../utils/utils";
import AppBgLayout from "../AppBgLayout/AppBgLayout";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import Title from "../Title/Title";
import TxModal from "../TxModal/TxModal";
import "./TerraWalletToETH.scss";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import { useCallback, useEffect, useState } from "react";

const EXPIRY_REGISTERING_DATE = new Date("2022-09-15T15:00:00.000Z");

const TerraWalletToETH = () => {
  const [ethAddress, setEthAddress] = useState("");
  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [registeringAvailable] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [contractETHAddress, setContractETHAddress] = useState("");
  const connectedWallet = useConnectedWallet();

  const { setEthAddressTx, getETHAddress } = useTerraWalletToETHApi();

  const setEthAddressCall = async () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
    setLoading(true);
    const txResult = await setEthAddressTx(ethAddress, setTxInfo, setTxError);
    setLoading(false);
    console.log(txResult);
    getContractETHAddress();
  };

  const getContractETHAddress = useCallback(async () => {
    const contractQuery = await getETHAddress(connectedWallet?.walletAddress);
    if (contractQuery) {
      setContractETHAddress(contractQuery);
    }
  }, [connectedWallet?.walletAddress, getETHAddress]);

  useEffect(() => {
    getContractETHAddress();
  }, [connectedWallet?.walletAddress, getContractETHAddress]);

  return (
    <AppBgLayout displayBackgroundLogo={true}>
      <div className="terra-wallet-to-eth">
        {isModalOpen && (
          <TxModal
            loading={loading}
            txError={txError}
            setModalOpen={setModalOpen}
            txInfo={txInfo}
            connectedWallet={connectedWallet}
          />
        )}
        <div className="terra-wallet-to-eth__message">
          <Title text="Terra" suffix="Backup" />
          <Text
            text={`Dear user, please provide your EVM compatible wallet, it will be associated with your Terra wallet. Currently, we are working on our recovery plan. Based on that address you will receive Mint tokens on another chain. Stay strong!`}
          />
        </div>
        <div>
          <SmallTitle
            style={{ fontSize: 14, color: "#878787" }}
            text={`Registering available for:`}
          />
          <Timer
            expiryTimestamp={EXPIRY_REGISTERING_DATE}
            onExpire={() => {}}
            showDays={true}
          ></Timer>
        </div>
        <div className="terra-wallet-to-eth__eth-address">
          <Text
            className="terra-wallet-to-eth__eth-address-text"
            text={`Current ETH address:`}
          />
          <Text
            className="terra-wallet-to-eth__eth-address-value"
            text={
              contractETHAddress?.data
                ? utils.shortAddress(contractETHAddress?.data)
                : "None"
            }
          />
          {registeringAvailable && (
            <>
              <TextInput
                className="terra-wallet-to-eth__eth-address-input"
                placeholder="Set Your ETH Address…"
                handleChange={(value) => setEthAddress(value)}
                value={ethAddress}
              />
              {ethAddress !== "" && !/^0x[a-fA-F0-9]{40}$/.test(ethAddress) && (
                <Text
                  className="terra-wallet-to-eth__eth-address-error"
                  text={`Provided address is invalid`}
                />
              )}
              <Button
                variant="contained"
                className="terra-wallet-to-eth__button"
                fullWidth
                onClick={setEthAddressCall}
                disabled={!/^0x[a-fA-F0-9]{40}$/.test(ethAddress)}
              >
                Set ETH Address
              </Button>
            </>
          )}
        </div>
      </div>
    </AppBgLayout>
  );
};

export default TerraWalletToETH;
