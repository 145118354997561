import Title from "../Title/Title";
import "./SmallTitle.scss";

const SmallTitle = ({ style, className, text }) => {
  return (
    <Title style={style} className={`small-title ${className}`} text={text} />
  );
};

export default SmallTitle;
