import { useCallback } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { MsgExecuteContract } from "@terra-money/terra.js";
import useContractApi from "./useContractApi";
import { contractAddresses } from "../config";
import useTerra from "./useTerra";

export const useAirdropApi = () => {
  const { network } = useTerra();
  const connectedWallet = useConnectedWallet();
  const { queryContract, executeContractAndWaitForResult } = useContractApi();
  const airdropContractAddress = contractAddresses[network.name].airdrop;
  return {
    getAirdropUserInfo: useCallback(
      async (address) => {
        try {
          const response = await queryContract(airdropContractAddress, {
            airdrop_user_info: {
              address,
            },
          });
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [queryContract, airdropContractAddress]
    ),
    claimAirdrop: useCallback(
      async (setLoading, setTxInfo, setTxError) => {
        try {
          setLoading(true);
          const msgs = [
            new MsgExecuteContract(
              connectedWallet?.walletAddress,
              airdropContractAddress,
              {
                claim_airdrop: {},
              },
              { uusd: 1000000 }
            ),
          ];
          const response = await executeContractAndWaitForResult(
            msgs,
            setTxInfo,
            setTxError
          );
          setLoading(false);

          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [
        connectedWallet?.walletAddress,
        executeContractAndWaitForResult,
        airdropContractAddress,
      ]
    ),
  };
};

export default useAirdropApi;
