import "./Box.scss";

const Box = ({ children, className }) => (
  <div className={`box ${className}`}>
    <div className="box__border box__border--outer">
      <div className="box__border box__border--inner">{children}</div>
    </div>
  </div>
);

export default Box;
