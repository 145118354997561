import Text from "../../components/Text/Text";
import "./ErrorFallback.scss"; 
import AppBgLayout from "../AppBgLayout/AppBgLayout";
import Title from "../Title/Title";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const ErrorFallback = ({resetErrorBoundary}) => {
  const navigate = useNavigate()


  return (
    <AppBgLayout displayBackgroundLogo={true}>
      <div className="terra-wallet-to-eth">
        
        <div className="terra-wallet-to-eth__message">
          <Title text="Error" suffix="Ocurred" />
          <Text
            text={`Dear user, We have caught an unexpected error. Are you connected to the right network?`}
          />
          
          <Button
            variant="contained"
            className="terra-wallet-to-eth__button"
            fullWidth
            onClick={() => {
              navigate("/");
              resetErrorBoundary()
            }} 
          >
            Take me from here!
          </Button>
        </div>
      </div>
    </AppBgLayout>
  );
};

export default ErrorFallback;
