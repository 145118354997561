import "./BuyCollectionNft.scss";

import { MsgExecuteContract } from "@terra-money/terra.js";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import React, { useEffect, useRef, useCallback, useState } from "react";
import Button from "../../components/Button/Button";
import useContractApi from "../../hooks/useContractApi";
import utils from "../../utils/utils";
import Modal from "../../components/Modal/Modal";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { useNavigate } from "react-router-dom";
import Text from "../../components/Text/Text";
import MessageBar from "../../components/MessageBar/MessageBar";
import ConfirmationPopup from "../../components/ConfirmationPopup/ConfirmationPopup";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getDatabase, ref, set } from "firebase/database";

export function BuyCollectionNft({
  walletInfo,
  availableTokens,
  getNftInfo,
  mintManagerAddress,
  congratulationsPage,
  returnedDrawedAttrName,
  attrValueThatHasToChange,
  mintEvent,
}) {
  const navigate = useNavigate();
  const [mintingState, setMintingState] = useState("beforeMint");
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [txError, setTxError] = useState(null);
  const connectedWallet = useConnectedWallet();
  const { executeContractAndWaitForResult } = useContractApi();
  const isModalOpenRef = useRef(isModalOpen);
  const modalOpenForTokenIdRef = useRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    isModalOpenRef.current = isModalOpen;
  }, [isModalOpen]);

  const proceed = useCallback(async () => {
    if (!connectedWallet) {
      return;
    }

    const db = getDatabase();
    set(
      ref(db, `users/${connectedWallet.walletAddress}/consents/privacy_policy`),
      true
    );
    set(
      ref(db, `users/${connectedWallet.walletAddress}/consents/terms_of_use`),
      true
    );

    setTxError(null);
    setModalOpen(true);
    setMintingState("minting requested");
    const coins = {};
    if (walletInfo?.current_price > 0) {
      coins[walletInfo?.denom] = walletInfo?.current_price;
    }
    const msgs = [
      new MsgExecuteContract(
        connectedWallet.walletAddress,
        mintManagerAddress,
        {
          mint: {},
        },
        coins
      ),
    ];
    modalOpenForTokenIdRef.current = null;
    const txResult = await executeContractAndWaitForResult(
      msgs,
      () => setMintingState("minting"),
      setTxError
    );
    if (txResult === null || txError !== null || txResult?.code !== 0) {
      setModalOpen(false);
      return;
    }

    mintEvent();
    const tokenId = txResult?.logs[0]?.events[3]?.attributes?.filter(
      (attr) => attr.key === "token_id"
    )?.[0].value;
    modalOpenForTokenIdRef.current = tokenId;
    setMintingState("drawing");
    utils.delegateIntervalTask(async () => {
      const tokenInfo = await utils.wrapWithRetries(
        () => getNftInfo(tokenId),
        20
      );
      const returnedDrawedAttr = tokenInfo?.extension?.attributes.filter(
        (x) => x.trait_type === returnedDrawedAttrName
      );
      if (returnedDrawedAttr.length === 0) {
        return false;
      }
      if (returnedDrawedAttr[0].value !== attrValueThatHasToChange) {
        if (
          isModalOpenRef.current &&
          modalOpenForTokenIdRef.current === tokenId
        ) {
          setMintingState("minted");
          setTimeout(() => {
            setModalOpen(false);
            navigate(congratulationsPage + `/${tokenId}`, {
              state: { congratulations: true },
            });
          }, 2000);
        } else {
          enqueueSnackbar({
            text: "NFT with id " + tokenId + " minted successfuly!",
            variant: "success",
            action: (key) => (
              <Button
                onClick={() => {
                  navigate(congratulationsPage + `/${tokenId}`, {
                    state: { congratulations: true },
                  });
                  closeSnackbar(key);
                }}
              >
                Check
              </Button>
            ),
          });
        }
        return true;
      }
      return false;
    });
  }, [
    connectedWallet,
    walletInfo,
    executeContractAndWaitForResult,
    txError,
    getNftInfo,
    navigate,
    congratulationsPage,
    enqueueSnackbar,
    closeSnackbar,
    mintManagerAddress,
    mintEvent,
    attrValueThatHasToChange,
    returnedDrawedAttrName,
  ]);
  return (
    <div>
      {connectedWallet?.availablePost && (
        <>
          <Button
            disabled={
              availableTokens === 0 ||
              walletInfo?.minted_tokens >= walletInfo?.max_tokens
            }
            fullWidth
            variant="contained"
            onClick={() => setConfirmationModalOpen(true)}
          >
            {walletInfo?.minted_tokens >= walletInfo?.max_tokens
              ? "This wallet cannot mint more tokens"
              : "Buy Your NFT"}
          </Button>
          {availableTokens === 0 && (
            <Text
              className="buytoken__all-tokens-sold"
              text="All tokens were sold!"
            />
          )}
        </>
      )}

      {isConfirmationModalOpen && (
        <Modal
          id="modal"
          onCloseClick={() => setConfirmationModalOpen(false)}
          showCloseButton
        >
          <ConfirmationPopup
            onConfirm={() => {
              setConfirmationModalOpen(false);
              proceed();
            }}
            onCancel={() => setConfirmationModalOpen(false)}
          />
        </Modal>
      )}

      {isModalOpen && (
        <Modal
          id="modal"
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <ProgressBar progress={mintingState} />
          {mintingState === "drawing" && (
            <Grid
              className="buytoken__move_to_background"
              justifyContent="center"
              container
            >
              <Button
                onClick={() => {
                  setModalOpen(false);
                }}
                variant="contained"
              >
                Move to background
              </Button>
            </Grid>
          )}
        </Modal>
      )}

      {txError && <MessageBar text={txError} type="error" />}
    </div>
  );
}
