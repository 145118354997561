import React, { useState, useEffect } from "react";
import "./ClaimMint.scss";
import Title from "../../components/Title/Title";
import Text from "../../components/Text/Text";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { ReactComponent as MintLogo } from "../../assets/images/mint-logo.svg";
import Button from "../../components/Button/Button";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import StaticSlider from "../../components/StaticSlider/StaticSlider";
import useTerra from "../../hooks/useTerra";
import useVestingApi from "../../hooks/useVestingApi";
import { tokensPrecision } from "../../config";
import utils from "../../utils/utils";
import { useSnackbar } from "notistack";
import Modal from "../../components/Modal/Modal";
import LoadingPopup from "../../components/LoadingPopup/LoadingPopup";
import TxHash from "../../components/TxHash/TxHash";

const ClaimMint = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [vestingInfo, setVestingInfo] = useState(null);
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getVestingAccount, claimTokens } = useVestingApi();

  const [txInfo, setTxInfo] = useState(null);
  const [txError, setTxError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      const vestingInfo = async () => {
        const vestingInfoResponse = await getVestingAccount(
          connectedWallet.walletAddress
        );
        if (vestingInfoResponse) {
          setVestingInfo(vestingInfoResponse);
        } else {
          enqueueSnackbar({
            text: "There was a problem while fetching vesting info!",
            variant: "error",
          });
          await utils.delay(30000);
        }
      };
      if (connectedWallet) {
        await vestingInfo();
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [network.name, connectedWallet, getVestingAccount, enqueueSnackbar]);

  const calculateVestingCompletion = () => {
    if (vestingInfo) {
      const claimableAmount =
        parseInt(vestingInfo?.claimable_amount) / tokensPrecision;
      const alreadyClaimed =
        parseInt(vestingInfo?.info?.claimed_amount) / tokensPrecision;
      const total = parseInt(vestingInfo?.info?.total_amount) / tokensPrecision;
      const result = ((claimableAmount + alreadyClaimed) / total) * 100;
      if (isNaN(result)) {
        return 0;
      }
      return result;
    }
    return 0;
  };

  const beforeTransaction = () => {
    setModalOpen(true);
    setTxInfo(null);
    setTxError(null);
  };
  const claim = () => {
    beforeTransaction();
    claimTokens(setLoading, setTxInfo, setTxError);
  };

  return (
    <AppBgLayout>
      {isModalOpen && (
        <Modal
          id="modal"
          type={loading ? "warning" : txError ? "error" : "success"}
          onCloseClick={() => {
            setModalOpen(false);
          }}
          showCloseButton
        >
          <LoadingPopup
            status={loading ? "loading" : txError ? "error" : "success"}
            loadingComponent={
              <>
                {!txInfo ? (
                  <Text
                    className="loading-popup__copy"
                    text="Approve transaction"
                  />
                ) : (
                  <>
                    <TxHash
                      txhash={txInfo?.result.txhash}
                      network={connectedWallet?.network.name}
                    ></TxHash>
                  </>
                )}
              </>
            }
            successComponent={
              <TxHash
                txhash={txInfo?.result.txhash}
                network={connectedWallet?.network.name}
              ></TxHash>
            }
            errorMsg={txError ? txError.toString() : null}
          />
        </Modal>
      )}

      <div className="claim-mint__title-container">
        <Title
          text="claim your"
          suffix="mint"
          inverted
          className="claim-mint__title"
        />
        <Text text="Remember that your vesting may have a cliff." />
        <Text text="Claiming fee 1 UST" />
      </div>

      <div className="claim-mint__inputs">
        <div>
          <label className="claim-label" htmlFor="from">
            <div>
              <input
                type="text"
                className="claim-label__input"
                value={(
                  parseInt(vestingInfo?.claimable_amount) / tokensPrecision || 0
                ).toFixed(2)}
                readOnly
                id="from"
              />
              <p className="claim-label__title">
                Available <span>| MINT</span>
              </p>
            </div>
            <p className="claim-label__currency">
              MINT <MintLogo />
            </p>
            <Button
              onClick={claim}
              variant="contained"
              className="claim-label__button"
              disabled={!vestingInfo || vestingInfo?.claimable_amount === "0"}
            >
              Claim
            </Button>
          </label>
        </div>

        <div>
          <label className="claim-label" htmlFor="to">
            <div>
              <input
                type="text"
                className="claim-label__input"
                value={`${(
                  parseInt(vestingInfo?.info?.claimed_amount) /
                    tokensPrecision || 0
                ).toFixed(2)} / ${(
                  parseInt(vestingInfo?.info?.total_amount) / tokensPrecision ||
                  0
                ).toFixed(2)}`}
                readOnly
                id="to"
              />
              <p className="claim-label__title">
                Withdrawn <span>| MINT</span>
              </p>
            </div>
            <p className="claim-label__currency">
              MINT <MintLogo />
            </p>
          </label>
        </div>

        <Button
          onClick={claim}
          variant="contained"
          fullWidth
          className="claim-mint__button"
          disabled={!vestingInfo || vestingInfo?.claimable_amount === "0"}
        >
          Claim Now
        </Button>
      </div>

      <div className="claim-mint__details">
        <StaticSlider current={calculateVestingCompletion()} />
      </div>
    </AppBgLayout>
  );
};

export default ClaimMint;
