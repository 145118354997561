import "./Tooltip.scss";

const Tooltip = ({ text, children }) => (
  <div className="tooltip">
    {children}
    <div className="tooltiptext">{text}</div>
  </div>
);

export default Tooltip;
