import "./Text.scss";

const Text = ({ text, className }) => {
  return (
    <p
      className={`text ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default Text;
