import { contractAddresses, harvestFees } from "../../../config";
import Text from "../../../components/Text/Text";
import ThorstarterShieldsUtils from "../../../utils/ThorstarterShieldsUtils";
import { useNavigate } from "react-router";

export const useThorstarterShieldsPresenter = () => {
  const navigate = useNavigate();

  return {
    parseTokens: (tokens, network) => {
      const items = tokens.map((thorstarterShiled) => {
        const thorstarterShiledId = thorstarterShiled.token_id;
        let thorstarterShiledLevel = thorstarterShiled.level;
        const thorstarterShiledAdditionalInfo =
          ThorstarterShieldsUtils.getThorstarterShieldsByTokenInfo(
            thorstarterShiledLevel
          );

        const thorstarterShiledReward =
          thorstarterShiled.pending_reward / 1_000_000;
        const thorstarterShiledAttributeSection = (
          <>
            <Text
              text={`<span>Shield level:</span> ${thorstarterShiledAdditionalInfo.levelNumber}`}
              className="dashboard__item-attribute"
            />
            <Text
              text={`<span>tokens inside:</span>`}
              className="dashboard__item-attribute"
            />
          </>
        );

        const contractAddress =
          contractAddresses[network.name].thorstarterShields;
        const harvestFee = harvestFees.thorstarterShields;
        return {
          tokenImage: thorstarterShiledAdditionalInfo.image,
          tokenId: thorstarterShiledId,
          tokenName: `Thorstarter Shield`,
          itemAttributesSection: thorstarterShiledAttributeSection,
          itemReward: thorstarterShiledReward,
          contractAddress,
          harvestFee,
          onClick: () => {
            navigate(
              `/collections/thorstarter-shields/nft/${thorstarterShiledId}`,
              {
                state: {
                  tokenId: thorstarterShiledId,
                  contractAddress,
                  harvestFee,
                  selectedLevel: thorstarterShiledLevel,
                },
              }
            );
          },
        };
      });

      return items;
    },
  };
};

export default useThorstarterShieldsPresenter;
