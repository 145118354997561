import React, { useEffect, useState } from "react";
import "./StakingLeaderboard.scss";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import { ReactComponent as Cup } from "./cup.svg";
import { ReactComponent as Arrows } from "./arrows.svg";
import useUSTRewardsApi from "../../hooks/useUSTRewardsApi";
import { useConnectedWallet } from "@starterra/starterra-tool-dapp";
import useTerra from "../../hooks/useTerra";
import utils from "../../utils/utils";
import AppBgLayout from "../../components/AppBgLayout/AppBgLayout";
import SmallTitle from "../../components/SmallTitle/SmallTitle";
import { TableVirtuoso } from "react-virtuoso";
import Table from "../../components/Table/Table";

const StakingLeaderboard = () => {
  const connectedWallet = useConnectedWallet();
  const { network } = useTerra();
  const { getAllStakersInfo } = useUSTRewardsApi();
  const [stakers, setStakers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(async () => {
      const errorCheck = (arg) =>
        arg === null
          ? setError("There was a problem while fetching the stakers.")
          : setError(null);

      const stakersResponse = await getAllStakersInfo({
        limit: 200,
      });
      errorCheck(stakersResponse);
      setStakers(stakersResponse);
    }, 60000);
    return () => clearInterval(intervalId);
  }, [connectedWallet, network.name, getAllStakersInfo]);

  const renderHash = (hash, length = 5) => (
    <p title={hash}>{`${hash.slice(0, length)}...${hash.slice(-length)}`}</p>
  );

  const sortedStakers = stakers
    ?.sort((a, b) => (+a.mint_power < +b.mint_power ? 1 : -1))
    .map((row, index) => ({ ...row, index: index + 1 }));

  const yourPosition = sortedStakers.filter(
    (item) => item.staker === connectedWallet?.walletAddress
  );

  const renderRow = (row, props) => (
    <tr
      {...props}
      key={row.index}
      className={`staking-leaderboard__row ${
        row.staker === connectedWallet?.walletAddress
          ? "staking-leaderboard__row--yours"
          : ""
      }`}
    >
      <td style={{ "--title": "'NO.'" }}>
        {row.index.toString().padStart(2, "0")}
        {row.index <= 3 && <Cup className="staking-leaderboard__cup" />}
      </td>
      <td style={{ "--title": "'wallet adress'" }}>{renderHash(row.staker)}</td>
      <td style={{ "--title": "'mint power'" }}>
        {utils.addSeparatorsToNumber((row.mint_power / 1000000).toFixed(2))}
      </td>
      <td style={{ "--title": "'total distributed UST'" }}>
        {`${utils.printAmount(
          +row.total_claimed_reward + +row.pending_reward
        )} UST`}
      </td>
    </tr>
  );

  const TableWithHeader = ({ children, className }) => (
    <Table className={className}>
      <thead>
        <tr>
          <th>NO.</th>
          <th>wallet address</th>
          <th>
            mint power <Arrows className="staking-leaderboard__arrows" />
          </th>
          <th>total distributed UST</th>
        </tr>
      </thead>
      {children}
    </Table>
  );

  return (
    <AppBgLayout className="staking-leaderboard">
      <Title
        className="staking-leaderboard__title"
        suffix="Staking Leaderboard"
        inverted
      />
      <Text
        className="staking-leaderboard__copy"
        text="Top Mint Power leaderboard"
      />

      {/* LOADING */}
      {!stakers.length && (
        <SmallTitle
          className="staking-leaderboard__header"
          text="Loading Leaderboard, please wait..."
        />
      )}

      {stakers.length > 0 && (
        <>
          {/* YOUR POSITION TABLE */}
          {yourPosition.length > 0 && (
            <>
              <SmallTitle
                className="staking-leaderboard__header"
                text="your position"
              />
              <TableWithHeader className="staking-leaderboard__table">
                <tbody>{yourPosition.map(renderRow)}</tbody>
              </TableWithHeader>
            </>
          )}

          {/* LEADERBOARD */}
          <SmallTitle
            className="staking-leaderboard__header"
            text="leaderboard"
          />
          <TableVirtuoso
            className="staking-leaderboard__table"
            useWindowScroll
            data={sortedStakers.filter((item, index) => index < 100)}
            components={{
              Table: TableWithHeader,
              TableRow: (props) => {
                const rowIndex = props["data-index"];
                const row = sortedStakers[rowIndex];
                return renderRow(row, props);
              },
            }}
          />
        </>
      )}
    </AppBgLayout>
  );
};

export default StakingLeaderboard;
