import useTerra from "./useTerra";
import {
  CreateTxFailed,
  Timeout,
  TxFailed,
  TxUnspecifiedError,
  useConnectedWallet,
  UserDenied,
} from "@terra-money/wallet-provider";
import utils from "../utils/utils";
import { useCallback, useEffect, useState } from "react";
export const useContractApi = () => {
  const { terra } = useTerra();
  const connectedWallet = useConnectedWallet();

  const [gasPrices, setGasPrices] = useState({
    uluna: "0.01133",
    uusd: "0.15",
  });

  useEffect(() => {
    const getGasPrices = async () => {
      if (!connectedWallet) {
        return;
      }
      const url =
        connectedWallet.network.name !== "testnet"
          ? "https://fcd.terra.dev/v1/txs/gas_prices"
          : "https://bombay-fcd.terra.dev/v1/txs/gas_prices";

      const gasOfficialPrices = await (await fetch(url))?.json();
      setGasPrices(gasOfficialPrices);
    };
    getGasPrices();
  }, [connectedWallet, connectedWallet?.network.name]);

  return {
    queryContract: useCallback(
      async (queryContractAddress, queryMessage = {}) => {
        try {
          const response = await terra.wasm.contractQuery(
            queryContractAddress,
            queryMessage
          );
          return response;
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [terra]
    ),
    executeContract: useCallback(
      async (msgs) => {
        try {
          if (connectedWallet) {
            const executeTx = await connectedWallet.post({
              msgs,
            });
            return executeTx;
          }
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [connectedWallet]
    ),
    executeContractAndWaitForResult: useCallback(
      async (
        msgs,
        changeStateAfterBroadcast,
        setTxError,
        refreshInterval = 1000
      ) => {
        try {
          if (connectedWallet) {
            const [coins] = await terra.bank.balance(
              connectedWallet.walletAddress
            );

            const estimateFee = async (msg, feeDenom) => {
              const signMsg = await terra.tx.create(
                [{ address: connectedWallet.walletAddress }],
                {
                  msgs: msg,
                  feeDenoms: [feeDenom],
                  gasPrices: { [feeDenom]: gasPrices[feeDenom] },
                }
              );
              return signMsg.auth_info.fee;
            };

            let fee = undefined;
            try {
              if (!fee && coins.get("uusd")) {
                const estimatedFee = await estimateFee(msgs, "uusd");
                if (
                  parseInt(`${estimatedFee.amount.get("uusd").amount}`, 10) <=
                  parseInt(`${coins.get("uusd").amount}`, 10)
                ) {
                  fee = estimatedFee;
                }
              }

              if (!fee && coins.get("uluna")) {
                const estimatedFee = await estimateFee(msgs, "uluna");
                if (
                  parseInt(`${estimatedFee.amount.get("uluna").amount}`, 10) <=
                  parseInt(`${coins.get("uluna").amount}`, 10)
                ) {
                  fee = estimatedFee;
                }
              }
            } catch (err) {
              console.warn("Preestimate fee failed");
            }
            const tx = {
              msgs,
            };
            if (fee) {
              tx.fee = fee;
            }
            const executeTx = await connectedWallet.post(tx);
            changeStateAfterBroadcast(executeTx);
            const result = await utils.delegateIntervalTask(async () => {
              try {
                const txResult = await terra.tx.txInfo(executeTx.result.txhash);
                if (txResult) {
                  return txResult;
                }
              } catch {
                return false;
              }
            }, refreshInterval);
            return result;
          }
        } catch (error) {
          console.log(error);
          if (error instanceof UserDenied) {
            setTxError("The user has denied the transaction.");
          } else if (error instanceof CreateTxFailed) {
            setTxError("Could not create the transaction: " + error.message);
          } else if (error instanceof TxFailed) {
            setTxError("Transaction Failed: " + error.message);
          } else if (error instanceof Timeout) {
            setTxError("The time limit has been exceeded");
          } else if (error instanceof TxUnspecifiedError) {
            setTxError("Unspecified error occured: " + error.message);
          } else {
            setTxError(
              "Unknown error occured: " +
                (error instanceof Error ? error.message : String(error))
            );
          }
        }
        return null;
      },
      [terra, connectedWallet, gasPrices]
    ),
    getNativeTokensBalances: useCallback(
      async (address) => {
        try {
          const response = await terra.bank.balance(address);
          return response[0].map((item) => {
            return {
              ...item,
              balance: item.amount.toString(),
            };
          });
        } catch (err) {
          console.log(err);
        }
        return null;
      },
      [terra]
    ),
  };
};

export default useContractApi;
