import "./Timer.scss";
import { useTimer } from "react-timer-hook";
import Text from "../Text/Text";

const Timer = ({ expiryTimestamp, showDays = false, onExpire, className }) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  return (
    <div className={`timer ${className}`}>
      {showDays && (
        <>
          <div className="timer__digit">
            {days.toString().padStart(2, "0")}
            <Text className="timer__description" text="Days" />
          </div>
          <span className="timer__colon">:</span>
        </>
      )}
      <div className="timer__digit">
        {(showDays ? hours : days * 24 + hours).toString().padStart(2, "0")}
        <Text className="timer__description" text="Hrs" />
      </div>
      <span className="timer__colon">:</span>
      <div className="timer__digit">
        {minutes.toString().padStart(2, "0")}
        <Text className="timer__description" text="Mins" />
      </div>
      <span className="timer__colon">:</span>
      <div className="timer__digit">
        {seconds.toString().padStart(2, 0)}
        <Text className="timer__description" text="Secs" />
      </div>
    </div>
  );
};

export default Timer;

// export default function App() {
//   const time = new Date();
//   time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
//   return (
//     <div>
//       <MyTimer expiryTimestamp={time} />
//     </div>
//   );
// }
