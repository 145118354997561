import "./MessageBar.scss";

const MessageBar = ({ text, type, isSnackbar, Action }) => {
  return (
    <div
      className={`message-bar ${type ? `message-bar-${type}` : "info"} ${
        isSnackbar ? "message-bar--is-snackbar" : ""
      }`}
    >
      <p className={`message-bar__message`}>{text}</p>
      {Action && (
        <div className="message-bar__action">
          <Action />
        </div>
      )}
    </div>
  );
};

export default MessageBar;
