import useOnScreen from "../../hooks/useOnScreen";
import "./Title.scss";
import { useRef } from "react";

const Title = ({ style, text, suffix, className, inverted, animate }) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  return (
    <h2
      ref={ref}
      style={style}
      className={`title ${animate ? "title--animate" : ""} ${
        inverted ? "title--inverted" : ""
      } ${isVisible ? "title--visible" : ""} ${className}`}
    >
      {text} {suffix && <span className="title__suffix">{suffix}</span>}
    </h2>
  );
};

export default Title;
