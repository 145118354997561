import React from "react";
import "./HappyHourContent.scss";
import Text from "../Text/Text";
import Button from "../Button/Button";
import { ReactComponent as Icon } from "./icon_boost.svg";
import { Link } from "react-router-dom";
import Timer from "../Timer/Timer";
import { ReactComponent as UstLogo } from "../../assets/images/ust.svg";
import useStakingApr from "../../hooks/useStakingApr";
import { happyHour, tokensPrecision } from "../../config";
import useHappyHourEventState from "../../hooks/useHappyHourEventState";

const HappyHourContent = ({ onTimerEnd, mode, setModalVisible }) => {
  const { eventState } = useHappyHourEventState();
  const eventStartTime = new Date(happyHour.startDate).getTime();
  const eventEndTime = new Date(happyHour.startDate).getTime() + 1000 * 60 * 60;

  const { calculateRewardPoints, calculateUSTAPR } = useStakingApr("lp");

  const closePopup = () => {
    if (setModalVisible) {
      setModalVisible(false);
    }
  };

  const getHappyHourDistributionSchedule = () => {
    if (eventState === "before") {
      return [
        {
          amount: happyHour.distributionAmount * tokensPrecision,
          startTime: eventStartTime,
          endTime: eventEndTime,
        },
      ];
    }
    return [];
  };

  return (
    <>
      <div
        className={
          mode === "full" ? "happy-hour happy-hour__full-mode" : "happy-hour"
        }
      >
        <div
          className={
            mode === "full"
              ? "happy-hour__title-container-full-mode"
              : "happy-hour__title-container"
          }
        >
          <Text
            className={
              mode === "full"
                ? "happy-hour__full-mode-title"
                : "happy-hour__title"
            }
            text="Happy Hour"
          />
          {eventState === "live" && (
            <Text
              className={
                mode === "full"
                  ? "happy-hour__full-mode-title-live"
                  : "happy-hour__title-live"
              }
              text="is Live"
            />
          )}
          <Icon
            className={
              mode === "full"
                ? "happy-hour__full-mode-icon"
                : "happy-hour__icon"
            }
          />
        </div>
        <div>
          <Text
            text={`MintDAO ${
              eventState === "before" ? "will distribute" : "is distributing"
            } <strong>${
              happyHour.distributionAmount
            } UST</strong> from DAO Treasury within an hour!`}
          />
        </div>
        <div className="happy-hour__details happy-hour__details--highlighted">
          <div className="happy-hour__details-row">
            <Text
              className="happy-hour__detail"
              text={`Event ${eventState === "before" ? "starts" : "Ends"} in`}
            />
            {eventState === "before" && (
              <Timer
                className="happy-hour__timer"
                expiryTimestamp={eventStartTime}
                onExpire={onTimerEnd}
                showDays
              />
            )}
            {eventState === "live" && (
              <Timer
                className="happy-hour__timer"
                expiryTimestamp={eventEndTime}
                onExpire={onTimerEnd}
                showDays
              />
            )}
          </div>
          <div className="happy-hour__details-row">
            <Text
              className="happy-hour__detail"
              text={`${eventState === "before" ? "Estimated" : ""} APR up to`}
            />
            <div className="happy-hour__apr">
              <Text
                className="happy-hour__value"
                text={`${calculateUSTAPR(
                  parseFloat(calculateRewardPoints(1)),
                  1,
                  getHappyHourDistributionSchedule()
                )}%`}
              />
              <UstLogo className="happy-hour__token-logo" />
            </div>
          </div>
        </div>
        <div>
          <Text text="The more mint power you have the more rewards in UST you will get!" />
        </div>
        <footer
          className={
            mode === "full"
              ? "happy-hour__footer-full-mode"
              : "happy-hour__footer"
          }
        >
          <Link to="/stake/lp/stake">
            <Button onClick={closePopup} variant="contained">
              Stake Now
            </Button>
          </Link>
          {setModalVisible && (
            <Button onClick={closePopup} variant="contained">
              Close
            </Button>
          )}
        </footer>
      </div>
    </>
  );
};

export default HappyHourContent;
