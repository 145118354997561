import "./PlaynityTheGenesisNft.scss";
import SmallTitle from "../../../components/SmallTitle/SmallTitle";
import React, { useCallback, useEffect, useState } from "react";
import PlaynityTheGenesisUtils from "../../../utils/PlaynityTheGenesisUtils";
import NftContainer from "../../../containers/NftContainer/NftContainer";
import Text from "../../../components/Text/Text";
import { useLocation, useParams } from "react-router";
import usePlaynityTheGenesisApi from "../../../hooks/usePlaynityTheGenesisApi";
import utils from "../../../utils/utils";
import Table from "../../../components/Table/Table";

const PlaynityTheGenesisNft = () => {
  const { tokenId } = useParams();
  const { state } = useLocation();

  const { getNftInfo, getOwner, getPlaynityTheGenesisInfo } =
    usePlaynityTheGenesisApi();

  const [owner, setOwner] = useState("");
  const [rank, setRank] = useState("-");
  const [rarityScore, setRarityScore] = useState(0);
  const [nftInfo, setNftInfo] = useState(
    PlaynityTheGenesisUtils.getPlaynityTheGenesisTokenInfo()
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNftInfoCall = useCallback(async () => {
    const contractQuery = await getNftInfo(tokenId);
    const attr = contractQuery?.extension?.attributes;
    const image = contractQuery?.extension?.image;
    if (attr) {
      setNftInfo(
        PlaynityTheGenesisUtils.getPlaynityTheGenesisTokenInfo(attr, image)
      );
    }
  }, [getNftInfo, tokenId]);

  const getNftRankCall = useCallback(async () => {
    const contractQuery = await getPlaynityTheGenesisInfo({
      tokenIds: [tokenId],
    });
    console.log(contractQuery);
    const dna = contractQuery?.[0]?.drawed_nft_info.dna;
    setRank(PlaynityTheGenesisUtils.getRankFromDna(dna));
    setRarityScore(PlaynityTheGenesisUtils.getRarityScoreFromDna(dna));
  }, [tokenId, getPlaynityTheGenesisInfo]);

  const getNftOwnerCall = useCallback(async () => {
    const contractQuery = await getOwner(tokenId);
    if (contractQuery) {
      setOwner(contractQuery.owner);
    }
  }, [getOwner, tokenId]);

  const updateState = useCallback(async () => {
    getNftInfoCall();
    getNftRankCall();
    getNftOwnerCall();
  }, [getNftInfoCall, getNftOwnerCall, getNftRankCall]);

  useEffect(() => {
    updateState();
  }, [updateState]);

  const image = <img src={nftInfo?.image} alt={"nft"} />;

  const OwnedByComponent = (
    <Text
      text={`Owned by <span>${utils.shortAddress(owner)}</span>`}
      className="playnity-the-genesis-nft__before-title"
    />
  );

  const children = (
    <>
      <SmallTitle
        text="description:"
        className="playnity-the-genesis-nft__description"
      />
      <div>
        <Text text="PlayNity The Genesis Collection consists of 1800 NFTs from five rarities. Each NFT gives you various utilities based on rarity." />
      </div>

      <div className="playnity-the-genesis-nft__separator" />
      <SmallTitle
        text="info:"
        className="playnity-the-genesis-nft__description"
      />

      <div className="playnity-the-genesis-nft__info">
        <Text text={`Rank: <span>#${rank}</span>`} />
        <Text text={`Rarity: <span>${nftInfo?.rarity}</span>`} />
        <Text text={`Daily booster: <span>+${nftInfo?.pexPoints} PEX</span>`} />
        <Text
          text={`Rarity Score: <span>${rarityScore?.toFixed(2)} points</span>`}
        />
        <Text
          text={`Presale tickets: <span>${nftInfo?.presaleTickets}</span>`}
        />
        <Text
          text={`Voting Power And Airdrop: <span>${nftInfo?.votingAndAirdropPower}</span>`}
        />
      </div>

      <div className="playnity-the-genesis-nft__separator" />
      <SmallTitle
        text="attributes:"
        className="playnity-the-genesis-nft__description"
      />

      <Table>
        <thead>
          <tr className="playnity-the-genesis-nft__table-row">
            <th>key</th>
            <th>value</th>
            <th>Rarity %</th>
          </tr>
        </thead>
        <tbody>
          {nftInfo &&
            nftInfo?.attributes?.map((attr) => (
              <tr className="playnity-the-genesis-nft__table-row">
                <td style={{ "--title": "'key'" }}>{attr.trait_type}</td>
                <td style={{ "--title": "'value'" }}>{attr.value}</td>
                <td style={{ "--title": "'%'" }}>{attr.percent.toFixed(2)}%</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );

  return (
    <NftContainer
      tokenId={tokenId}
      image={image}
      imagePath={nftInfo?.image}
      children={children}
      title={`Playnity The Genesis`}
      beforeTitle={OwnedByComponent}
      congratulations={state?.congratulations}
      collectionSalePage="/collections/playnity-the-genesis"
      harvestReward={nftInfo?.mintReward}
    />
  );
};

export default PlaynityTheGenesisNft;
