const sort = (a, b, sortMethod, dir) => {
  if (dir === "asc") {
    if (Number.isNaN(Number(a[sortMethod]))) {
      if (a[sortMethod] > b[sortMethod]) return 1;
      if (a[sortMethod] < b[sortMethod]) return -1;
      return 0;
    }
    return a[sortMethod] - b[sortMethod];
  } else {
    if (Number.isNaN(Number(a[sortMethod]))) {
      if (a[sortMethod] > b[sortMethod]) return -1;
      if (a[sortMethod] < b[sortMethod]) return 1;
      return 0;
    }
    return b[sortMethod] - a[sortMethod];
  }
};

const utils = {
  renderListHeaders: (headersArr, sortMethod, sortDir, onHeaderClick) => {
    return () => (
      <tr>
        <th>No.</th>

        {headersArr.map(({ key, title }) => (
          <th key={key} onClick={() => onHeaderClick(key)}>
            {title}
            {sortMethod === key ? (sortDir === "asc" ? "↓" : "↑") : ""}
          </th>
        ))}
      </tr>
    );
  },
  renderRow: (tokenCollection, filter, rowData, sortMethod, sortDir) => {
    return (props) => {
      let collection = tokenCollection;
      const index = props[["data-index"]];
      if (filter) {
        collection = collection.filter((item) =>
          item.token_id.toString().includes(filter)
        );
      }

      if (collection && collection.length > 0) {
        const sortedTokenCollection = collection.sort((a, b) =>
          sort(a, b, sortMethod, sortDir)
        );
        const tokenData = sortedTokenCollection[index];
        if (!tokenData) {
          return null;
        }

        let children = <tr {...props}></tr>;
        children = rowData(props, index, tokenData);

        return children;
      }

      return null;
    };
  },
};

export default utils;
