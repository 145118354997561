import { useEffect, useCallback, useState, useMemo } from "react";
import useUSTRewardsApi from "./useUSTRewardsApi";
import useTokenPrice from "./useTokenPrice";
import useTerra from "./useTerra";
import useStakingApi from "./useStakingApi";
import utils from "../utils/utils";
import { tokensPrecision, contractAddresses } from "../config";

const useStakingApr = (stakingType) => {
  const { network } = useTerra();

  const [contractConfig, setContractConfig] = useState({});
  const [contractState, setContractState] = useState({});
  const [mintPrice, setMintPrice] = useState({});
  const [lpPrice, setLpPrice] = useState({});
  const [ustRewardsContractConfig, setUstRewardsContractConfig] = useState({});
  const [ustRewardsContractState, setUstRewardsContractState] = useState({});

  const { getConfig: getUstRewardsConfig, getState: getUstRewardsState } =
    useUSTRewardsApi();
  const { getLpPrice, getPrice } = useTokenPrice();
  const { getConfig, getState } = useStakingApi(stakingType);
  const updateData = useCallback(() => {
    const config = async () => {
      const config = await getConfig();
      if (config) {
        setContractConfig(config);
      }
    };
    const state = async () => {
      const state = await getState();
      if (state) {
        setContractState(state);
      }
    };
    const mintPrice = async () => {
      const mintPrice = await getPrice();
      if (mintPrice) {
        setMintPrice(mintPrice);
      }
    };
    const lpPrice = async () => {
      const lpPrice = await getLpPrice();
      if (lpPrice) {
        setLpPrice(lpPrice);
      }
    };
    const ustRewardsState = async () => {
      const state = await getUstRewardsState();
      if (state) {
        setUstRewardsContractState(state);
      }
    };
    const ustRewardsConfig = async () => {
      const config = await getUstRewardsConfig();
      if (config) {
        setUstRewardsContractConfig(config);
      }
    };
    config();
    state();
    ustRewardsConfig();
    ustRewardsState();
    lpPrice();
    mintPrice();
  }, [
    getConfig,
    getState,
    getPrice,
    getLpPrice,
    getUstRewardsState,
    getUstRewardsConfig,
  ]);

  useEffect(() => {
    const intervalId = utils.asyncSetInterval(updateData, 10000);
    return () => clearInterval(intervalId);
  }, [updateData]);

  const stakingContractAddress = useMemo(() => {
    return stakingType === "mint"
      ? contractAddresses[network.name].stakingSAS
      : contractAddresses[network.name].stakingLP;
  }, [stakingType, network.name]);

  const mintPowerMultiplier =
    parseInt(
      ustRewardsContractConfig?.staking_score_points_multipliers?.find(
        (e) => e[0] === stakingContractAddress
      )?.[1]
    ) / tokensPrecision || 1;

  const calculateRewardPoints = (mintAmount, unbondingPeriod) => {
    const maxUnbondingPeriod = contractConfig.maximum_unbonding_period || 31;
    const scorePointsBoost =
      parseFloat(contractConfig.unbonding_boost_multiplier) / tokensPrecision ||
      4;
    const periodDecimal =
      unbondingPeriod === undefined ? 1 : unbondingPeriod / maxUnbondingPeriod;
    const additionalBoost = periodDecimal * (scorePointsBoost - 1);
    return (
      (mintAmount || 0) *
      (1 + additionalBoost) *
      mintPowerMultiplier
    ).toFixed(2);
  };

  const calculateAPR = (scorePoints, bondAmount) => {
    if (bondAmount === 0) {
      return (0).toFixed(2);
    }
    const yearInMiliseconds = 1000 * 60 * 60 * 24 * 365.25;
    const distributionSchedule = (
      contractConfig.reward_distribution_schedule || []
    ).map((distributionEntry) => ({
      amount: parseInt(distributionEntry.amount),
      startTime: parseInt(parseInt(distributionEntry.start_time) / 1000000),
      endTime: parseInt(parseInt(distributionEntry.end_time) / 1000000),
    }));
    const now = Date.now();
    const currentDistribution = distributionSchedule.find(
      (distributionEntry) => {
        const { startTime, endTime } = distributionEntry;
        return now >= startTime && now <= endTime;
      }
    );
    if (!currentDistribution) {
      return 0;
    }
    const yearlyDistributionAmount =
      (yearInMiliseconds /
        (currentDistribution.endTime - currentDistribution.startTime)) *
      currentDistribution.amount;
    const scorePointsProportion =
      scorePoints / contractState.total_score_points;
    const stakerShare = scorePointsProportion * yearlyDistributionAmount;

    const priceInReward =
      stakingType === "lp" ? lpPrice.priceMint / 1000000 : 1;
    return (((stakerShare / bondAmount) * 100) / priceInReward).toFixed(2);
  };

  const calculateUSTAPR = (
    scorePoints,
    bondAmount,
    additionalDistributionSchedule = []
  ) => {
    if (bondAmount === 0) {
      return (0).toFixed(2);
    }

    const yearInMiliseconds = 1000 * 60 * 60 * 24 * 365.25;
    const distributionSchedule = (
      ustRewardsContractConfig.reward_distribution_schedule || []
    ).map((distributionEntry) => ({
      amount: parseInt(distributionEntry.amount),
      startTime: parseInt(parseInt(distributionEntry.start_time) / 1000000),
      endTime: parseInt(parseInt(distributionEntry.end_time) / 1000000),
    }));
    const now = Date.now();
    const currentDistributions = distributionSchedule.filter(
      (distributionEntry) => {
        const { startTime, endTime } = distributionEntry;
        return now >= startTime && now <= endTime;
      }
    );
    if (currentDistributions.length === 0) {
      return (0).toFixed(2);
    }

    let yearlyDistributionAmount = 0;

    currentDistributions
      .concat(additionalDistributionSchedule)
      .forEach((distributionEntry) => {
        yearlyDistributionAmount +=
          (yearInMiliseconds /
            (distributionEntry.endTime - distributionEntry.startTime)) *
          distributionEntry.amount;
      });
    const scorePointsProportion =
      scorePoints / ustRewardsContractState.total_mint_power;
    const stakerShare = scorePointsProportion * yearlyDistributionAmount;
    const priceInReward =
      stakingType === "lp" ? lpPrice.priceUst / 1000000 : mintPrice;
    return (((stakerShare / bondAmount) * 100) / priceInReward).toFixed(2);
  };

  return {
    calculateRewardPoints,
    calculateAPR,
    calculateUSTAPR,
  };
};

export default useStakingApr;
